import React, { useMemo } from 'react';
import {
  ChatEvent,
  Message,
  SystemMessageContent,
  SystemMessageType,
} from '../../../types';
import TosRequestRender from './TosRequestRender';
import TosRejectedRender from './TosRejectedRender';

interface Props {
  message: Message;
  enabled: boolean;
  sendMessage: (message: string) => void;
  sendEvent: (event: ChatEvent) => void;
  id: string;
  currentMessage: boolean;
}

const SystemMessageRender = ({
  message,
  enabled,
  sendMessage,
  sendEvent,
  id,
  currentMessage,
}: Props): React.ReactElement => {
  const content = useMemo(
    () =>
      message.content
        ? (JSON.parse(message.content) as SystemMessageContent)
        : undefined,
    [message]
  );
  if (content) {
    switch (content.type) {
      case SystemMessageType.TOS_REQUEST:
        return (
          <TosRequestRender
            sendEvent={sendEvent}
            sendMessage={sendMessage}
            currentMessage={currentMessage}
          />
        );
      case SystemMessageType.TOS_REJECTED:
        return (
          <TosRejectedRender sendEvent={sendEvent} sendMessage={sendMessage} />
        );
    }
  }
  return <></>;
};

export default SystemMessageRender;
