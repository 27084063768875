import { compareBreakpoints } from '../helpers/breakpoint';
import { getDynamicTokens } from './dynamic-tokens';
import { createCSSVarName } from './create-css-var-name';
import { designTokens } from './example-design-tokens';
import { DesignTokens } from './definitions';

export function getMediaQueryVars(optionsOverride?: unknown): string {
  const optionsTokens =
    optionsOverride && (optionsOverride as { tokens?: DesignTokens }).tokens;
  // @ts-ignores
  const dynamicTokens = getDynamicTokens(optionsTokens || designTokens);

  return Object.keys(dynamicTokens)
    .sort(compareBreakpoints)
    .reduce((result, breakpoint) => {
      const styles = Object.keys(dynamicTokens[breakpoint]).reduce(
        (cssVars, path) => `
				${cssVars}
				${createCSSVarName(path)}: ${dynamicTokens[breakpoint][path]};
			`,
        ''
      );

      return breakpoint === '0'
        ? `${styles}
					${result}`
        : `${result}
					@media (min-width: ${breakpoint}) {
						${styles}
					}`;
    }, '');
}
