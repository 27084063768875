import React from 'react';
import { Text, TextAppearance, TextColor } from '../Text/Text';
import styled from 'styled-components/macro';
import { ReactComponent as MaintenanceIcon } from '../../icons/maintenance/default.svg';
import { ReactComponent as VWLogo } from '../../icons/vw/default.svg';

interface Props {}

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const StyledMaintenanceIcon = styled(MaintenanceIcon)`
  height: 50%;
  fill: #001f4e;
`;

const StyledVWLogo = styled(VWLogo)`
  height: 20%;
  fill: #001f4e;
  align-self: center;
`;

const TextWrapper = styled.div`
  margin: 10px;
  color: #001f4e;
`;

const Maintenance = (props: Props) => {
  return (
    <MainContainer>
      <StyledMaintenanceIcon />
      <TextWrapper>
        <Text
          color={TextColor.inherit}
          appearance={TextAppearance.headline0200}
          bold={true}
        >
          EN MANTENIMIENTO
        </Text>
      </TextWrapper>
      {/*
      <StyledVWLogo />
      */}
    </MainContainer>
  );
};

export default Maintenance;
