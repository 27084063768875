import React from 'react';
import {
  CustomPayloadCarousel,
  CustomPayloadButtonsType,
  CustomPayloadLinksOpenType,
  CustomPayloadButton,
  ChatEvent,
} from '../../../types';
import { Gallery, GallerySlide } from '../../Gallery/Gallery';
import { ArrowButtons } from '../../Gallery/arrow-buttons';
import styled from 'styled-components/macro';
import { NavigationBullets } from '../../Gallery/navigation-bullets';
import { Image } from '../../Image/Image';
import { Text, TextAlignment, TextAppearance } from '../../Text/Text';
import { CTA } from '../../CallToAction/CallToAction';

interface Props {
  content: CustomPayloadCarousel;
  id: string;
  sendMessage: (message: string) => void;
  enabled: boolean;
  sendEvent: (event: ChatEvent) => void;
}

const GalleryWrapper = styled.div`
  width: 275px;
  background: #dfe4e8;
`;

const SlideContentWrapper = styled.div`
  overflow-wrap: break-word;
`;

const RootWrapper = styled.div`
  width: 260px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CTAOverride = styled(CTA)`
  margin: 5px;
  background-color: #001e50;
  color: white;
`;

const CarouselRender = ({
  content,
  id,
  sendMessage,
  enabled,
  sendEvent,
}: Props): React.ReactElement => {
  const buttonClickHandler = (button: CustomPayloadButton) => {
    switch (button.type) {
      case CustomPayloadButtonsType.LINK:
        button.typeLink === CustomPayloadLinksOpenType.EXTERNAL
          ? window.open(button.value, '_blank')
          : (window.location.href = button.value);
        break;
      case CustomPayloadButtonsType.INTENT:
        sendMessage(button.value as string);
        break;
      case CustomPayloadButtonsType.EVENT:
        sendEvent(button.value);
        break;
    }
  };
  return (
    <GalleryWrapper>
      <Gallery
        id={`carousel-${id}`}
        arrowButtons={
          <ArrowButtons
            buttonPreviousAriaLabel="Previous"
            buttonNextAriaLabel="Next"
            size="small"
          />
        }
        navigation={
          <NavigationBullets>
            {content.elements.map((_, index) => index)}
          </NavigationBullets>
        }
      >
        {content.elements.map(
          ({ imageUrl, title, subtitle, buttons }, index) => (
            <GallerySlide key={index} ariaLabel="Slide">
              <SlideContentWrapper>
                <RootWrapper>
                  {imageUrl && (
                    <Image
                      src={imageUrl}
                      objectFit={'contain'}
                      alt={'header image'}
                    />
                  )}
                  <ButtonsContainer>
                    {buttons?.map((button, index) => (
                      <CTAOverride
                        disabled={!enabled}
                        emphasis="secondary"
                        size="small"
                        key={`${index}-custom`}
                        tag="button"
                        onClick={
                          enabled ? () => buttonClickHandler(button) : undefined
                        }
                      >
                        {button.text}
                      </CTAOverride>
                    ))}
                  </ButtonsContainer>
                </RootWrapper>
              </SlideContentWrapper>
            </GallerySlide>
          )
        )}
      </Gallery>
    </GalleryWrapper>
  );
};

export default CarouselRender;
