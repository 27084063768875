import { DesignTokens } from './definitions';

interface DynamicDesignToken {
  readonly [breakpoint: string]: string;
}

interface DynamicDesignTokens {
  readonly [breakpoint: string]: { readonly [path: string]: string };
}

interface DesignTokenSubtree {
  readonly [key: string]: DesignTokenSubtree | string | DynamicDesignToken[];
}

function isDynamicTokenSubtree(
  subtree: DesignTokenSubtree | string | DynamicDesignToken[]
): subtree is DesignTokenSubtree {
  return !Array.isArray(subtree) && typeof subtree !== 'string';
}

type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export function getDynamicTokens(
  tokens: DesignTokens | DesignTokenSubtree,
  path?: string
): DynamicDesignTokens {
  return Object.keys(tokens).reduce<DynamicDesignTokens>(
    (result: Mutable<DynamicDesignTokens>, key): DynamicDesignTokens => {
      const newPath = path ? `${path}.${key}` : key;
      const currentToken = (tokens as DesignTokenSubtree)[key];

      if (Array.isArray(currentToken)) {
        currentToken.forEach((dynamic) => {
          result[dynamic.breakpoint] = result[dynamic.breakpoint] || {};
          result[dynamic.breakpoint] = {
            ...result[dynamic.breakpoint],
            [newPath]: dynamic.value,
          };
        });

        return result;
      }
      if (isDynamicTokenSubtree(currentToken)) {
        const subtreeResults = getDynamicTokens(currentToken, newPath);
        Object.keys(subtreeResults).forEach((breakpoint) => {
          result[breakpoint] = {
            ...(result[breakpoint] || {}),
            ...subtreeResults[breakpoint],
          };
        });

        return result;
      }

      return result;
    },
    {}
  );
}
