import React from 'react';
import {
  ChatEvent,
  CustomPayloadButtonEvent,
  CustomPayloadButtonsType,
} from '../../../types';
import {
  Text,
  TextAlignment,
  TextAppearance,
  TextColor,
} from '../../Text/Text';
import styled from 'styled-components/macro';
import ButtonsRender from './ButtonsRender';

interface Props {
  sendMessage: (message: string) => void;
  sendEvent: (event: ChatEvent) => void;
}

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  text-size: 16px;
`;

const LegalText = styled.div`
  flex-basis: 100%;
  align-self: start;
  padding-top: 8px;
`;

const buttons: CustomPayloadButtonEvent[] = [
  {
    type: CustomPayloadButtonsType.EVENT,
    text: 'Volver',
    value: ChatEvent.TOS_RESET,
  },
];

const TosRejectedRender = ({ sendEvent, sendMessage }: Props) => {
  return (
    <MainContainer>
      <LegalText>
        <Text
          appearance={TextAppearance.copy200}
          color={TextColor.inherit}
          staticSize
          textAlign={TextAlignment.start}
        >
          <>
            {'Necesitamos que aceptes los términos. Si estas interesado podes contactarte con un asesor llamando al 0800-888-8338 (lunes a viernes de 9:00 a 18:00 h).\n' +
              'Si cambias de opinión podes continuar con este chat.'}
          </>
        </Text>
      </LegalText>
      <ButtonsRender
        buttons={buttons}
        size={'large'}
        enabled={true}
        sendMessage={sendMessage}
        userSelection={function () {}}
        sendEvent={sendEvent}
      />
    </MainContainer>
  );
};

export default TosRejectedRender;
