import { css } from '../theme';

export enum InputAppearance {
  Default,
  Disabled,
  Error,
  Success,
  ReadOnly,
}

export const getMainColor = css<{
  readonly appearance?: InputAppearance;
}>`
  ${({ theme, appearance }) => {
    switch (appearance) {
      case InputAppearance.ReadOnly:
        return theme.interaction.recipe100.default;
      case InputAppearance.Disabled:
        return theme.interaction.recipe500.disabled;
      default:
        return theme.interaction.recipe500.default;
    }
  }}
`;

export const getLabelColor = css<{
  readonly appearance?: InputAppearance;
}>`
  ${({ theme, appearance }) => {
    switch (appearance) {
      case InputAppearance.ReadOnly:
        return theme.interaction.recipe100.default;
      case InputAppearance.Disabled:
        return theme.interaction.recipe500.disabled;
      default:
        return theme.interaction.recipe450.default;
    }
  }}
`;

// color for borders, etc.
export const getAuxillaryColor = css<{
  readonly appearance?: InputAppearance;
}>`
  ${({ theme, appearance }) => {
    switch (appearance) {
      case InputAppearance.Error:
        return theme.interaction.recipe360.default;
      case InputAppearance.Success:
        return theme.interaction.recipe350.default;
      case InputAppearance.Disabled:
        return theme.interaction.recipe200.disabled;
      default:
        return theme.interaction.recipe200.default;
    }
  }}
`;

// color for character counter
export const getAuxillaryTextColor = css<{
  readonly appearance?: InputAppearance;
}>`
  ${({ theme, appearance }) => {
    switch (appearance) {
      case InputAppearance.Disabled:
        return theme.interaction.recipe200.disabled;
      case InputAppearance.Error:
      case InputAppearance.Success:
      default:
        return theme.interaction.recipe200.default;
    }
  }}
`;

// color for notifications
export const getNotificationColor = css<{
  readonly appearance?: InputAppearance;
}>`
  ${({ theme, appearance }) => {
    switch (appearance) {
      case InputAppearance.Error:
        return theme.interaction.recipe360.default;
      case InputAppearance.Success:
        return theme.interaction.recipe350.default;
      default:
        return theme.interaction.recipe370.default;
    }
  }}
`;
