import { DialogState } from './types';

interface State {
  dialog: DialogState;
}

export const dialogMessagesSelector = (state: State) => state.dialog.messages;
export const dialogInitializingSelector = (state: State) =>
  state.dialog.dialogInitializing;
export const dialogInitializedSelector = (state: State) =>
  state.dialog.dialogInitialized;
export const dialogLegalAcceptedSelector = (state: State) =>
  state.dialog.dialogLegalAccepted;
export const dialogRestoredSelector = (state: State) =>
  state.dialog.dialogRestored;
export const dialogSessionIdSelector = (state: State) => state.dialog.sessionId;
