import { useContext } from 'react';
import {
  ThemedCssFunction,
  css as baseCSS,
  ThemeContext,
  withTheme as baseWithTheme,
  WithThemeFnInterface,
} from 'styled-components';

import { ThemeDefinition } from './definitions';

export * from './definitions';
export * from './example-design-tokens';
export * from './theme-provider';
export * from './create-css-var-name';
export const css = baseCSS as ThemedCssFunction<ThemeDefinition>;
export const withTheme =
  baseWithTheme as unknown as WithThemeFnInterface<ThemeDefinition>;

export interface WithThemeProps {
  theme: ThemeDefinition;
}
// once styled-components is ^5.0.0, we can re-export useTheme instead
export const useTheme = (): ThemeDefinition =>
  useContext<ThemeDefinition>(ThemeContext);
