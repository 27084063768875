import { AppThunk } from '../store';
import CacheManager, { CacheKey } from '../../cache/cacheManager';
import {
  initializeDialogDoneAction,
  initializeDialogRequestedAction,
} from './actions';

const cache = new CacheManager();

export const thunkInitializeDialog = (): AppThunk => async (dispatch) => {
  dispatch(initializeDialogRequestedAction());
  let dialogCache = await cache.readData(CacheKey.DIALOG_STATE_CACHE_KEY);
  if (
    dialogCache &&
    dialogCache.timestamp +
      parseInt(process.env.REACT_APP_CHAT_EXPIRATION_TIME_MS) <
      Date.now()
  ) {
    dialogCache = null;
  }
  dispatch(
    initializeDialogDoneAction(
      dialogCache?.messages || [],
      dialogCache?.dialogLegalAccepted || false,
      dialogCache?.sessionId || undefined
    )
  );
};
