import * as React from 'react';

import { ThemeDefinition } from '../../theme';
import styled from 'styled-components/macro';
import { Container, ContainerWrap, ContainerGutter } from '../Container';
import { withTheme } from 'styled-components/macro';
import { AriaLabelOrLabelledby } from '../../helpers/types';
import { GalleryContext } from './GalleryContext';

const StyledBullets = styled.div`
  position: absolute;
  bottom: ${(props) => props.theme.size.static150};
  left: 50%;
  transform: translateX(-50%);
`;

const VisuallyHidden = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledBullet = styled.button<{
  readonly isActive: boolean;
}>`
  position: relative;
  width: ${(props) => props.theme.size.static150};
  height: ${(props) => props.theme.size.static150};
  border: 0; // reset browser styles
  padding: 0; // reset browser styles
  border-radius: 50%;
  background: ${(props) => props.theme.interaction.recipe750.default};
  cursor: ${(props) => (props.isActive ? 'unset' : 'pointer')};
  outline: none; // reset browser styles
  transform: scale(${(props) => (props.isActive ? 2 : 1)});
  transition: ${(props) => {
    const duration = props.theme.animation.duration.layer;
    const timingFunction = props.theme.animation.timingFunction.in;

    return `all ${duration} ${timingFunction}`;
  }};

  ::before {
    content: '';
    position: absolute;
    top: 12.5%;
    left: 12.5%;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background: ${(props) => props.theme.interaction.recipe450.default};
  }

  :hover {
    ::before {
      background: ${(props) => props.theme.interaction.recipe450.hover};
    }
  }

  :focus {
    outline: none;
    ::before {
      background-color: ${({ theme }) => theme.focus.background};
      box-shadow: 0 0 0 1px
        ${({ theme }) => theme.interaction.recipe450.default};
    }
  }
`;

export type NavigationBulletsProps = {
  readonly theme: ThemeDefinition;
} & AriaLabelOrLabelledby;

class InternalNavigationBullets extends React.PureComponent<
  React.PropsWithChildren<NavigationBulletsProps>
> {
  private readonly carouselRefs: {
    [id: string]: React.RefObject<HTMLButtonElement>;
  } = {};

  private createCarouselRef(
    elementId: string
  ): React.RefObject<HTMLElement> | void {
    // Do not recreate the ref if it already exists
    if (Object.prototype.hasOwnProperty.call(this.carouselRefs, elementId)) {
      return;
    }

    this.carouselRefs[elementId] = React.createRef();

    return this.carouselRefs[elementId];
  }

  private readonly focusElement = (el: string) => {
    const element = this.carouselRefs[el].current;
    if (element) {
      element.focus();
    }
  };

  private readonly createKeyHandler =
    (triggerChange: (newIndex: number) => void, index: number) =>
    (event: React.KeyboardEvent) => {
      const { children, theme } = this.props;
      const length = React.Children.count(children);

      let newIndex: number | null;
      switch (event.key) {
        case 'ArrowLeft':
          newIndex =
            (index + (theme.direction === 'rtl' ? 1 : length - 1)) % length;
          break;
        case 'ArrowRight':
          newIndex =
            (index + (theme.direction === 'rtl' ? length - 1 : 1)) % length;
          break;
        case 'Home':
          newIndex = 0;
          break;
        case 'End':
          newIndex = length - 1;
          break;
        default:
          newIndex = null;
      }

      if (newIndex !== null) {
        event.stopPropagation();
        event.preventDefault();
        this.focusElement(`bullet-${newIndex}`);
        triggerChange(newIndex);
      }
    };

  private readonly createClickHandler =
    (handleChange: (newIndex: number) => void, index: number) =>
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      handleChange(index);
    };

  public render(): JSX.Element {
    const { children, ariaLabel, ariaLabelledby } = this.props;

    return (
      <GalleryContext.Consumer>
        {({ index, triggerChange, getTabId, getSlideId }) => (
          <StyledBullets
            role="tablist"
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
          >
            <Container
              wrap={ContainerWrap.never}
              gutter={ContainerGutter.static150}
            >
              {React.Children.map(children, (child, bulletIndex) => {
                this.createCarouselRef(`bullet-${bulletIndex}`);
                return (
                  <StyledBullet
                    id={getTabId(bulletIndex)}
                    isActive={index === bulletIndex}
                    onClick={this.createClickHandler(
                      triggerChange,
                      bulletIndex
                    )}
                    onKeyDown={this.createKeyHandler(
                      triggerChange,
                      bulletIndex
                    )}
                    key={`carousel-bullet-${bulletIndex}`}
                    ref={this.carouselRefs[`bullet-${bulletIndex}`]}
                    tabIndex={index === bulletIndex ? 0 : -1}
                    role="tab"
                    aria-selected={index === bulletIndex}
                    aria-controls={getSlideId(bulletIndex)}
                  >
                    <VisuallyHidden>{child}</VisuallyHidden>
                  </StyledBullet>
                );
              })}
            </Container>
          </StyledBullets>
        )}
      </GalleryContext.Consumer>
    );
  }
}

export const NavigationBullets = withTheme(InternalNavigationBullets);
