const basis = 24;

function assertCountGridColumn(count: number): void {
  if (process.env.NODE_ENV !== 'production') {
    if (Math.floor(count) !== count) {
      throw new Error('count has to be an integer.');
    }

    if (count < 0 || count > basis) {
      throw new Error(`count has to be between 0 and ${basis}.`);
    }
  }
}

export function getGridColumn(count: number): string {
  assertCountGridColumn(count);

  // Round down to two decimal places
  // two decimal places because Edge has issues with too many decimal places
  // round down, so (positive) rounding errors do not add up
  return `${Math.floor((100 / basis) * count * 100) / 100}vw`;
}

export function getColumnsWidthForBreakpoint(
  columns: number,
  breakpoint: number
): string {
  assertCountGridColumn(columns);

  // Round down to two decimal places
  // two decimal places because Edge has issues with too many decimal places
  // round down, so (positive) rounding errors do not add up
  return `${Math.floor((breakpoint / basis) * columns * 100) / 100}px`;
}
