import * as React from 'react';

import { ReactComponent as ExternalLink } from '../../icons/externalLink/default.svg';

import styled from 'styled-components/macro';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';

const StyledExternalLinkIndicator = styled(ExternalLink)`
  border: 0;
  width: 0.85em;
  height: 0.7em;

  ${(props) => props.theme.direction === 'rtl' && `transform: scale(-1, 1);`}
`;

interface ExternalLinkIndicatorProps
  extends Record<string, unknown>,
    CommonProps {
  title?: string;
}

export class ExternalLinkIndicator extends React.PureComponent<ExternalLinkIndicatorProps> {
  public constructor(props: ExternalLinkIndicatorProps) {
    super(props);
  }

  public render(): JSX.Element | null {
    const commonProps = getCommonProps(this.props);

    return (
      <StyledExternalLinkIndicator
        {...(this.props.title
          ? { title: this.props.title }
          : { 'aria-hidden': true })}
        {...commonProps}
      />
    );
  }
}
