import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { CTA } from '../CallToAction/CallToAction';

interface Props {
  sendButtonDisabled: boolean;
  handleSendButton: MouseEventHandler<HTMLButtonElement>;
  sendIcon: React.ReactElement;
}

const CTAOverride = styled(CTA)`
  :focus {
    border: none;
    box-shadow: none;
    padding-top: 2px;
    padding-left: 2px;
    background: none;
  }
`;

const ChatBotSendButton = ({
  sendButtonDisabled,
  handleSendButton,
  sendIcon,
}: Props) => {
  return (
    <CTAOverride
      tag="button"
      emphasis="tertiary"
      ariaLabel="aria label"
      iconPosition="top"
      disabled={sendButtonDisabled}
      onClick={handleSendButton}
      icon={sendIcon}
    />
  );
};

export default ChatBotSendButton;
