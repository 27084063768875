import * as React from 'react';

import { css } from '../../theme';
import styled from 'styled-components/macro';

import {
  BreakpointConfigType,
  createStylesForBreakpoints,
  isBreakpointConfig,
} from '../../helpers/breakpoint';
import { CommonProps } from '../../helpers/commonProps';
import { TextAppearance, TextColor, Text } from '../Text/Text';

export type Emphasis = 'primary' | 'secondary' | 'tertiary' | 'none';

export type Size = 'small' | 'large';

export type IconPosition = 'start' | 'end' | 'top';

type CTAElement = HTMLAnchorElement | HTMLButtonElement;

export interface CTACommonProps extends CommonProps {
  readonly emphasis?: Emphasis;
  readonly size?: Size;
  readonly icon?: React.ReactElement;
  readonly iconPosition?: IconPosition;
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
  readonly ariaLabel?: string;
  readonly tabIndex?: -1 | 0;
}

export interface CTAButtonProps {
  readonly tag: 'button';
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
  readonly disabled?: boolean;
  readonly title?: string;
  readonly type?: 'submit' | 'reset' | 'button';
}

type LinkRel =
  | 'alternate'
  | 'archives'
  | 'author'
  | 'bookmark'
  | 'external'
  | 'help'
  | 'license'
  | 'next'
  | 'nofollow'
  | 'noopener'
  | 'noreferrer'
  | 'noopener noreferrer'
  | 'prev'
  | 'search'
  | 'tag';

type LinkTarget = '_self' | '_blank' | '_parent' | '_top';

export interface CTALinkProps {
  readonly tag: 'a';
  readonly href?: string;
  readonly disabled?: boolean;
  readonly onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  readonly property?: string;
  readonly rel?: LinkRel;
  readonly target?: LinkTarget;
  readonly title?: string;
  readonly typeOf?: string;
}

export type CTAProps = CTACommonProps & (CTAButtonProps | CTALinkProps);

const isLink = (props: CTAProps): props is CTACommonProps & CTALinkProps =>
  Boolean(props.tag === 'a');

// color styles for all CTAs
const colorStyle = css<{
  readonly disabled?: boolean;
  readonly emphasis?: Emphasis;
}>`
  ${(props) => {
    const textRecipe =
      props.theme.interaction[
        props.emphasis === 'primary'
          ? 'recipe750'
          : props.emphasis === 'tertiary'
          ? 'recipe400'
          : 'recipe450'
      ];
    const transparentRecipe = {
      default: 'transparent',
      disabled: 'transparent',
      hover: 'transparent',
      active: 'transparent',
    };
    const backgroundRecipe =
      props.emphasis === 'primary'
        ? props.theme.interaction.recipe450
        : transparentRecipe;
    const borderRecipe = props.theme.interaction.recipe450;

    return props.disabled
      ? css`
          background: ${backgroundRecipe.disabled};
          border-color: ${borderRecipe.disabled};
          color: ${textRecipe.disabled};
        `
      : css`
          background: ${backgroundRecipe.default};
          border-color: ${borderRecipe.default};
          color: ${textRecipe.default};
        `;
  }};
`;

const focusStyle = css`
  outline: none;
  background-color: ${({ theme }) => theme.focus.background};
  color: ${({ theme }) => theme.focus.foreground};
`;
const focusStyleBoxShadow = css`
  box-shadow: 0 0 0 1px inset
    ${({ theme }) => theme.interaction.recipe450.default};
`;

// styled component for icons in links
const StyledLinkIcon = styled.span<{ readonly iconPosition?: IconPosition }>`
  border-radius: 50%;
  line-height: 0;
  position: relative;
  order: ${(props) => (props.iconPosition === 'end' ? 1 : 'initial')};

  ${({ iconPosition, theme }) =>
    iconPosition === 'top' &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 2px solid currentColor;
      margin-bottom: ${theme.size.static100};
    `}
`;

// styled component for icons in buttons
const StyledButtonIcon = styled.span<{ readonly iconPosition?: IconPosition }>`
  order: ${(props) => (props.iconPosition === 'end' ? 1 : 'initial')};
`;

// styled component for (text-) children in links
const StyledLinkText = styled.span<{
  readonly disabled?: boolean;
  readonly emphasis?: Emphasis;
}>`
  position: relative;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;

  ${(props) => props.emphasis === 'none' && 'text-decoration: none;'}

  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

// styled component for arranging icons and children in buttons (emphasis primary and secondary, size small and large)
const StyledButtonWrapper = styled.div<{
  iconPosition?: IconPosition;
}>`
  align-items: center;
  ${(props) => {
    if (props.iconPosition === 'top') {
      return css`
        display: flex;
        flex-direction: column;
      `;
    }

    const templateColumns =
      props.iconPosition === 'end'
        ? `repeat(
					${React.Children.count(props.children) - 1},
					auto
				) max-content`
        : `max-content repeat(
					${React.Children.count(props.children) - 1},
					auto
				)`;

    return css`
      display: inline-grid;
      grid-template-columns: ${templateColumns};
      grid-column-gap: ${props.theme.size.static150};
    `;
  }}
`;

// styled component for arranging icons and children in links (emphasis tertiary)
const StyledLinkWrapper = styled.div<{ iconPosition?: IconPosition }>`
  align-items: center;
  ${(props) => {
    if (props.iconPosition === 'top') {
      return css`
        display: flex;
        flex-direction: column;
      `;
    }

    return css`
      display: inline-grid;
      grid-template-columns: max-content auto;
      grid-column-gap: ${(props) => props.theme.size.static150};
      align-items: center;
    `;
  }}
`;

const createStretchContentForBreakpoints = (props: {
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
}) => {
  if (!props.stretchContent) {
    return '';
  }
  if (isBreakpointConfig(props.stretchContent)) {
    return createStylesForBreakpoints<boolean>(
      props.stretchContent,
      (stretchContent) => (stretchContent ? 'width: 100%;' : 'width: initial;')
    );
  }

  return props.stretchContent && 'width: 100%;';
};

// styled component for large buttons (without icon, emphasis primary and secondary)
const StyledButton = styled.button<{
  readonly disabled?: boolean;
  readonly emphasis?: Emphasis;
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
  readonly circular?: boolean;
  readonly size?: Size;
}>`
  position: relative;
  ${createStretchContentForBreakpoints}
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) => {
    const width = props.size === 'small' ? props.theme.size.static400 : '43px';
    const padding =
      props.size === 'small'
        ? `${props.theme.size.static100} ${props.theme.size.static250}`
        : `0 ${props.theme.size.static400}`;
    const border =
      props.size === 'small'
        ? `${props.theme.size.border.light}`
        : `${props.theme.size.border.heavy}`;

    return css`
      min-height: ${width};
      min-width: ${width};
      ${props.circular
        ? css`
            width: ${width};
            padding: 0;
          `
        : css`
            padding: ${padding};
          `}
      border: ${border} solid;
      ${!props.disabled &&
      css`
        :hover,
        :active {
          ::before {
            content: '';
            border-radius: 500px; /* any absolute value larger than the largest sensible multi-line button will work */
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: -${border};
          }
        }
        :hover {
          ::before {
            background-color: ${props.emphasis === 'primary'
              ? props.theme.hoverIndicatorColor.filledElement.default
              : props.theme.hoverIndicatorColor.outlinedElement.default};
          }
        }
        :active {
          ::before {
            background-color: ${props.emphasis === 'primary'
              ? props.theme.hoverIndicatorColor.filledElement.active
              : props.theme.hoverIndicatorColor.outlinedElement.active};
          }
        }
        :focus {
          ${focusStyle};
          ${focusStyleBoxShadow};
        }
      `}
    `;
  }}
  margin: 0;
  border-radius: 500px; /* any absolute value larger than the largest sensible multi-line button will work */
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  -webkit-tap-highlight-color: transparent;

  ${colorStyle}
`;

// A box shadow with a 9px spread yields an overall icon size of 42px (or 30px
// for small icons), which is the same size as the icon-only (circular) tertiary
// CTAs.
const iconBoxShadowSpreadRadius = '9px';

// styled component for links (with or without icons, emphasis tertiary)
const StyledLink = styled.a<{
  readonly disabled?: boolean;
  readonly emphasis?: Emphasis;
  readonly circular?: boolean;
  readonly size?: Size;
  readonly iconPosition?: IconPosition;
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
}>`
  font-weight: inherit;
  display: inline;
  ${createStretchContentForBreakpoints}
  border: none;
  position: relative;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 0;

  ${colorStyle}

  ${(props) =>
    !props.disabled &&
    css`
      :hover,
      :active {
        ${props.circular
          ? css`
              ::before {
                content: '';
                border-radius: 50%;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
              }
            `
          : css`
              ${StyledLinkIcon} {
                ::before {
                  content: '';
                  border-radius: 50%;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                }
              }
            `}
      }
      :hover:not(:focus) {
        ${StyledLinkText} {
          background-color: ${props.theme.hoverIndicatorColor.outlinedElement
            .default};
        }
        ${StyledLinkWrapper} ${StyledLinkText} {
          background-color: inherit;
        }
        ${props.circular
          ? css`
              ::before {
                background-color: ${props.theme.hoverIndicatorColor
                  .outlinedElement.default};
              }
            `
          : css`
              ${StyledLinkIcon} {
                ::before {
                  background-color: ${props.theme.hoverIndicatorColor
                    .outlinedElement.default};
                  box-shadow: ${props.iconPosition !== 'top' &&
                  `0 0 0 ${iconBoxShadowSpreadRadius}
									${props.theme.hoverIndicatorColor.outlinedElement.default}`};
                }
              }
            `}
      }

      :active ${StyledLinkText}::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${props.theme.hoverIndicatorColor.outlinedElement
          .active};
      }

      :focus {
        outline: none;
        ${props.circular &&
        css`
          ${focusStyle}
          ${focusStyleBoxShadow}
					border-color: ${props.theme.interaction.recipe450.default};
        `}
        ${StyledLinkText} {
          ${focusStyle}
          text-underline-offset: 0.2em;
          text-decoration-thickness: 2px;
          text-decoration-skip-ink: none;
          text-decoration-color: ${props.theme.interaction.recipe450.default};
        }
      }
    `}

	text-align: inherit;

  ${(props) =>
    props.circular &&
    css`
      display: inline-block;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: ${props.size === 'small' ? props.theme.size.static400 : '44px'};
      height: ${props.size === 'small' ? props.theme.size.static400 : '44px'};
      border: ${props.size === 'small'
          ? props.theme.size.border.light
          : props.theme.size.border.heavy}
        solid transparent;
      border-radius: 50%;
    `}

  -webkit-tap-highlight-color: transparent;
`;

const CTALink = (
  props: React.PropsWithChildren<CTAProps> & {
    forwardRef:
      | ((instance: CTAElement | null) => void)
      | React.MutableRefObject<CTAElement | null>
      | null;
  }
) => {
  const {
    ariaLabel,
    children,
    disabled,
    emphasis,
    forwardRef,
    icon,
    iconPosition,
    size,
    tag,
    onClick,
    ...rest
  } = props;

  const hasChildren = React.Children.count(children) > 0;

  // depending on the tag we need different props and different types for these props
  const additionalProps =
    isLink(props) && !disabled ? { href: props.href } : {};

  // there is no sane type for the ref of both StyledLink and StyledButton using "as" that works with both components and both tags, so we have to use "any" instead
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = forwardRef as any;

  return (
    <StyledLink
      as={tag}
      aria-label={ariaLabel}
      disabled={disabled}
      {...rest}
      {...additionalProps}
      ref={ref}
      emphasis={emphasis}
      circular={icon && !hasChildren}
      size={size}
      onClick={onClick as React.MouseEventHandler<HTMLElement>}
      iconPosition={iconPosition}
    >
      {icon && hasChildren && (
        <StyledLinkWrapper iconPosition={iconPosition}>
          <StyledLinkIcon iconPosition={iconPosition}>{icon}</StyledLinkIcon>
          <span>
            <StyledLinkText emphasis={emphasis} disabled={disabled}>
              {children}
            </StyledLinkText>
          </span>
        </StyledLinkWrapper>
      )}
      {icon && !hasChildren && <StyledLinkIcon>{icon}</StyledLinkIcon>}
      {hasChildren && !icon && (
        <StyledLinkText emphasis={emphasis} disabled={disabled}>
          {children}
        </StyledLinkText>
      )}
    </StyledLink>
  );
};

const CTAButton = (
  props: React.PropsWithChildren<CTAProps> & {
    forwardRef:
      | ((instance: CTAElement | null) => void)
      | React.MutableRefObject<CTAElement | null>
      | null;
  }
) => {
  const {
    ariaLabel,
    children,
    disabled,
    emphasis,
    forwardRef,
    icon,
    iconPosition,
    size,
    tag,
    onClick,
    ...rest
  } = props;

  const hasChildren = React.Children.count(children) > 0;

  // depending on the tag we need different props and different types for these props
  const additionalProps =
    isLink(props) && !disabled ? { href: props.href } : {};

  // there is no sane type for the ref of both StyledLink and StyledButton using "as" that works with both components and both tags, so we have to use "any" instead
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = forwardRef as any;

  return (
    <StyledButton
      as={tag}
      aria-label={ariaLabel}
      disabled={disabled}
      {...rest}
      {...additionalProps}
      ref={ref}
      emphasis={emphasis}
      circular={icon && !hasChildren}
      size={size}
      onClick={onClick as React.MouseEventHandler<HTMLElement>}
    >
      {icon && !hasChildren && <StyledButtonIcon>{icon}</StyledButtonIcon>}
      {icon && hasChildren && (
        <StyledButtonWrapper iconPosition={iconPosition}>
          <StyledButtonIcon iconPosition={iconPosition}>
            {icon}
          </StyledButtonIcon>
          <Text
            appearance={
              size === 'small'
                ? TextAppearance.label0150
                : TextAppearance.label0200
            }
            color={TextColor.inherit}
            staticSize
          >
            {children}
          </Text>
        </StyledButtonWrapper>
      )}
      {hasChildren && !icon && (
        <Text
          appearance={
            size === 'small'
              ? TextAppearance.label0150
              : TextAppearance.label0200
          }
          color={TextColor.inherit}
          staticSize
        >
          {children}
        </Text>
      )}
    </StyledButton>
  );
};

export const CTA = React.forwardRef<
  CTAElement,
  React.PropsWithChildren<CTAProps>
>((props, forwardRef) => {
  const { emphasis = 'primary', size = 'large', ...rest } = props;

  if (emphasis === 'tertiary' || emphasis === 'none') {
    return (
      <CTALink
        {...rest}
        forwardRef={forwardRef}
        emphasis={emphasis}
        size={size}
      />
    );
  } else {
    return (
      <CTAButton
        {...rest}
        forwardRef={forwardRef}
        emphasis={emphasis}
        size={size}
      />
    );
  }
});

CTA.displayName = 'CTA';
