import * as React from 'react';
import styled from 'styled-components/macro';

import { ButtonPrevious } from '../ButtonPrevious/ButtonPreviuos';
import { ButtonNext } from '../ButtonNext/ButtonNext';
import { GalleryContext } from './GalleryContext';
import { Size } from '../CallToAction/CallToAction';

const StyledArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -25px;
  left: -25px;
  display: flex;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.size.static150};
  transform: translateY(-50%);
`;

export type ArrowButtonsProps =
  | {
      ariaHidden: true;
      buttonNextAriaLabel?: undefined;
      buttonPreviousAriaLabel?: undefined;
      size: Size;
    }
  | {
      ariaHidden?: false;
      buttonNextAriaLabel: string;
      buttonPreviousAriaLabel: string;
      size: Size;
    };

export const ArrowButtons: React.FunctionComponent<ArrowButtonsProps> = ({
  ariaHidden,
  buttonNextAriaLabel,
  buttonPreviousAriaLabel,
  size,
}) => {
  return (
    <GalleryContext.Consumer>
      {/* @ts-ignore */}
      {({ triggerNext, triggerPrevious, hasNavigation }) => (
        <StyledArrowWrapper>
          <ButtonPrevious
            tag="button"
            emphasis="tertiary"
            onClick={triggerPrevious}
            ariaLabel={buttonPreviousAriaLabel}
            size={size}
            tabIndex={hasNavigation || ariaHidden ? -1 : 0} // ignore the button for tab navigation as the arrow keys are enough to navigate through the carousel
          />
          <ButtonNext
            tag="button"
            emphasis="tertiary"
            onClick={triggerNext}
            ariaLabel={buttonNextAriaLabel}
            size={size}
            tabIndex={hasNavigation || ariaHidden ? -1 : 0} // ignore the button for tab navigation as the arrow keys are enough to navigate through the carousel
          />
        </StyledArrowWrapper>
      )}
    </GalleryContext.Consumer>
  );
};
