import React from 'react';
import ImageResponseCardRender from './ImageResponseCardRender';
import CustomPayloadRender from './CustomPayloadRender';
import { Text, TextAppearance, TextColor } from '../../Text/Text';
import {
  ChatEvent,
  Message,
  MessageContentType,
  MessageOrigin,
} from '../../../types';
import styled from 'styled-components/macro';
import SystemMessageRender from './SystemMessageRender';

interface Props {
  message: Message | string;
  enabled: boolean;
  sendMessage: (message: string) => void;
  userSelection: (message: string) => void;
  id: string;
  sendEvent: (event: ChatEvent) => void;
  currentMessage: boolean;
  origin: MessageOrigin;
}

const TextWrapper = styled.div<{ origin: MessageOrigin }>`
  font-size: 16px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-24) var(--unnamed-font-family-vw-text);
  letter-spacing: var(--unnamed-character-spacing--0-24);
  text-align: ${(props) =>
    props.origin === MessageOrigin.BOT ? 'left' : 'right'};
  font: normal normal normal 16px/24px VW Text;
  letter-spacing: -0.24px;
  max-width: 290px;
`;

const MessageRender = ({
  message,
  enabled,
  sendMessage,
  userSelection,
  id,
  sendEvent,
  currentMessage,
  origin,
}: Props): React.ReactElement => {
  if (typeof message === 'string')
    return (
      <TextWrapper origin={origin}>
        <Text color={TextColor.inherit}>{message}</Text>
      </TextWrapper>
    );
  switch (message.contentType) {
    case MessageContentType.CUSTOM_PAYLOAD:
      return (
        <CustomPayloadRender
          message={message}
          enabled={enabled}
          sendMessage={sendMessage}
          userSelection={userSelection}
          id={id}
          sendEvent={sendEvent}
        />
      );
    case MessageContentType.IMAGE_RESPONSE_CARD:
      return (
        <ImageResponseCardRender
          content={message.imageResponseCard}
          enabled={enabled}
          sendMessage={sendMessage}
          userSelection={userSelection}
          sendEvent={sendEvent}
        />
      );
    case MessageContentType.PLAIN_TEXT:
      return (
        <TextWrapper origin={origin}>
          <Text color={TextColor.inherit} appearance={TextAppearance.copy200}>
            {message.content}
          </Text>
        </TextWrapper>
      );
    case MessageContentType.SYSTEM_MESSAGE:
      return (
        <SystemMessageRender
          message={message}
          enabled={enabled}
          sendMessage={sendMessage}
          sendEvent={sendEvent}
          id={id}
          currentMessage={currentMessage}
        />
      );
  }
  return <></>;
};

export default MessageRender;
