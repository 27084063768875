import * as React from 'react';

import styled from 'styled-components/macro';
import {
  getCommonProps,
  getUncommonProps,
  CommonProps,
} from '../../helpers/commonProps';

type ImageObjectFit = 'contain' | 'cover' | 'none' | 'scale-down';

export interface DecorativeImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement>,
    CommonProps {
  readonly className?: string;
  readonly reduceBrightness?: boolean;
  readonly objectFit?: ImageObjectFit;
}

export interface ImageProps extends DecorativeImageProps {
  readonly alt: string;
  readonly srcPlaceholder?: string;
}

export interface LazyImageProps {
  // Internal use only
  readonly _disableNoScript?: boolean;
}

export const Image = styled.img<ImageProps>`
  display: block;
  width: 100%;
  object-fit: ${(props) => props.objectFit};
  ${(props) => props.reduceBrightness && 'filter: brightness(80%)'};
`;

Image.displayName = 'Image';

export const DecorativeImage = React.forwardRef<
  HTMLImageElement,
  DecorativeImageProps
>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { alt, ...others } = props;

  // `aria-hidden`is required to hide it from voiceover
  return <Image alt="" aria-hidden {...others} ref={ref} />;
});

DecorativeImage.displayName = 'DecorativeImage';

const TRANSPARENT_GIF =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export const LazyImage = React.forwardRef<
  HTMLImageElement,
  ImageProps & LazyImageProps
>((props, ref) => {
  const {
    src,
    srcPlaceholder,
    srcSet,
    sizes,
    className,
    alt,
    reduceBrightness,
    _disableNoScript,
    ...others
  } = getUncommonProps(props);
  const commonProps = getCommonProps(props);

  return (
    <>
      {_disableNoScript ? undefined : (
        <noscript>
          <Image
            alt={alt}
            className={className}
            sizes={sizes}
            src={src}
            srcSet={srcSet}
            reduceBrightness={reduceBrightness}
            {...others}
          />
        </noscript>
      )}
      <Image
        // The class `lazyload` is required for our 3rd party lazyloading lib to work
        className={`lazyload ${className || ''}`}
        // If an alt attribute is used, a broken image symbol would be displayed
        // if src would be left empty. Therefore we render a transparent 1px gif
        // until lazyload has replaced the src/srcSet.
        src={srcPlaceholder || TRANSPARENT_GIF}
        data-src={src}
        data-srcset={srcSet}
        data-sizes={sizes}
        alt={alt}
        reduceBrightness={reduceBrightness}
        ref={ref}
        {...others}
        {...commonProps}
      />
    </>
  );
});

LazyImage.displayName = 'LazyImage';

export const DecorativeLazyImage = React.forwardRef<
  HTMLImageElement,
  DecorativeImageProps & LazyImageProps
>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { alt, reduceBrightness, ...others } = props;

  return (
    <LazyImage
      reduceBrightness={reduceBrightness}
      alt=""
      {...others}
      ref={ref}
    />
  );
});

DecorativeLazyImage.displayName = 'DecorativeLazyImage';

export type ImageComponent =
  | typeof Image
  | typeof DecorativeImage
  | typeof LazyImage
  | typeof DecorativeLazyImage;
