import { SimpleInterpolation, css } from 'styled-components/macro';

import {
  createStylesForBreakpoints,
  isBreakpointConfig,
  BreakpointConfigType,
} from '../../helpers/breakpoint';
import {
  DesignTokenSize,
  ThemeDefinition,
  ThemeProviderProps,
  createCSSVarName,
} from '../../theme';

import { ContainerProps } from './container';
import { ContainerHorizontalAlignment, ContainerWrap } from './definitions';

export type { BreakpointConfigType as ContainerBreakpointConfig };

const directions: (keyof ContainerDirectionalConfig<void>)[] = [
  'top',
  'right',
  'bottom',
  'left',
  'inlineStart',
  'inlineEnd',
];

export interface ContainerDirectionalConfig<X> {
  readonly top?: X;
  readonly right?: X;
  readonly bottom?: X;
  readonly left?: X;
  readonly inlineStart?: X;
  readonly inlineEnd?: X;
}

export type ContainerPaddingPropType =
  | DesignTokenSize
  | BreakpointConfigType<DesignTokenSize>
  | ContainerDirectionalConfig<
      DesignTokenSize | BreakpointConfigType<DesignTokenSize>
    >;

export type ThemedContainerProps = ThemeProviderProps<
  ContainerProps,
  ThemeDefinition
>;

function isDirectionalConfig(
  prop?: ContainerPaddingPropType
): prop is ContainerDirectionalConfig<
  DesignTokenSize | BreakpointConfigType<DesignTokenSize>
> {
  return (
    typeof prop === 'object' &&
    Object.keys(prop).every((key) => (directions as string[]).includes(key))
  );
}

// create CSS variables for all dynamic sizes for a given configuration (e.g. container gutter or padding)
export function createCSSVariablesForSizes(
  configOrSize: ContainerPaddingPropType | undefined,
  theme: ThemeDefinition
): string {
  if (!configOrSize) {
    return '';
  }

  if (isDirectionalConfig(configOrSize)) {
    return directions
      .map((key) => {
        const directionalConfigOrSize = configOrSize[key];

        return createCSSVariablesForSizes(directionalConfigOrSize, theme);
      })
      .join('');
  }

  if (isBreakpointConfig(configOrSize)) {
    const cssVar = createCSSVarName(configOrSize, 'container');

    return createStylesForBreakpoints<DesignTokenSize>(
      configOrSize,
      (size: DesignTokenSize) => {
        const value = theme.size[size];

        return `${cssVar}: ${value};`;
      }
    );
  }

  return '';
}

function createSpacing(
  gutter: DesignTokenSize | BreakpointConfigType<DesignTokenSize>,
  theme: ThemeDefinition
): string {
  if (isBreakpointConfig(gutter)) {
    const cssVar = createCSSVarName(gutter, 'container');

    return `var(${cssVar})`;
  }

  return theme.size[gutter];
}

export function createHorizontalAlignmentStyles(
  horizontalAlign:
    | ContainerHorizontalAlignment
    | BreakpointConfigType<ContainerHorizontalAlignment>
): string {
  if (isBreakpointConfig(horizontalAlign)) {
    return createStylesForBreakpoints<
      | ContainerHorizontalAlignment
      | BreakpointConfigType<ContainerHorizontalAlignment>
    >(
      horizontalAlign,
      (breakpointHorizontalAlign) =>
        `justify-content: ${breakpointHorizontalAlign};`
    );
  }

  return `justify-content: ${horizontalAlign};`;
}

function wrapStyles(
  wrap: ContainerWrap,
  theme: ThemeDefinition,
  gutter?: DesignTokenSize | BreakpointConfigType<DesignTokenSize>
): string {
  const spacing = (gutter && createSpacing(gutter, theme)) || '0px';

  switch (wrap) {
    case ContainerWrap.never:
      return `
				display: flex;
				flex-wrap: nowrap;
				margin: 0;
			`;
    case ContainerWrap.always:
      return `
				display: block;
				flex-wrap: unset;
				margin: 0;
			`;
    default: {
      return `
				display: flex;
				flex-wrap: wrap;
				margin: 0 calc(-0.5 * ${spacing}) calc(-1 * ${spacing});
			`;
    }
  }
}

export function createWrapStyles(
  wrap:
    | BreakpointConfigType<ContainerWrap>
    | ContainerWrap = ContainerWrap.auto,
  theme: ThemeDefinition,
  gutter?: BreakpointConfigType<DesignTokenSize> | DesignTokenSize
): SimpleInterpolation | undefined {
  if (isBreakpointConfig(wrap)) {
    return css`
      ${createStylesForBreakpoints<ContainerWrap>(
        wrap,
        (breakpointWrap: ContainerWrap) =>
          wrapStyles(breakpointWrap, theme, gutter)
      )}
    `;
  }

  return wrapStyles(wrap, theme, gutter);
}

function spacingBetweenElementsStyles(
  wrap: BreakpointConfigType<ContainerWrap> | ContainerWrap,
  theme: ThemeDefinition,
  gutter?: DesignTokenSize | BreakpointConfigType<DesignTokenSize>,
  direction?: 'ltr' | 'rtl'
): string {
  const spacing = (gutter && createSpacing(gutter, theme)) || '0px';

  switch (wrap) {
    case ContainerWrap.never:
      return `
				margin: 0 calc(${spacing} / 2);
				:first-child {
					${
            (theme.direction || direction) === 'rtl'
              ? `margin: 0 0 0 calc(${spacing} / 2);`
              : `margin: 0 calc(${spacing} / 2) 0 0;`
          }
				}
				:last-child {
					${
            (theme.direction || direction) === 'rtl'
              ? `margin: 0 calc(${spacing} / 2) 0 0;`
              : `margin: 0 0 0 calc(${spacing} / 2);`
          }
				}
			`;
    case ContainerWrap.always:
      return `
				margin: 0 0 ${spacing};
				:last-child {
					margin: 0;
				}
			`;
    default:
      return `
				margin: 0 calc(${spacing} / 2) ${spacing};
				:last-child {
					margin: 0 calc(${spacing} / 2) ${spacing};
				}
			`;
  }
}

export function createSpacingBetweenElementsStyles(props: {
  readonly wrapped?: BreakpointConfigType<ContainerWrap> | ContainerWrap;
  readonly theme: ThemeDefinition;
  readonly gutter?: BreakpointConfigType<DesignTokenSize> | DesignTokenSize;
  readonly direction?: 'ltr' | 'rtl';
}): SimpleInterpolation | undefined {
  if (isBreakpointConfig(props.wrapped)) {
    return css`
      ${createStylesForBreakpoints<ContainerWrap>(
        props.wrapped,
        (wrap: ContainerWrap) =>
          spacingBetweenElementsStyles(
            wrap,
            props.theme,
            props.gutter,
            props.direction
          )
      )}
    `;
  }

  return spacingBetweenElementsStyles(
    props.wrapped || ContainerWrap.auto,
    props.theme,
    props.gutter,
    props.direction
  );
}

function createPaddings(value: string, key: string) {
  switch (key) {
    case 'inlineStart':
      return `
				padding-inline-start: ${value};
			`;
    case 'inlineEnd':
      return `
				padding-inline-end: ${value};
			`;
    default:
      return `padding-${key}: ${value};`;
  }
}

function createDirectionalSpacings(
  key: keyof ContainerDirectionalConfig<void>,
  padding: ContainerDirectionalConfig<
    DesignTokenSize | BreakpointConfigType<DesignTokenSize>
  >,
  theme: ThemeDefinition
) {
  const directionalPadding = padding[key];

  if (isBreakpointConfig(directionalPadding)) {
    const cssVar = createCSSVarName(directionalPadding, 'container');

    return createPaddings(`var(${cssVar})`, key);
  }

  if (typeof directionalPadding === 'string' && theme) {
    return createPaddings(theme.size[directionalPadding], key);
  }

  return '';
}

export function createSpacingAroundElementsStyles(
  padding: ContainerPaddingPropType,
  theme: ThemeDefinition
): SimpleInterpolation {
  if (typeof padding === 'string' && theme) {
    return css`
      padding: ${theme.size[padding]};
    `;
  }

  if (isDirectionalConfig(padding)) {
    return css`
      padding: 0;
      ${directions.reduce(
        (acc, key: keyof ContainerDirectionalConfig<void>) => {
          return `
						${acc}
						${createDirectionalSpacings(key, padding, theme)}
					`;
        },
        ''
      )};
    `;
  }

  if (isBreakpointConfig(padding)) {
    const cssVar = createCSSVarName(padding, 'container');

    return css`
      padding: var(${cssVar});
    `;
  }

  return css`
    padding: 0;
  `;
}
