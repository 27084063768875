import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Text, TextAppearance, TextColor } from '../../Text/Text';
import { Image } from '../../Image/Image';
import {
  ChatEvent,
  CustomPayloadButtons,
  ImageResponseCard,
} from '../../../types';
import ButtonsRender from './ButtonsRender';

interface Props {
  content?: ImageResponseCard | CustomPayloadButtons;
  enabled: boolean;
  sendMessage: (message: string) => void;
  userSelection: (message: string) => void;
  sendEvent: (event: ChatEvent) => void;
}

const RootWrapper = styled.div``;

const getDir = (arg?: ImageResponseCard) => {
  return arg && (arg as CustomPayloadButtons).dir;
};

const getButtonsSize = (arg?: ImageResponseCard) => {
  if (arg && (arg as CustomPayloadButtons).size !== undefined)
    return (arg as CustomPayloadButtons).size;
  else return 'large';
};

const ImageResponseCardRender = ({
  content,
  enabled,
  sendMessage,
  userSelection,
  sendEvent,
}: Props): React.ReactElement => {
  const dir = useMemo(() => getDir(content), [content]);
  const buttonsSize = useMemo(() => getButtonsSize(content), [content]);
  if (content) {
    const { subtitle, imageUrl, title, buttons } = content;
    return (
      <RootWrapper>
        {imageUrl && (
          <Image src={imageUrl} objectFit={'contain'} alt={'header image'} />
        )}
        <div>
          {title && (
            <Text
              color={TextColor.inherit}
              appearance={TextAppearance.copy200}
              staticSize={true}
            >
              {title}
            </Text>
          )}
          {subtitle && <Text>{subtitle}</Text>}
        </div>
        <ButtonsRender
          buttons={buttons}
          size={buttonsSize}
          dir={dir}
          enabled={enabled}
          sendMessage={sendMessage}
          userSelection={userSelection}
          sendEvent={sendEvent}
        />
      </RootWrapper>
    );
  }
  return <></>;
};

export default ImageResponseCardRender;
