import * as React from 'react';

export type GalleryContextValue = {
  hasNavigation: boolean;
  index: number;
  triggerChange: (newIndex: number) => void;
  triggerNext: () => void;
  triggerPrevious: () => void;
  getSlideId: (index: number) => string;
  getTabId: (index: number) => string;
};

const noop = () => {
  // this function is intentionally left blank
};

export const GalleryContext = React.createContext<GalleryContextValue>({
  hasNavigation: false,
  index: 0,
  triggerChange: noop,
  triggerNext: noop,
  triggerPrevious: noop,
  getTabId: () => '',
  getSlideId: () => '',
});
