import * as React from 'react';
import { withTheme } from 'styled-components/macro';

import { ThemeDefinition } from '../../theme';
import styled from 'styled-components/macro';
import { AriaLabelOrLabelledby } from '../../helpers/types';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';
import { GalleryContext } from './GalleryContext';

export type GalleryProps = {
  readonly theme: ThemeDefinition;
  readonly id: string;
  readonly arrowButtons: React.ReactNode;
  readonly navigation: React.ReactNode;
  readonly handleChange?: (activeIndex: number) => void;
  readonly endlessScrolling?: boolean;
  readonly children:
    | React.ReactElement<AriaLabelOrLabelledby>
    | React.ReactElement<AriaLabelOrLabelledby>[];
} & AriaLabelOrLabelledby &
  CommonProps;

interface GalleryState {
  readonly index: number;
}

const StyledCarousel = styled.section`
  overflow: none;
  width: 100%;
  position: relative;

  ::before {
    content: '';
    display: block;
  }

  :focus {
    outline: none;
  }
`;

const StyledSlide = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;

  user-select: none; // disable selection

  outline: none;
`;

const StyledSlides = styled.div`
  display: flex;
  scroll-snap-type: x mandatory;
  padding-bottom: 24px;
  touch-action: pan-x;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const StyledContainedImageWrapper = styled.div`
  /*
   * create a "safe-zone" so the controls do not overlap the image
   * calculation: 100% - 2 (arrows) * width of the arrows(40px)
   width: calc(100% - 2 * ${(props) => props.theme.size.static100});
  */
  margin-top: 5px;
`;

export const GallerySlide: React.FunctionComponent<AriaLabelOrLabelledby> = ({
  ariaLabel,
  ariaLabelledby,
  children,
}): JSX.Element => (
  <GalleryContext.Consumer>
    {({ index: activeIndex, hasNavigation, getSlideId, getTabId }) => (
      <GallerySlideContext.Consumer>
        {({ index }) => {
          const ariaProps =
            index !== activeIndex
              ? {
                  'aria-hidden': true,
                }
              : hasNavigation
              ? {
                  role: 'tabpanel',
                  'aria-labelledby': getTabId(index),
                }
              : {
                  role: 'group',
                  'aria-label': ariaLabel,
                  'aria-labelledby': ariaLabelledby,
                  'aria-roledescription': 'slide',
                };

          return (
            <StyledSlide
              id={getSlideId(index)}
              {...ariaProps}
              tabIndex={activeIndex === index ? 0 : -1}
            >
              <StyledContainedImageWrapper>
                {children}
              </StyledContainedImageWrapper>
            </StyledSlide>
          );
        }}
      </GallerySlideContext.Consumer>
    )}
  </GalleryContext.Consumer>
);

const GallerySlideContext = React.createContext<{
  index: number;
}>({ index: 0 });

class InternalGallery extends React.PureComponent<GalleryProps, GalleryState> {
  private readonly slides: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  public constructor(props: GalleryProps) {
    super(props);

    this.state = {
      index: 0,
    };
  }

  public componentDidMount(): void {
    const { endlessScrolling } = this.props;
    if (endlessScrolling) {
      this.changeSlide(0, false);
    }
  }

  private isSlidingAutomatically = false;

  private readonly changeSlide = (nextIndex: number, animate = true) => {
    const { endlessScrolling, handleChange, theme } = this.props;
    const slides = this.slides.current;
    if (!slides) {
      return;
    }
    const length = React.Children.count(this.props.children);
    const shouldOverscroll = length > 2 && endlessScrolling;
    const effectiveIndex = shouldOverscroll
      ? nextIndex
      : (nextIndex + length) % length;
    this.setState({ index: effectiveIndex }, () => {
      // use setState callback to defer this and prevent rerender during smooth scroll
      const slideWidth = slides.getBoundingClientRect().width;
      const targetPosition =
        slideWidth * (effectiveIndex + (shouldOverscroll ? 1 : 0));
      const left =
        theme.direction === 'rtl' ? -1 * targetPosition : targetPosition;
      this.isSlidingAutomatically = true;
      slides.scrollTo({
        left,
        behavior: animate ? 'smooth' : 'auto',
      });
      if (handleChange) {
        handleChange(effectiveIndex);
      }
    });
  };

  private readonly slideToNextSlide = () => {
    const { index } = this.state;
    this.changeSlide(index + 1);
  };

  private readonly slideToPreviousSlide = () => {
    const { index } = this.state;
    this.changeSlide(index - 1);
  };

  private readonly handleChange = (newIndex: number) => {
    const childrenLength = React.Children.count(this.props.children);
    this.changeSlide((newIndex + childrenLength) % childrenLength);
  };

  private readonly handleScroll = (e: React.MouseEvent<HTMLDivElement>) => {
    const { endlessScrolling, theme } = this.props;
    const { index } = this.state;
    const slideContainer = e.currentTarget;
    const scrollStart =
      theme.direction === 'rtl'
        ? -1 * slideContainer.scrollLeft
        : slideContainer.scrollLeft;
    const slideWidth = slideContainer.getBoundingClientRect().width;
    const length = React.Children.count(this.props.children);
    const scrollIndex =
      Math.round(scrollStart / slideWidth) -
      (length > 2 && endlessScrolling ? 1 : 0);

    if (this.isSlidingAutomatically && index === scrollIndex) {
      this.isSlidingAutomatically = false;
    }
    if (scrollIndex === -1 && scrollStart === 0) {
      // endlessScrolling only
      this.changeSlide(length - 1, false);
      this.setState({ index: length - 1 });
    } else if (
      scrollIndex === length &&
      scrollStart === slideWidth * (length + 1)
    ) {
      // endlessScrolling only
      this.changeSlide(0, false);
      this.setState({ index: 0 });
    } else if (scrollIndex !== index && !this.isSlidingAutomatically) {
      this.setState({ index: scrollIndex });
    }
  };

  public render(): JSX.Element {
    const {
      id,
      children,
      arrowButtons,
      navigation,
      endlessScrolling = false,
      ariaLabel,
      ariaLabelledby,
    } = this.props;
    const commonProps = getCommonProps(this.props);
    const { index } = this.state;
    const length = React.Children.count(this.props.children);

    const effectiveIndex =
      index === -1 ? length - 1 : index === length ? 0 : index;

    return (
      <GalleryContext.Provider
        value={{
          hasNavigation: Boolean(navigation),
          index: effectiveIndex,
          triggerChange: this.handleChange,
          triggerPrevious: this.slideToPreviousSlide,
          triggerNext: this.slideToNextSlide,
          getSlideId: (index: number) => `${id}__slide-${index}`,
          getTabId: (index: number) => `${id}__tab-${index}`,
        }}
      >
        <StyledCarousel
          id={id}
          tabIndex={-1}
          aria-label={ariaLabel}
          aria-roledescription={navigation ? undefined : 'carousel'}
          aria-labelledby={ariaLabelledby}
          {...commonProps}
        >
          <StyledSlides
            ref={this.slides}
            onScroll={this.handleScroll}
            aria-atomic="false"
            aria-live="polite"
          >
            {endlessScrolling && length > 2 && (
              <GallerySlideContext.Provider value={{ index: -1 }}>
                {React.Children.toArray(children)[length - 1]}
              </GallerySlideContext.Provider>
            )}
            {React.Children.map(children, (child, index) => (
              <GallerySlideContext.Provider value={{ index }}>
                {child}
              </GallerySlideContext.Provider>
            ))}
            {endlessScrolling && length > 2 && (
              <GallerySlideContext.Provider value={{ index: length }}>
                {React.Children.toArray(children)[0]}
              </GallerySlideContext.Provider>
            )}
          </StyledSlides>
          {arrowButtons}
          {navigation}
        </StyledCarousel>
      </GalleryContext.Provider>
    );
  }
}

export const Gallery = withTheme(InternalGallery);
