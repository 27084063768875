import {
  Button,
  ButtonsDirection,
  ButtonsSize,
  ChatEvent,
  CustomPayloadButton,
  CustomPayloadButtonsType,
  CustomPayloadLinksOpenType,
} from '../../../types';
import React from 'react';
import styled from 'styled-components/macro';
import { CTA } from '../../CallToAction/CallToAction';

interface Props {
  buttons?: CustomPayloadButton[] | Button[];
  dir?: ButtonsDirection;
  enabled: boolean;
  size: ButtonsSize;
  sendMessage: (message: string) => void;
  userSelection: (message: string) => void;
  sendEvent: (event: ChatEvent) => void;
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.dir?.startsWith('horizontal') ? 'row' : 'column'};
  justify-content: ${(props) =>
    props.dir?.startsWith('horizontal') ? 'center' : ''};
  align-items: ${(props) =>
    props.dir?.startsWith('horizontal') ? 'center' : ''};
  width: 310px;
`;

const CTAOverride = styled(CTA)`
  padding: 10px;
  margin: 3px 5px 0 0;
  color: #001e50;
  background-color: white;
  border: 1px solid;
  border-radius: 22px;
  max-width: 268px;
  min-width: 80px;

  :active {
    background-color: #002c5f;
    color: white;
  }
  :focus {
    background-color: #002c5f;
  }
`;

const isCustomPayloadButton = (
  arg: Button | CustomPayloadButton
): arg is CustomPayloadButton => {
  return (arg as CustomPayloadButton).type !== undefined;
};

const ButtonsRender = ({
  buttons,
  dir,
  enabled,
  sendMessage,
  userSelection,
  sendEvent,
  size,
}: Props) => {
  const buttonClickHandler = (button: CustomPayloadButton) => {
    switch (button.type) {
      case CustomPayloadButtonsType.LINK:
        button.typeLink === CustomPayloadLinksOpenType.EXTERNAL
          ? window.open(button.value, '_blank')
          : (window.location.href = button.value);
        break;
      case CustomPayloadButtonsType.INTENT:
        sendMessage(button.value as string);
        userSelection(button.text);
        break;
      case CustomPayloadButtonsType.EVENT:
        sendEvent(button.value);
        break;
    }
  };
  return (
    <ButtonsContainer dir={dir}>
      {buttons?.map((button, index) =>
        isCustomPayloadButton(button) ? (
          <CTAOverride
            disabled={!enabled}
            emphasis={
              dir === 'horizontal-lightweight' ? 'secondary' : 'primary'
            }
            key={`${index}-custom`}
            tag="button"
            size={size}
            onClick={enabled ? () => buttonClickHandler(button) : undefined}
          >
            {button.text}
          </CTAOverride>
        ) : (
          <CTAOverride
            disabled={!enabled}
            tag="button"
            emphasis="secondary"
            key={`${index}-standard`}
            size={size}
            onClick={
              enabled ? () => sendMessage(button.value as string) : undefined
            }
          >
            {button.text}
          </CTAOverride>
        )
      )}
    </ButtonsContainer>
  );
};

export default ButtonsRender;
