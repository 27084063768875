export type DesignTokenColorName =
  | 'white-000'
  | 'black-000'
  | 'grey-100'
  | 'grey-200'
  | 'grey-300'
  | 'grey-400'
  | 'grey-500'
  | 'blue-100'
  | 'blue-200'
  | 'blue-350'
  | 'blue-500'
  | 'blue-600'
  | 'blue-900'
  | 'mint-100'
  | 'red-100'
  | 'red-200'
  | 'purple-100'
  | 'green-100'
  | 'green-200'
  | 'yellow-000'
  | 'transparent'
  | 'black-000-transparent-10'
  | 'black-000-transparent-50'
  | 'black-000-transparent-80'
  | 'grey-100-transparent-40'
  | 'grey-200-transparent-20'
  | 'white-000-transparent-20'
  | 'white-000-transparent-40'
  | 'white-000-transparent-98';

export enum DesignTokenSizeEnum {
  static0 = 'static0',
  static100 = 'static100',
  static150 = 'static150',
  static200 = 'static200',
  static250 = 'static250',
  static300 = 'static300',
  static350 = 'static350',
  static370 = 'static370',
  static400 = 'static400',
  static450 = 'static450',
  static500 = 'static500',
  static510 = 'static510',
  static515 = 'static515',
  static520 = 'static520',
  static530 = 'static530',
  static535 = 'static535',
  static550 = 'static550',
  static565 = 'static565',
  static575 = 'static575',
  static600 = 'static600',
  static625 = 'static625',
  static650 = 'static650',
  static700 = 'static700',
  static750 = 'static750',
  static800 = 'static800',
  static900 = 'static900',
  static1000 = 'static1000',
  static1100 = 'static1100',
  static1200 = 'static1200',
  grid001 = 'grid001',
  grid002 = 'grid002',
  grid003 = 'grid003',
  grid004 = 'grid004',
  grid005 = 'grid005',
  grid006 = 'grid006',
  grid007 = 'grid007',
  grid008 = 'grid008',
  grid009 = 'grid009',
  grid010 = 'grid010',
  grid011 = 'grid011',
  grid012 = 'grid012',
  grid013 = 'grid013',
  grid014 = 'grid014',
  grid015 = 'grid015',
  grid016 = 'grid016',
  grid017 = 'grid017',
  grid018 = 'grid018',
  grid019 = 'grid019',
  grid020 = 'grid020',
  grid021 = 'grid021',
  grid022 = 'grid022',
  grid023 = 'grid023',
  grid024 = 'grid024',
  dynamic0020 = 'dynamic0020',
  dynamic0040 = 'dynamic0040',
  dynamic0050 = 'dynamic0050',
  dynamic0100 = 'dynamic0100',
  dynamic0120 = 'dynamic0120',
  dynamic0130 = 'dynamic0130',
  dynamic0140 = 'dynamic0140',
  dynamic0150 = 'dynamic0150',
  dynamic0200 = 'dynamic0200',
  dynamic0250 = 'dynamic0250',
  dynamic0270 = 'dynamic0270',
  dynamic0300 = 'dynamic0300',
  dynamic0350 = 'dynamic0350',
  dynamic0450 = 'dynamic0450',
}

// type used for typing the keys of the size section of the theme definition and the size section of the design tokens
export type DesignTokenSize = keyof typeof DesignTokenSizeEnum;

// type used for typing the size section of the them definition
export type ThemeDefinitionSizeType = { [alias in DesignTokenSize]: string } & {
  readonly line: {
    readonly default: string;
    readonly active: string;
  };
  readonly border: {
    readonly light: string;
    readonly heavy: string;
  };
};

// type used for typing the size section of the design tokens
export type DesignTokenSizeType = {
  [alias in DesignTokenSize]?: string | DynamicToken[];
} & {
  readonly line100: string;
  readonly line200: string;
};

export type DesignTokenAnimationDuration =
  | 'duration300'
  | 'duration400'
  | 'duration500'
  | 'duration600'
  | 'duration2000';

export enum DesignTokenFontSizes {
  size0100 = 'size0100',
  size0150 = 'size0150',
  size0200 = 'size0200',
  size0250 = 'size0250',
  size0300 = 'size0300',
  size0350 = 'size0350',
  size0400 = 'size0400',
  size0450 = 'size0450',
  size0500 = 'size0500',
  size0550 = 'size0550',
  size0600 = 'size0600',
}

export interface DynamicToken {
  readonly breakpoint: string;
  readonly value: string;
}

export interface TokenTextProperties {
  readonly fontFamily: string;
  readonly fontSize: string | DynamicToken[];
  readonly reducedFontSize: string | DynamicToken[];
  readonly lineHeight: string | DynamicToken[];
  readonly letterSpacing: string | DynamicToken[];
  readonly static: {
    readonly fontSize: string;
    readonly lineHeight: string;
    readonly letterSpacing: string;
    readonly reducedFontSize: string;
  };
}

export interface DesignTokens {
  /**
   * Possible animations by alias
   */
  readonly animation: {
    readonly duration: {
      readonly duration300: string;
      readonly duration400: string;
      readonly duration500: string;
      readonly duration600: string;
      readonly duration2000: string;
    };
    readonly timingFunction: {
      readonly timing100: string;
      readonly timing200: string;
      readonly timing300: string;
      readonly timing400: string;
    };
  };
  /**
   * Possible colors in hex code by alias
   */
  readonly color: { [alias in DesignTokenColorName]: string };
  readonly textAppearances: {
    readonly [alias: string]: TokenTextProperties;
  };
  /**
   * Possible font faces by alias
   */
  readonly font: {
    readonly weight: {
      readonly regular: string;
      readonly light: string;
      readonly bold: string;
    };
  };
  /**
   * Possible sizes by alias
   */
  readonly size: DesignTokenSizeType;
}

/**
 * Utility type for constructing deep partial types
 */
export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

/**
 * Allow partial theme definitions for partial overrides
 */
export type PartialThemeDefinition = DeepPartial<ThemeDefinition>;

/**
 * Names of pre-defined themes
 */
export type ThemeName = 'main' | 'inverted' | 'image';

export type ThemeReference = ThemeName | PartialThemeDefinition | undefined;

export interface ThemeSet {
  readonly [themeName: string]: PartialThemeDefinition;
  /**
   * The main theme definition. This is the default theme.
   */
  readonly main: ThemeDefinition;
}

export interface ThemeTextProperties {
  readonly fontFamily?: string;
  readonly fontSize?: string;
  readonly reducedFontSize?: string;
  readonly lineHeight?: string;
  readonly letterSpacing?: string;
  readonly static: {
    readonly fontSize?: string;
    readonly lineHeight?: string;
    readonly letterSpacing?: string;
    readonly reducedFontSize?: string;
  };
}

export interface ThemeDefinition {
  readonly global: {
    readonly backgroundColor: string;
    readonly shimColor: string;
    readonly eyeCatcherColor: string;
  };
  readonly navigation: {
    readonly backgroundColor: string;
    readonly backgroundTransparency: string;
  };
  readonly referenceBadge: {
    readonly backgroundColor: {
      readonly default: string;
      readonly active: string;
    };
    readonly textColor: {
      readonly default: string;
      readonly active: string;
    };
    readonly outlineColor?: string;
  };
  readonly nextBestAction: {
    readonly transition: {
      readonly backgroundColor: string;
    };
  };
  readonly transparentColor: string;
  readonly hoverIndicatorColor: {
    readonly filledElement: {
      readonly default: string;
      readonly active: string;
    };
    readonly outlinedElement: {
      readonly default: string;
      readonly active: string;
    };
  };
  readonly decoratorColor: string;
  readonly focusColor: string;
  readonly separatorColor: string;
  readonly shadowColor: string;
  readonly focus: {
    background: string;
    foreground: string;
  };
  readonly backgroundGradient: {
    readonly darkColor: string;
    readonly lightColor: string;
  };
  readonly validation: {
    readonly success: string;
    readonly error: string;
  };
  readonly video: {
    readonly background: string;
  };
  readonly shim: {
    readonly keepContext: string;
    readonly hideContext: string;
    readonly enhanceContrast: string;
  };
  readonly interaction: { readonly [alias: string]: ColorRecipe };
  readonly animation: {
    readonly duration: {
      readonly [alias: string]: string | undefined;
    };
    readonly timingFunction: {
      readonly in: string;
      readonly out: string;
      readonly linear: string;
      readonly cubicOut: string;
      readonly navigation: string;
    };
  };
  readonly textAppearances: {
    readonly [alias: string]: ThemeTextProperties;
  };
  readonly text: {
    readonly color: {
      readonly primary: string;
      readonly primaryHover: string;
      readonly secondary: string;
    };
    readonly weight: {
      readonly regular: string;
      readonly light: string;
      readonly bold: string;
    };
    readonly background: {
      readonly primary: string;
    };
  };
  readonly border: {
    readonly color: {
      readonly primary: string;
      readonly secondary: string;
    };
  };
  readonly size: ThemeDefinitionSizeType;
  readonly direction?: 'ltr' | 'rtl';
  readonly language?: string;
}

export interface ColorRecipe {
  readonly default: string;
  readonly hover?: string;
  readonly active?: string;
  readonly disabled?: string;
  readonly checked?: string;
}
