import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import dialogReducer from './dialog/reducer';
import { DialogAction } from './dialog/types';

export const store = configureStore({
  reducer: {
    dialog: dialogReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppAction = DialogAction;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AppAction
>;
