import React from 'react';
import { Helmet } from 'react-helmet';

// This styles fix global overrides by bootstrap and other normalize CSS present in VW pages
const globalStyles = `
.chatBotContainer * {
  box-sizing: unset;
  line-height: 1.15;
}
.chatBotContainer button {
  box-sizing: border-box;
}
.chatBotContainer label {
  font-weight: unset;
  margin-bottom: initial;
}
.chatBotHeader * {
  line-height: 19px;
}
`;

// We should use react-helmet.d.ts to inject global styles until it get fixed in styled-components
// https://github.com/styled-components/styled-components/issues/3146
const GlobalStyle = () => {
  return (
    // placement property supported by https://github.com/lokeshrana9999/react-helmet-with-placement-prop
    // @ts-ignore
    <Helmet placement={'top'}>
      <style>{globalStyles}</style>
    </Helmet>
  );
};

export default GlobalStyle;
