const ANIMATION_FUNCTION = 'cubic-bezier(0.14, 1.12, 0.67, 0.99)';
const DEFAULT_ANIMATION_DURATION = '0.3s';

export interface ElectricTransitionAdvanced {
  readonly name: string;
  readonly duration?: string;
  readonly delay?: string;
}

export type ElectricTransition = string | ElectricTransitionAdvanced;

function normalizeTransitionProps(
  transition: ElectricTransition
): ElectricTransitionAdvanced {
  return typeof transition === 'string'
    ? {
        name: transition,
        duration: DEFAULT_ANIMATION_DURATION,
      }
    : {
        ...transition,
        duration: transition.duration || DEFAULT_ANIMATION_DURATION,
      };
}

function createTransitionForProperty(transition: ElectricTransition): string {
  const { name, duration, delay } = normalizeTransitionProps(transition);
  const delayToAppend = delay ? ` ${delay}` : '';

  return `${name} ${duration} ${ANIMATION_FUNCTION}${delayToAppend}`;
}

export function createElectricTransition(
  ...transitions: ElectricTransition[]
): string | undefined {
  return transitions.length > 0
    ? `transition: ${transitions.map(createTransitionForProperty).join(', ')}`
    : undefined;
}
