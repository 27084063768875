import * as React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/macro';

import { ThemeDefinition, ThemeReference } from './definitions';
import { getTheme } from './get-theme';

export type ThemePropHelper = (
  propertyPath: string | undefined
) => (componentProps: ThemeProviderProps) => string | undefined;

// this interface needs to be exported explicitly.
// see https://github.com/Microsoft/TypeScript/issues/5711
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ThemeContextOptions {}

export type ThemeProviderProps<
  AdditionalProps = Record<string, unknown>,
  Theme = ThemeReference
> = AdditionalProps & {
  readonly theme?: Theme;
  readonly children?: React.ReactNode;
  readonly themeContextOptions?: ThemeContextOptions;
  readonly direction?: 'ltr' | 'rtl';
  readonly language?: string;
};

export const ThemeProviderContext: React.Context<ThemeContextOptions> =
  React.createContext({});

/**
 * ThemeProvider component
 *
 * Provides a theme object to all it's child components via `styled-components`' ThemeProvider
 *
 * @param props Component properties
 */
export function ThemeProvider(props: ThemeProviderProps): JSX.Element | null {
  const {
    children,
    direction,
    language,
    theme: newTheme,
    themeContextOptions,
  } = props;

  const validChildren: React.ReactElement[] = React.Children.toArray(
    children
  ).filter(React.isValidElement);

  if (!validChildren.length) {
    return null;
  }

  // Hint: Do not remove the fragment (<>...</>) below. It is absolutely
  // necessary to ensure the theme provider's children have the correct type.
  return (
    <ThemeProviderContext.Consumer>
      {(context) => (
        <ThemeProviderContext.Provider
          value={{ ...context, ...themeContextOptions }}
        >
          <StyledComponentsThemeProvider
            theme={(currentTheme: ThemeDefinition) =>
              getTheme(currentTheme, newTheme, language, direction)
            }
          >
            <>{validChildren}</>
          </StyledComponentsThemeProvider>
        </ThemeProviderContext.Provider>
      )}
    </ThemeProviderContext.Consumer>
  );
}
