import localforage from 'localforage';
import { DialogCache } from '../types';

export enum CacheKey {
  DIALOG_STATE_CACHE_KEY = 'vw-chatbot-dialog-state',
}

export interface CacheKeyMap {
  [CacheKey.DIALOG_STATE_CACHE_KEY]: DialogCache;
}

export default class CacheManager {
  writeData = <TCacheKey extends CacheKey>(
    key: TCacheKey,
    data: CacheKeyMap[TCacheKey]
  ) => localforage.setItem<CacheKeyMap[TCacheKey]>(key, data);

  readData = <TCacheKey extends CacheKey>(key: TCacheKey) =>
    localforage.getItem<CacheKeyMap[TCacheKey]>(key);

  removeData = (key: CacheKey) => localforage.removeItem(key);

  clear = () => localforage.clear();
}
