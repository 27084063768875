export interface TestProps {
  readonly 'data-testid'?: string;
}

// This rule can be removed when we have additional shared props.
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CommonProps extends TestProps {}

export const getCommonProps = (
  props:
    | CommonProps
    | (CommonProps & {
        [otherProps: string]: unknown;
      })
): CommonProps => {
  return { 'data-testid': props['data-testid'] };
};

export const getUncommonProps = <T>(props: CommonProps & T): T => {
  const commonPropKeys = Object.keys(getCommonProps(props));

  return (
    (Object.keys(props) as (keyof CommonProps & T)[]).filter(
      (propKey) => !commonPropKeys.includes(propKey)
    ) as (keyof CommonProps)[]
  ).reduce(
    (accumulator, propKey) => ({
      ...accumulator,
      [propKey]: props[propKey],
    }),
    {} as T
  );
};
