import React, { MouseEventHandler, FormEventHandler } from 'react';
import ChatBotSendButton from './ChatBotSendButton';
import ChatBotTextInput from './ChatBotTextInput';
import { HandleTextInputEvent } from '../TextInput/TextInput';
import styled from 'styled-components/macro';

export interface ChatState {
  message: string;
  icon: React.ReactElement;
  inputDisabled: boolean;
  sendButtonDisabled: boolean;
}

interface Props {
  textEnabled: boolean;
  currentChatState: ChatState;
  inputText: string;
  handleSendButton: MouseEventHandler<HTMLButtonElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onChange: HandleTextInputEvent;
  disabled?: boolean;
}

const InputsWrapper = styled.div`
  display: flex;
`;

const SendButtonWrapper = styled.div`
  width: 20%;
  margin-top: 7px;
`;

const FormWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
  margin-top: 5px;
  font-family: var(
    --unnamed-font-family-vw-text,
    vw-text,
    Helvetica,
    Arial,
    sans-serif
  );
`;

const TextInputWrapper = styled.div`
  display: flex;
  width: 140px;
`;

function ChatBotInputs({
  textEnabled,
  onChange,
  inputText,
  onSubmit,
  handleSendButton,
  currentChatState,
  disabled,
}: Props) {
  let textInputDisabled =
    currentChatState.inputDisabled || !textEnabled || !!disabled;
  return (
    <InputsWrapper id={'inputs-wrapper'}>
      <FormWrapper id={'form-wrapper'}>
        <TextInputWrapper>
          <form id={'form'} onSubmit={onSubmit}>
            <ChatBotTextInput
              placeholder={currentChatState.message}
              onChange={onChange}
              inputText={inputText}
              inputDisabled={textInputDisabled}
            />
          </form>
        </TextInputWrapper>
      </FormWrapper>
      <SendButtonWrapper id={'send-btn-wrapper'}>
        <ChatBotSendButton
          sendIcon={currentChatState.icon}
          sendButtonDisabled={textInputDisabled}
          handleSendButton={handleSendButton}
        />
      </SendButtonWrapper>
    </InputsWrapper>
  );
}

export default ChatBotInputs;
