import { Message, MessageOrigin } from '../../types';
import { ActionPayload } from '../action';
import { Action } from 'redux';

export interface DialogStateMessage {
  message: Message | string;
  from: MessageOrigin;
}

export interface DialogState {
  messages: DialogStateMessage[];
  dialogLegalAccepted: boolean;
  dialogInitialized: boolean;
  dialogInitializing: boolean;
  dialogRestored: boolean;
  sessionId?: string;
}

export enum DialogActionType {
  initializeDialogRequested = 'vw-chatbot/dialog/initialize-dialog-requested',
  initializeDialogDone = 'vw-chatbot/dialog/initialize-dialog-done',
  addMessagesRequested = 'vw-chatbot/dialog/add-messages-requested',
  clearMessagesRequested = 'vw-chatbot/dialog/clear-messages-requested',
  legalAcceptedRequested = 'vw-chatbot/dialog/legal-accepted-requested',
  setSessionIdRequested = 'vw-chatbot/dialog/set-session-id-requested',
  clearAllRequested = 'vw-chatbot/dialog/clear-all-requested',
}

export interface InitializeDialogRequestedAction
  extends Action<DialogActionType.initializeDialogRequested> {}

export interface InitializeDialogDonePayload {
  messages: DialogStateMessage[];
  legalAccepted: boolean;
  sessionId?: string;
}

export interface InitializeDialogDoneAction
  extends ActionPayload<
    DialogActionType.initializeDialogDone,
    InitializeDialogDonePayload
  > {}

export interface AddMessagesRequestedPayload {
  messages: DialogStateMessage[];
}

export interface AddMessagesRequestedAction
  extends ActionPayload<
    DialogActionType.addMessagesRequested,
    AddMessagesRequestedPayload
  > {}

export interface ClearMessagesRequestedAction
  extends Action<DialogActionType.clearMessagesRequested> {}

export interface LegalAcceptedRequestedAction
  extends Action<DialogActionType.legalAcceptedRequested> {}

export interface SetSessionIdRequestedPayload {
  sessionId: string;
}

export interface SetSessionIdRequestedAction
  extends ActionPayload<
    DialogActionType.setSessionIdRequested,
    SetSessionIdRequestedPayload
  > {}

export interface ClearAllRequestedAction
  extends Action<DialogActionType.clearAllRequested> {}

export type DialogAction =
  | InitializeDialogRequestedAction
  | InitializeDialogDoneAction
  | AddMessagesRequestedAction
  | ClearMessagesRequestedAction
  | LegalAcceptedRequestedAction
  | SetSessionIdRequestedAction
  | ClearAllRequestedAction;
