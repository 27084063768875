import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { store } from './state/store';
import { Provider } from 'react-redux';
import GlobalStyle from './components/GlobalStyle/GlobalStyle';
//import './audio/aws-lex-audio';
import './index.css';

Amplify.Logger.LOG_LEVEL = 'INFO';
Amplify.register(Auth);
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,
    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    // (optional) - Amazon Cognito Web Client ID (App client secret needs to be disabled)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_BOT_API_NAME,
        endpoint: process.env.REACT_APP_BOT_API_ENDPOINT,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <GlobalStyle />
    </Provider>
  </React.StrictMode>,
  document.getElementById('chat-bot-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
