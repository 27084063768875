const isObject = (x: unknown): x is Record<string, unknown> =>
  typeof x === 'object' && !Array.isArray(x);

export function get<T = unknown>(
  obj: Record<string, unknown>,
  path: string
): T | undefined {
  const parts = path.split('.');
  const key = parts.shift();
  if (key && obj[key] !== undefined) {
    const result = obj[key];
    if (isObject(result)) {
      return get(result, parts.join('.'));
    }
    return result as T;
  }
  return undefined;
}
