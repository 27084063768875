import { API } from '@aws-amplify/api';
import {
  RecognizeTextCommandOutput,
  RecognizeUtteranceCommandOutput,
} from '../types';
import { PromiseResult, promiseResultWrapper } from '../helpers/promise';

export enum ChatBotDataType {
  TEXT = 'TEXT',
  VOICE = 'VOICE',
}

export interface VoiceData {
  type: ChatBotDataType.VOICE;
  data: string;
  dataType: string;
}

export interface TextData {
  type: ChatBotDataType.TEXT;
  data: string;
}

export class ChatBotApi {
  private onCompleteCallback?: (...args: any[]) => void;

  onComplete(onComplete: (...args: any[]) => void) {
    this.onCompleteCallback = onComplete;
  }

  async recognizeText(
    textData: TextData,
    sessionId: string
  ): Promise<PromiseResult<RecognizeTextCommandOutput>> {
    return promiseResultWrapper<RecognizeTextCommandOutput>(() =>
      API.post(process.env.REACT_APP_BOT_API_NAME, `/chatbot/${sessionId}`, {
        body: textData,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    );
  }

  async recognizeVoice(
    voiceData: VoiceData,
    sessionId: string
  ): Promise<PromiseResult<RecognizeUtteranceCommandOutput>> {
    return promiseResultWrapper<RecognizeUtteranceCommandOutput>(() =>
      API.post(process.env.REACT_APP_BOT_API_NAME, `/chatbot/${sessionId}`, {
        body: voiceData,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    );
  }

  async online(): Promise<boolean> {
    return API.get(process.env.REACT_APP_BOT_API_NAME, `/chatbot`, {})
      .then(() => true)
      .catch(() => false);
  }
}

export default new ChatBotApi();
