/*
These conditions are checked for every part of the theme path.

If in this case the `b` is deprecated:
```
{
	a: {
		b: {
			c: 'testValue';
		}
	}
}
```

The regex should look like this:
`/\.b$/g`

This ensures that the deprecation warning is only triggered once and
not again for the children of `b`.

Example deprecation:

```
{
	condition: /\.example$/g,
	message: '`example` is deprecated. Please use `example2` instead.',
}
```
*/

export const deprecations: { condition: RegExp; message: string }[] = [];
