import { HandleTextInputEvent, TextInput } from '../TextInput/TextInput';
import { InputAppearance } from '../../helpers/input';

interface Props {
  placeholder: string;
  inputDisabled: boolean;
  inputText: string;
  onChange: HandleTextInputEvent;
}

const ChatBotTextInput = (props: Props) => {
  const onChange = props.onChange;
  const inputText = props.inputText;
  const inputDisabled = props.inputDisabled;
  const placeholder = props.placeholder;

  return (
    <TextInput
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      value={inputText}
      appearance={
        inputDisabled ? InputAppearance.Disabled : InputAppearance.Success
      }
    />
  );
};

export default ChatBotTextInput;
