import * as React from 'react';
import {
  EventHandler,
  FormEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ChatEvent,
  CustomPayloadButtons,
  CustomPayloadMessageContent,
  CustomPayloadType,
  Message,
  MessageContentType,
  MessageOrigin,
  RecognizeTextCommandOutput,
  RecognizeUtteranceCommandOutput,
  RecognizeUtteranceResponse,
  SystemMessageContent,
  SystemMessageType,
} from '../../types';
import MessageRender from './renders/MessageRender';
import { unGzipBase64AsJson } from '../../helpers/gzip';
import { Text, TextAppearance, TextColor, TextTag } from '../Text/Text';
import styled from 'styled-components/macro';
import ChatBotInputs, { ChatState } from './ChatBotInputs';
import { ReactComponent as Mic } from '../../icons/sendChat/default.svg';
import { ReactComponent as Rec } from '../../icons/rec/default.svg';
import { ReactComponent as Speaker } from '../../icons/speaker/default.svg';
import { ReactComponent as ThreeDots } from '../../icons/threeDots/default.svg';
import { ReactComponent as VWLogo } from '../../icons/vw/default.svg';
import { ReactComponent as MinimizeWindow } from '../../icons/minimizeWindow/default.svg';
import { ReactComponent as CloseWindow } from '../../icons/closeWindow/default.svg';
import { ReactComponent as SendChat } from '../../icons/sendChat/default.svg';
import { CTA } from '../CallToAction/CallToAction';
import ChatBotApi, { ChatBotDataType } from '../../api/chatBotApi';
import { base64ToUint8Array, uint8ArrayToBase64 } from '../../helpers/base64';
import { convert } from '../../helpers/convert';
import {
  dialogInitializedSelector,
  dialogInitializingSelector,
  dialogLegalAcceptedSelector,
  dialogMessagesSelector,
  dialogRestoredSelector,
  dialogSessionIdSelector,
} from '../../state/dialog/selectors';
import {
  addMessagesRequestedAction,
  clearMessagesRequestedAction,
  legalAcceptedRequestedAction,
  setSessionIdRequestedAction,
} from '../../state/dialog/actions';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { thunkInitializeDialog } from '../../state/dialog/thunks';
import Spinner from '../Spinner/Spinner';
import { v4 as uuidv4 } from 'uuid';
import Maintenance from './Maintenance';
import { isAxiosError } from '../../helpers/axios';
import { HttpStatusCodes } from '../../helpers/http';
import useRecursiveTimeout from '../../hooks/useRecursiveTimeout';
import chatBotApi from '../../api/chatBotApi';
import { Breakpoints } from '../../helpers/breakpoint';
import { ReactComponent as QuestionIcon } from '../../icons/questions/default.svg';

enum StatusBot {
  ONLINE = 'En línea',
  OFFLINE = 'Offline',
  SENDING = 'Escribiendo...',
}

const tosRejectedMessage: SystemMessageContent = {
  type: SystemMessageType.TOS_REJECTED,
};

const tosRequestMessage: SystemMessageContent = {
  type: SystemMessageType.TOS_REQUEST,
};

const STATES: { [key: string]: ChatState } = {
  INITIAL: {
    message: 'Escribí aquí',
    icon: <SendChat />,
    inputDisabled: false,
    sendButtonDisabled: false,
  },
  SENDING: {
    message: 'Por favor espere...',
    icon: <ThreeDots />,
    inputDisabled: true,
    sendButtonDisabled: true,
  },
};

export interface SilentDetectionConfig {
  time: number;
  amplitude: number;
}

export interface IChatBotProps {
  clearOnComplete?: boolean;
  conversationModeOn?: boolean;
  onComplete: any;
  textEnabled?: boolean;
  title?: string;
  voiceConfig?: { silenceDetectionConfig?: SilentDetectionConfig };
  voiceEnabled?: boolean;
  initialMessage?: string;
  open: boolean;
  showFloatingButton: boolean;
  onClose: () => void;
  onOpen: () => void;
  botName?: string;
}

const FlexWrapperBot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 5px;
  font-size: 16px;
  overflow: hidden;
  @keyframes drop {
    0% {
      opacity: 0;
      transform: translateY(-80px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

const DivMsgWrapperBot = styled.div`
  display: flex;
}
`;

const MessageWrapperBot = styled.div<{ isCarrusel: boolean }>`
  padding: 14px;
  margin-left: 7px;
  margin-right: 63px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: start;
  border-radius: 2px 18px 18px 18px;
  background-color: ${(props) => (props.isCarrusel ? '' : 'white')};
  color: black;
  width: 310px;
  min-width: 310px;
  font-size: 16px;
  box-shadow: 0px 1px 1px #00000029;
`;

const FlexWrapperUser = styled.div`
  display: flex;
  justify-content: end;
  @keyframes drop {
    0% {
      opacity: 0;
      transform: translateY(-80px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

const DivMsgWrapperUser = styled.div`
  display: flex;
}
`;

const MessageWrapperUser = styled.div`
  padding: 20px;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 18px 2px 18px 18px;
  background: #00b0f0;
  color: white;
  min-width: 40px;
  box-shadow: 0px 1px 1px #00000029;
`;

const FlexWrapperVWLogo = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column-reverse;
  margin-left: 3px;
  margin-right: 6px;
  margin-top: auto;
  margin-bottom: 2px;
  width: 50px;
`;

const ChatBotWindow = styled.div`
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 18px 18px 0px 0px;
  border-width: 2px 2px 0px 2px;
  width: 364px;
  background: var(---dfe4e8) 0% 0% no-repeat padding-box;
  background: #dfe4e8 0% 0% no-repeat padding-box;
  padding-bottom: 0px;
  @media (max-width: ${Breakpoints.b560}px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
`;

const ChatBotBody = styled.div`
  @media (max-width: ${Breakpoints.b560}px) {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
`;

const ChatBotHeader = styled.div`
  height: 82px;
  line-height: 19px;
  border-radius: 18px 18px 0px 0px;
  margin-bottom: 5px;
  text-align: center;
  background: #00b0f0;
  color: white;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.b560}px) {
    border-radius: 0;
    margin-top: 57px;
    line-height: 19px;
  }
`;

const ChatBotFooter = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px;
  width: 300px;
  height: 60px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 1px 1px #00000029;
  font: normal normal bold 20px/24px;
  font-style: normal;
  font-family: vw-text;
  text-align: left;
  opacity: 1;
`;

const ChatBotMessageList = styled.div`
  height: 380px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: gray;
  ::-webkit-scrollbar {
    width: 10px;
    height: 2px;
    background: #dfe4e8;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: #707070 0% 0% no-repeat padding-box;
  }
  @media (max-width: ${Breakpoints.b560}px) {
    height: auto;
    width: auto;
  }
`;

const StyledText = styled(Text)`
  width: 100px;
  display: inline;
  white-space: nowrap;
  color: #fff;
  font: normal normal bold 20px/24px;
  font-style: normal;
  font-family: vw-text;
  font-size: 20px;
  text-align: left;
  letter-spacing: -0.3px;
`;

const StyledTextTwo = styled(Text)`
  width: 100px;
  display: inline;
  white-space: nowrap;
  color: #dfe4e8;
  font-style: normal;
  font: normal normal 300 20px/24px;
  font-family: vw-text;
  text-align: left;
  padding-right: 84px;
  letter-spacing: -0.3px;
`;

const HeaderItemContainer = styled.div`
  height: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const HeaderItemContainerText = styled.div`
  width: 160px;
  margin-top: 12px;
  margin-bottom: 3px;
  padding-left: 4px;
`;

const HeaderItemContainerMinimize = styled.div`
  margin-top: 2px;
  width: 70px;
  height: 80px;
  margin-right: 5px;
`;

const HeaderItemContainerQuestions = styled.div`
  margin-top: -40px;
  margin-right: 10px;
`;

const StyledQuestionButton = styled(QuestionIcon)`
  cursor: pointer;
`;

const StyledVWLogo = styled(VWLogo)`
  height: 80%;
  margin-left: 22px;
  margin-right: 4px;
  width: 50px;
  background-color: #00b0f0;
  padding-top: 5px;
`;

const StyledMinimizeWindow = styled(MinimizeWindow)`
  height: 65%;
  margin-left: -6px;
  fill: #001f4e;
  width: 100%;
  cursor: pointer;
  margin-top: -3px;
  padding-left: 1px;
`;

const StyledStateText = styled(Text)`
  width: 220px;
  margin-top: 1px;
  padding-left: 10px;
  display: flex;
  color: #dfe4e8;
  font-style: normal;
  font-family: vw-text;
  font-size: 15px;
`;

const ChatBotButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #001e50;
  z-index: 9999;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const defaultVoiceConfig = {
  silenceDetectionConfig: {
    time: 2000,
    amplitude: 0.2,
  },
};

const defaultProps: Partial<IChatBotProps> = {
  title: '',
  botName: '',
  onComplete: undefined,
  clearOnComplete: false,
  voiceConfig: defaultVoiceConfig,
  conversationModeOn: false,
  voiceEnabled: false,
  textEnabled: true,
};

const parseMultiMessages = (messages?: Message[]) => {
  const result: Message[] = [];
  if (!messages) return result;
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    if (message.contentType === MessageContentType.CUSTOM_PAYLOAD) {
      const content = JSON.parse(
        message.content as string
      ) as CustomPayloadMessageContent;
      if (content.type === CustomPayloadType.MULTI) {
        const lastIndex = i + content.size;
        const parsedMessage: Message = {
          contentType: MessageContentType.CUSTOM_PAYLOAD,
          content: '',
        };
        for (let j = i + 1; j <= lastIndex; j++) {
          parsedMessage.content = parsedMessage.content?.concat(
            messages[j].content || ''
          );
        }
        i = lastIndex;
        result.push(parsedMessage);
      } else {
        result.push(message);
      }
    } else {
      result.push(message);
    }
  }
  return result;
};

export const Lex2ChatBot = ({
  voiceConfig,
  open,
  textEnabled,
  voiceEnabled,
  onClose,
  onOpen,
  showFloatingButton,
  title,
  conversationModeOn,
  onComplete,
  clearOnComplete,
  initialMessage,
}: IChatBotProps) => {
  const listItemsRef: React.RefObject<HTMLDivElement | undefined> =
    useRef<HTMLDivElement>();
  const msgBotRef: React.RefObject<HTMLDivElement | undefined> =
    useRef<HTMLDivElement>();
  const msgUsrRef: React.RefObject<HTMLDivElement | undefined> =
    useRef<HTMLDivElement>();
  //const audioControl: any = useMemo(() => new LexAudio.audioControl(), []);
  const [audioInput, setAudioInput] = useState<Blob>();
  const [continueConversation, setContinueConversation] =
    useState<boolean>(false);
  const [currentChatState, setCurrentChatState] = useState<ChatState>(
    STATES.INITIAL
  );
  const dialog = useAppSelector(dialogMessagesSelector);
  const dialogInitializing = useAppSelector(dialogInitializingSelector);
  const dialogInitialized = useAppSelector(dialogInitializedSelector);
  const sessionId = useAppSelector(dialogSessionIdSelector);
  const reduxDispatch = useAppDispatch();
  const [inputText, setInputText] = useState<string>('');
  const [lexResponse, setLexResponse] = useState<
    RecognizeUtteranceCommandOutput | RecognizeTextCommandOutput
  >();
  const legalAccepted = useAppSelector(dialogLegalAcceptedSelector);
  const dialogRestored = useAppSelector(dialogRestoredSelector);
  const [statusBot, setStatusBot] = useState<string>(StatusBot.ONLINE);
  const [startRecordingTrigger, setStartRecordingTrigger] =
    useState<boolean>(false);
  const [clearRecordingTrigger, setClearRecordingTrigger] =
    useState<boolean>(false);
  const [serviceUnavailable, setServiceUnavailable] = useState<
    boolean | undefined
  >(undefined);

  const chatBotStatusPoll = async () => {
    const serviceOnline = await chatBotApi.online();
    if (serviceOnline && serviceUnavailable !== false)
      setServiceUnavailable(false);
    else if (!serviceOnline && serviceUnavailable !== true)
      setServiceUnavailable(true);
  };

  useRecursiveTimeout(
    chatBotStatusPoll,
    parseInt(process.env.REACT_APP_BOT_STATUS_POLL_TIME_MS),
    true
  );

  const scrollMessages = () => {
    if (MessageOrigin.BOT) {
      if (msgUsrRef.current) {
        msgUsrRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
      if (MessageOrigin.USER) {
        if (msgUsrRef.current) {
          msgUsrRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!dialogInitialized && !dialogInitializing) {
      reduxDispatch(thunkInitializeDialog());
    }
  }, []);

  useEffect(() => {
    if (dialogInitialized && !sessionId) {
      reduxDispatch(setSessionIdRequestedAction(uuidv4()));
    }
    if (dialogInitialized && !legalAccepted && dialog.length === 0) {
      reduxDispatch(
        addMessagesRequestedAction([
          {
            message: {
              contentType: MessageContentType.SYSTEM_MESSAGE,
              content: JSON.stringify(tosRequestMessage),
            },
            from: MessageOrigin.BOT,
          },
        ])
      );
    }
  }, [dialogInitialized]);

  useEffect(() => {
    scrollMessages();
  }, [dialog, serviceUnavailable, open]);
  /*
  const onSilenceHandler = useCallback(() => {
    audioControl.stopRecording();
    if (!continueConversation) {
      return;
    }
    audioControl.exportWAV((blob: Blob) => {
      setCurrentChatState(STATES.SENDING);
      setAudioInput(blob);
    });
  }, [audioControl, continueConversation]);

  useEffect(() => {
    if (startRecordingTrigger) {
      audioControl.startRecording(
        onSilenceHandler,
        null,
        voiceConfig?.silenceDetectionConfig
      );
      setStartRecordingTrigger(false);
    }
  }, [
    audioControl,
    onSilenceHandler,
    startRecordingTrigger,
    voiceConfig?.silenceDetectionConfig,
  ]);

  useEffect(() => {
    if (clearRecordingTrigger) {
      audioControl.clear();
      setClearRecordingTrigger(false);
    }
  }, [audioControl, clearRecordingTrigger]);
  */
  const getOnComplete = useCallback(
    (fn: Function) => {
      return (...args: any[]) => {
        const message = fn(...args);
        clearOnComplete && reduxDispatch(clearMessagesRequestedAction());
        reduxDispatch(
          addMessagesRequestedAction(
            message ? [{ from: MessageOrigin.BOT, message }] : []
          )
        );
      };
    },
    [clearOnComplete, reduxDispatch]
  );
  /*
  useEffect(() => {
    if (!voiceConfig?.silenceDetectionConfig) {
      throw new Error('voiceConfig prop is missing silenceDetectionConfig');
    }
  }, [voiceConfig]);
  */
  useEffect(() => {
    if (onComplete) {
      ChatBotApi.onComplete(getOnComplete(onComplete));
    }
  }, [onComplete, getOnComplete]);
  /*
  const doneSpeakingHandler = useCallback(() => {
    if (!continueConversation) {
      return;
    }
    const recognizeUtteranceResponse =
      lexResponse as RecognizeUtteranceResponse;
    if (recognizeUtteranceResponse.contentType === 'audio/mpeg') {
      audioControl.play(
        // @ts-ignore
        base64ToUint8Array(recognizeUtteranceResponse.audioStream),
        () => {
          if (
            // TODO THIS CHECK DOES NOT WORK IN LEX V2
            // @ts-ignore
            recognizeUtteranceResponse.dialogState === 'ReadyForFulfillment' ||
            // @ts-ignore
            recognizeUtteranceResponse.dialogState === 'Fulfilled' ||
            // @ts-ignore
            recognizeUtteranceResponse.dialogState === 'Failed' ||
            !conversationModeOn
          ) {
            setCurrentChatState(STATES.INITIAL);
            setContinueConversation(false);
          } else {
            setCurrentChatState(STATES.LISTENING);
            setStartRecordingTrigger(true);
          }
        }
      );
    } else {
      setCurrentChatState(STATES.INITIAL);
      setContinueConversation(false);
    }
  }, [audioControl, continueConversation, conversationModeOn, lexResponse]);

  useEffect(() => {
    if (lexResponse) {
      doneSpeakingHandler();
      setLexResponse(undefined);
    }
  }, [lexResponse, doneSpeakingHandler]);
  */
  const handleError = useCallback((error: any) => {
    console.log(error);
    if (
      isAxiosError(error) &&
      error.response?.status === HttpStatusCodes.HTTP_STATUS_SERVICE_UNAVAILABLE
    )
      setServiceUnavailable(true);
  }, []);

  const lexResponseHandler = useCallback(
    async function () {
      if (!continueConversation) {
        return;
      }
      setStatusBot(StatusBot.SENDING);
      const responseWrapper = await ChatBotApi.recognizeVoice(
        {
          type: ChatBotDataType.VOICE,
          data: uint8ArrayToBase64(await convert(audioInput as Blob)),
          dataType: 'audio/x-l16; sample-rate=16000; channel-count=1',
        },
        sessionId as string
      );
      if (responseWrapper.hasError) {
        handleError(responseWrapper.error);
      }
      const response = responseWrapper.result;
      const decodedMessages = response?.messages
        ? unGzipBase64AsJson<Message[]>(response.messages)
        : [];
      let decodedTranscript = response?.inputTranscript
        ? unGzipBase64AsJson<string>(response.inputTranscript)
        : 'Mensaje de voz';
      decodedTranscript =
        decodedTranscript === '' ? 'Mensaje de voz' : decodedTranscript;
      const parsedMessages = parseMultiMessages(decodedMessages);
      const messages = parsedMessages.map((i) => ({
        from: MessageOrigin.BOT,
        message: i,
      }));
      setLexResponse(response);
      setCurrentChatState(STATES.SPEAKING);
      reduxDispatch(
        addMessagesRequestedAction([
          { message: decodedTranscript, from: MessageOrigin.USER },
          ...messages,
        ])
      );
      setInputText('');
      setStatusBot(StatusBot.ONLINE);
    },
    [audioInput, continueConversation, reduxDispatch, sessionId]
  );

  useEffect(() => {
    if (audioInput) {
      lexResponseHandler().then();
      setAudioInput(undefined);
    }
  }, [audioInput, lexResponseHandler]);
  /*
  const micButtonHandler = function () {
    if (continueConversation) {
      setInputText('');
      setCurrentChatState(STATES.INITIAL);
      setContinueConversation(false);
      setClearRecordingTrigger(true);
    } else {
      setContinueConversation(true);
      setCurrentChatState(STATES.LISTENING);
      setStartRecordingTrigger(true);
    }
  };
  */
  const userSelection = useCallback(
    async function (text: string) {
      reduxDispatch(
        addMessagesRequestedAction([
          { message: text, from: MessageOrigin.USER },
        ])
      );
    },
    [reduxDispatch, sessionId]
  );

  const sendMessage = useCallback(
    async function (text: string) {
      setStatusBot(StatusBot.SENDING);

      const responseWrapper = await ChatBotApi.recognizeText(
        { type: ChatBotDataType.TEXT, data: text },
        sessionId as string
      );
      if (responseWrapper.hasError) {
        handleError(responseWrapper.error);
      }
      const response = responseWrapper.result;
      const parsedMessages = parseMultiMessages(response?.messages);
      const messages = parsedMessages.map((i) => ({
        from: MessageOrigin.BOT,
        message: i,
      }));
      reduxDispatch(addMessagesRequestedAction(messages));
      setInputText('');
      setStatusBot(StatusBot.ONLINE);
    },
    [reduxDispatch, sessionId]
  );

  const chatEventHandler = useCallback(
    async function (event: ChatEvent) {
      switch (event) {
        case ChatEvent.TOS_ACCEPTED:
          reduxDispatch(legalAcceptedRequestedAction());
          reduxDispatch(
            addMessagesRequestedAction([
              { message: 'Acepto', from: MessageOrigin.USER },
            ])
          );
          break;
        case ChatEvent.TOS_REJECTED:
          reduxDispatch(
            addMessagesRequestedAction([
              { message: 'No Acepto', from: MessageOrigin.USER },
              {
                message: {
                  contentType: MessageContentType.SYSTEM_MESSAGE,
                  content: JSON.stringify(tosRejectedMessage),
                },
                from: MessageOrigin.BOT,
              },
            ])
          );
          break;
        case ChatEvent.TOS_RESET:
          reduxDispatch(clearMessagesRequestedAction());
          reduxDispatch(
            addMessagesRequestedAction([
              {
                message: {
                  contentType: MessageContentType.SYSTEM_MESSAGE,
                  content: JSON.stringify(tosRequestMessage),
                },
                from: MessageOrigin.BOT,
              },
            ])
          );
          break;
      }
    },
    [reduxDispatch]
  );

  useEffect(() => {
    if (!dialogRestored && legalAccepted && initialMessage)
      sendMessage(initialMessage).then();
  }, [legalAccepted]);

  const submit: EventHandler<FormEvent> = useCallback(
    async function (e: FormEvent<HTMLFormElement>) {
      e?.preventDefault();

      if (!inputText) {
        return;
      }

      await new Promise<void>((resolve) => {
        reduxDispatch(
          addMessagesRequestedAction([
            { message: inputText, from: MessageOrigin.USER },
          ])
        );
        resolve();
      });

      sendMessage(inputText).then();
    },
    [inputText, reduxDispatch, sendMessage]
  );

  const sendButtonHandler = function () {
    if (!inputText) {
      return;
    }

    reduxDispatch(
      addMessagesRequestedAction([
        { message: inputText, from: MessageOrigin.USER },
      ])
    );

    sendMessage(inputText).then();
  };

  const changeInputText = async function (
    event: SyntheticEvent<HTMLInputElement>
  ) {
    // @ts-ignore
    setInputText(event.target.value);
  };

  const isCarrusel = function (msg: string | Message) {
    var carrusel = false;
    if (typeof msg !== 'string') {
      if (msg.contentType === MessageContentType.CUSTOM_PAYLOAD) {
        const content = msg.content
          ? (JSON.parse(msg.content) as CustomPayloadMessageContent)
          : undefined;

        if (content?.type === CustomPayloadType.CAROUSEL) {
          /// es un maldito carrusel
          carrusel = true;
        }
      }
    }

    return carrusel;
  };

  const lastIndex = dialog.length - 1;
  const subTitle = 'Volkswagen Argentina';

  return open ? (
    <ChatBotWindow>
      {title && (
        <ChatBotHeader id={'chatbot-header'}>
          <HeaderItemContainer>
            <StyledVWLogo />
          </HeaderItemContainer>
          <HeaderItemContainer>
            <HeaderItemContainerText>
              <StyledText bold={true} appearance={TextAppearance.headline250}>
                {title}
              </StyledText>
              <StyledText appearance={TextAppearance.label250}>
                {' | '}
              </StyledText>
              <StyledTextTwo bold={false} appearance={TextAppearance.label250}>
                {serviceUnavailable ? StatusBot.OFFLINE : statusBot}
              </StyledTextTwo>
            </HeaderItemContainerText>
            <StyledStateText
              tag={TextTag.span}
              color={TextColor.inherit}
              bold={false}
              appearance={TextAppearance.label0200}
            >
              {subTitle}
            </StyledStateText>
          </HeaderItemContainer>
          <HeaderItemContainer>
            <HeaderItemContainerMinimize>
              <StyledMinimizeWindow onClick={() => onClose()} />
            </HeaderItemContainerMinimize>
          </HeaderItemContainer>
        </ChatBotHeader>
      )}
      <ChatBotBody id={'chatbot-body'}>
        <ChatBotMessageList id={'chatbot-msglist'}>
          {serviceUnavailable ? (
            <Maintenance />
          ) : dialogInitialized && serviceUnavailable !== undefined ? (
            dialog.map((m, i) => {
              if (m.from === MessageOrigin.USER) {
                return (
                  <FlexWrapperUser id={'flexWrapperUser'} key={i}>
                    <MessageWrapperUser id={'msgWrapperUser'}>
                      <MessageRender
                        message={m.message}
                        currentMessage={i === lastIndex}
                        enabled={true}
                        sendMessage={sendMessage}
                        userSelection={userSelection}
                        id={i.toString()}
                        sendEvent={chatEventHandler}
                        origin={MessageOrigin.USER}
                      />
                    </MessageWrapperUser>
                    <DivMsgWrapperUser
                      id={'startDivUsr'}
                      ref={msgUsrRef as React.RefObject<HTMLDivElement>}
                    />
                  </FlexWrapperUser>
                );
              } else {
                <DivMsgWrapperBot
                  id={'startDivBot'}
                  ref={msgBotRef as React.RefObject<HTMLDivElement>}
                />;
                return (
                  <FlexWrapperBot id={'flexWrapperBot' + i} key={i}>
                    <MessageWrapperBot
                      id={'msgWrapperBot'}
                      isCarrusel={isCarrusel(m.message)}
                    >
                      <MessageRender
                        message={m.message}
                        currentMessage={i === lastIndex}
                        enabled={true}
                        sendMessage={sendMessage}
                        userSelection={userSelection}
                        sendEvent={chatEventHandler}
                        id={i.toString()}
                        origin={MessageOrigin.BOT}
                      />
                    </MessageWrapperBot>
                  </FlexWrapperBot>
                );
              }
            })
          ) : (
            <SpinnerWrapper>
              <Spinner color={'#001e50'} />
            </SpinnerWrapper>
          )}
        </ChatBotMessageList>
      </ChatBotBody>
      <ChatBotFooter id={'chatbot-footer'}>
        <ChatBotInputs
          textEnabled={legalAccepted && !serviceUnavailable}
          onChange={changeInputText}
          inputText={inputText}
          onSubmit={submit}
          handleSendButton={sendButtonHandler}
          currentChatState={currentChatState}
          disabled={!legalAccepted}
        />
      </ChatBotFooter>
    </ChatBotWindow>
  ) : (
    <>
      {showFloatingButton && (
        <ChatBotButtonContainer>
          <CTA
            tag="button"
            emphasis="tertiary"
            ariaLabel="aria label"
            iconPosition="end"
            icon={<VWLogo fill={'white'} />}
            onClick={() => onOpen()}
          />
        </ChatBotButtonContainer>
      )}
    </>
  );
};

Lex2ChatBot.defaultProps = defaultProps;

export default Lex2ChatBot;
