// This rule was disabled because the repetition of strings is intended in this file
import {
  DesignTokenSize,
  DesignTokenSizeEnum,
  PartialThemeDefinition,
  ThemeDefinition,
  ThemeDefinitionSizeType,
  ThemeSet,
  ThemeTextProperties,
} from './definitions';

/**
 * This map contains values to be used with sizes and spacings.
 */
const sizesAndSpacings = Object.keys(DesignTokenSizeEnum).reduce(
  (acc, key) => {
    acc[key as DesignTokenSize] = `size.${key}`;

    return acc;
  },
  {
    line: {
      default: 'size.line100',
      active: 'size.line200',
    },
    border: {
      light: 'size.line100',
      heavy: 'size.line200',
    },
  } as ThemeDefinitionSizeType
);

function generateTextAppearance(name: string): ThemeTextProperties {
  return {
    fontFamily: `textAppearances.${name}.fontFamily`,
    fontSize: `textAppearances.${name}.fontSize`,
    reducedFontSize: `textAppearances.${name}.reducedFontSize`,
    lineHeight: `textAppearances.${name}.lineHeight`,
    letterSpacing: `textAppearances.${name}.letterSpacing`,
    static: {
      fontSize: `textAppearances.${name}.static.fontSize`,
      lineHeight: `textAppearances.${name}.static.lineHeight`,
      letterSpacing: `textAppearances.${name}.static.letterSpacing`,
      reducedFontSize: `textAppearances.${name}.static.reducedFontSize`,
    },
  };
}

/**
 * Set of available UI themes
 *
 * "main" theme must be a complete UI theme, all other themes defined here can be
 * a partial definition, missing values will be inherited from "main"
 */

const main: ThemeDefinition = {
  animation: {
    timingFunction: {
      in: 'animation.timingFunction.timing100',
      out: 'animation.timingFunction.timing100',
      linear: 'animation.timingFunction.timing200',
      cubicOut: 'animation.timingFunction.timing300',
      navigation: 'animation.timingFunction.timing400',
    },
    duration: {
      collapsible: 'animation.duration.duration600',
      color: 'animation.duration.duration300',
      layer: 'animation.duration.duration300',
      scrollIndicator: 'animation.duration.duration2000',
      nba: 'animation.duration.duration300',
      tabIndicator: 'animation.duration.duration300',
    },
  },
  global: {
    backgroundColor: 'color.white-000',
    shimColor: 'color.black-000-transparent-50',
    eyeCatcherColor: 'color.mint-100',
  },
  navigation: {
    backgroundColor: 'color.white-000',
    backgroundTransparency: 'color.white-000-transparent-98',
  },
  transparentColor: 'color.transparent',
  hoverIndicatorColor: {
    filledElement: {
      default: 'color.white-000-transparent-20',
      active: 'color.white-000-transparent-40',
    },
    outlinedElement: {
      default: 'color.grey-200-transparent-20',
      active: 'color.white-000-transparent-20',
    },
  },
  decoratorColor: 'color.blue-200',
  focusColor: 'color.blue-100',
  focus: {
    background: 'color.blue-350',
    foreground: 'color.white-000',
  },
  separatorColor: 'color.grey-100',
  shadowColor: 'color.black-000',
  backgroundGradient: {
    darkColor: 'color.blue-600',
    lightColor: 'color.blue-500',
  },
  validation: {
    success: 'color.green-100',
    error: 'color.red-100',
  },
  video: {
    background: 'color.black-000',
  },
  shim: {
    keepContext: 'color.black-000-transparent-10',
    hideContext: 'color.black-000-transparent-50',
    enhanceContrast: 'color.black-000-transparent-80',
  },
  interaction: {
    recipe100: {
      default: 'color.black-000',
      hover: 'color.grey-400',
      active: 'color.grey-400',
      disabled: 'color.grey-100',
      checked: 'color.black-000',
    },
    recipe150: {
      default: 'color.grey-100',
      hover: 'color.grey-100',
      active: 'color.grey-100',
      disabled: 'color.grey-100',
      checked: 'color.blue-600',
    },
    recipe200: {
      default: 'color.grey-400',
      hover: 'color.grey-300',
      active: 'color.grey-300',
      disabled: 'color.grey-100',
      checked: 'color.blue-600',
    },
    recipe250: {
      default: 'color.blue-200',
      hover: 'color.blue-100',
      active: 'color.blue-100',
      disabled: 'color.grey-100',
      checked: 'color.blue-200',
    },
    recipe300: {
      default: 'color.blue-600',
      hover: 'color.blue-600',
      active: 'color.blue-600',
      disabled: 'color.grey-400',
      checked: 'color.blue-600',
    },
    recipe350: {
      default: 'color.green-200',
      hover: 'color.green-100',
      active: 'color.green-100',
      disabled: 'color.green-200',
      checked: 'color.green-200',
    },
    recipe360: {
      default: 'color.red-200',
      hover: 'color.red-100',
      active: 'color.red-100',
      disabled: 'color.red-200',
      checked: 'color.red-200',
    },
    recipe370: {
      default: 'color.purple-100',
      hover: 'color.purple-100',
      active: 'color.purple-100',
      disabled: 'color.grey-100',
      checked: 'color.purple-100',
    },
    recipe380: {
      default: 'color.yellow-000',
      hover: 'color.yellow-000',
      active: 'color.yellow-000',
      disabled: 'color.grey-100',
      checked: 'color.yellow-000',
    },
    recipe400: {
      default: 'color.blue-600',
      hover: 'color.blue-900',
      active: 'color.blue-900',
      disabled: 'color.grey-100',
      checked: 'color.blue-600',
    },
    recipe450: {
      default: 'color.blue-600',
      hover: 'color.blue-900',
      active: 'color.blue-900',
      disabled: 'color.grey-400',
      checked: 'color.blue-600',
    },
    recipe500: {
      default: 'color.blue-600',
      hover: 'color.blue-600',
      active: 'color.blue-600',
      disabled: 'color.grey-100',
      checked: 'color.blue-600',
    },
    recipe550: {
      default: 'color.grey-200',
      hover: 'color.grey-100',
      active: 'color.grey-100',
      checked: 'color.grey-200',
    },
    recipe600: {
      default: 'color.grey-500',
      hover: 'color.grey-400',
      active: 'color.grey-400',
      checked: 'color.grey-500',
    },
    recipe700: {
      default: 'color.grey-100',
      hover: 'color.grey-400',
      active: 'color.grey-400',
      disabled: 'color.grey-100',
      checked: 'color.blue-600',
    },
    recipe750: {
      default: 'color.white-000',
      hover: 'color.white-000',
      active: 'color.white-000',
      disabled: 'color.black-000',
      checked: 'color.white-000',
    },
    recipe800: {
      default: 'color.white-000',
      hover: 'color.white-000',
      active: 'color.white-000',
      disabled: 'color.grey-200',
      checked: 'color.blue-600',
    },
    recipe850: {
      default: 'color.black-000',
      hover: 'color.black-000',
      active: 'color.black-000',
      disabled: 'color.white-000',
      checked: 'color.white-000',
    },
    recipe925: {
      default: 'color.blue-600',
      hover: 'color.blue-900',
      active: 'color.blue-900',
      disabled: 'color.grey-200',
      checked: 'color.blue-900',
    },
    recipe950: {
      default: 'color.white-000',
      hover: 'color.white-000',
      active: 'color.white-000',
      disabled: 'color.white-000',
      checked: 'color.white-000',
    },
  },
  size: sizesAndSpacings,
  textAppearances: {
    // These textAppearances should be used in combination with the new breakpoints.
    copy100: generateTextAppearance('copy100'),
    copy150: generateTextAppearance('copy150'),
    copy200: generateTextAppearance('copy200'),
    copy250: generateTextAppearance('copy250'),
    copy300: generateTextAppearance('copy300'),
    headline200: generateTextAppearance('headline200'),
    headline250: generateTextAppearance('headline250'),
    headline300: generateTextAppearance('headline300'),
    headline350: generateTextAppearance('headline350'),
    headline400: generateTextAppearance('headline400'),
    headline450: generateTextAppearance('headline450'),
    headline500: generateTextAppearance('headline500'),
    headline550: generateTextAppearance('headline550'),
    headline600: generateTextAppearance('headline600'),
    label100: generateTextAppearance('label100'),
    label150: generateTextAppearance('label150'),
    label200: generateTextAppearance('label200'),
    label250: generateTextAppearance('label250'),
    label300: generateTextAppearance('label300'),
    // Following values are just used for the switch over to the new breakpoints
    copy0100: generateTextAppearance('copy100'),
    copy0150: generateTextAppearance('copy150'),
    copy0200: generateTextAppearance('copy200'),
    copy0250: generateTextAppearance('copy250'),
    copy0300: generateTextAppearance('copy300'),
    headline0200: generateTextAppearance('headline200'),
    headline0250: generateTextAppearance('headline250'),
    headline0300: generateTextAppearance('headline300'),
    headline0350: generateTextAppearance('headline350'),
    headline0400: generateTextAppearance('headline400'),
    headline0450: generateTextAppearance('headline450'),
    headline0500: generateTextAppearance('headline500'),
    headline0550: generateTextAppearance('headline550'),
    headline0600: generateTextAppearance('headline600'),
    label0100: generateTextAppearance('label100'),
    label0150: generateTextAppearance('label150'),
    label0200: generateTextAppearance('label200'),
    label0250: generateTextAppearance('label250'),
    label0300: generateTextAppearance('label300'),
  },
  text: {
    color: {
      primary: 'color.black-000',
      primaryHover: 'color.grey-300',
      secondary: 'color.grey-400',
    },
    weight: {
      regular: 'font.weight.regular',
      light: 'font.weight.light',
      bold: 'font.weight.bold',
    },
    background: {
      primary: 'color.white-000',
    },
  },
  border: {
    color: {
      primary: 'color.blue-600',
      secondary: 'color.grey-100',
    },
  },
  referenceBadge: {
    backgroundColor: {
      default: 'color.grey-100',
      active: 'color.blue-900',
    },
    textColor: {
      default: 'color.black-000',
      active: 'color.white-000',
    },
    outlineColor: 'color.transparent',
  },
  nextBestAction: {
    transition: {
      backgroundColor: 'color.white-000',
    },
  },
};
const inverted: PartialThemeDefinition = {
  global: {
    backgroundColor: 'color.blue-600',
    shimColor: 'color.black-000-transparent-50',
  },
  shadowColor: 'color.white-000',
  hoverIndicatorColor: {
    filledElement: {
      default: 'color.grey-200-transparent-20',
      active: 'color.white-000-transparent-20',
    },
  },
  interaction: {
    recipe100: {
      default: 'color.white-000',
      hover: 'color.grey-200',
      active: 'color.grey-200',
      disabled: 'color.grey-400',
      checked: 'color.white-000',
    },
    recipe150: {
      default: 'color.white-000',
      hover: 'color.white-000',
      active: 'color.white-000',
      disabled: 'color.grey-400',
      checked: 'color.blue-200',
    },
    recipe200: {
      default: 'color.grey-200',
      hover: 'color.grey-100',
      active: 'color.grey-100',
      disabled: 'color.grey-400',
      checked: 'color.blue-200',
    },
    recipe400: {
      default: 'color.blue-200',
      hover: 'color.blue-100',
      active: 'color.blue-100',
      disabled: 'color.grey-400',
      checked: 'color.blue-200',
    },
    recipe450: {
      default: 'color.white-000',
      hover: 'color.blue-100',
      active: 'color.blue-100',
      disabled: 'color.grey-400',
      checked: 'color.white-000',
    },
    recipe500: {
      default: 'color.white-000',
      hover: 'color.white-000',
      active: 'color.white-000',
      disabled: 'color.grey-400',
      checked: 'color.white-000',
    },
    recipe750: {
      default: 'color.blue-600',
      hover: 'color.blue-600',
      active: 'color.blue-600',
      disabled: 'color.grey-100',
      checked: 'color.blue-600',
    },
  },
  text: {
    color: { primary: 'color.white-000', secondary: 'color.grey-200' },
    background: {
      primary: 'color.black-000',
    },
  },
  navigation: {
    backgroundColor: 'color.blue-600',
  },
  referenceBadge: {
    backgroundColor: {
      default: 'color.blue-600',
      active: 'color.white-000',
    },
    textColor: {
      default: 'color.white-000',
      active: 'color.blue-600',
    },
    outlineColor: 'color.white-000',
  },
};
const image: PartialThemeDefinition = {
  interaction: {
    recipe100: {
      default: 'color.white-000',
      hover: 'color.blue-100',
      active: 'color.blue-100',
      disabled: 'color.grey-400',
      checked: 'color.white-000',
    },
    recipe150: {
      default: 'color.white-000',
      hover: 'color.white-000',
      active: 'color.white-000',
      disabled: 'color.grey-400',
      checked: 'color.white-000',
    },
    recipe200: inverted?.interaction?.recipe200,
    recipe400: {
      default: 'color.white-000',
      hover: 'color.blue-100',
      active: 'color.blue-100',
      disabled: 'color.grey-400',
      checked: 'color.white-000',
    },
    recipe450: inverted?.interaction?.recipe450,
    recipe500: inverted?.interaction?.recipe500,
    recipe750: inverted?.interaction?.recipe750,
  },
  text: {
    color: { primary: 'color.white-000', secondary: 'color.white-000' },
    background: {
      primary: 'color.black-000',
    },
  },
  navigation: {
    backgroundColor: 'color.transparent',
  },
  focusColor: 'color.white-000',
  referenceBadge: {
    backgroundColor: {
      default: 'color.black-000-transparent-80',
      active: 'color.blue-200',
    },
    textColor: {
      default: 'color.white-000',
      active: 'color.black-100',
    },
    outlineColor: 'color.transparent',
  },
};

export const themeSet: ThemeSet = {
  main,
  inverted,
  image,
};
