/* eslint-disable-next-line import/default */

import { warnWithTrace } from '../helpers/logger';
import {
  ThemeDefinition,
  ThemeName,
  ThemeReference,
  DeepPartial,
  ThemeSet,
  DesignTokens,
} from './definitions';
import { deprecations } from './deprecations';
import { getDesignTokenValue } from './get-design-token-value';
import { themeSet as defaultThemeSet } from './example-theme-definitions';

interface ThemeNode {
  [key: string]: string | ThemeNode | undefined;
}

/**
 * TypeGuard for type `ThemeName`
 *
 * @param prop Some arbitrary component property
 */
function isThemeName(prop: ThemeReference): prop is ThemeName {
  return typeof prop === 'string';
}

function warnIfDeprecated(path: string): void {
  deprecations.forEach((deprecation) => {
    if (path.match(deprecation.condition)) {
      warnWithTrace(deprecation.message);
    }
  });
}

type ThemeLike = ThemeNode | ThemeDefinition | DeepPartial<ThemeDefinition>;

function addProxy<P extends ThemeLike>(
  targetObject: P,
  getFallbackTheme: () => P | undefined = () => undefined,
  path?: string
) {
  return new Proxy(targetObject, {
    get: (target, key) => {
      if (typeof key !== 'string') {
        return undefined;
      }

      if (Object.hasOwnProperty.call(target, key)) {
        warnIfDeprecated(path ? `${path}.${key}` : `${key}`);

        return target[key as keyof P];
      }

      const fallbackTheme = getFallbackTheme();

      return fallbackTheme && fallbackTheme[key as keyof P];
    },
  });
}

function recursivelyAddProxies<P extends ThemeLike>(
  targetObject: P,
  getFallbackTheme: () => P | undefined = () => undefined,
  path?: string
): P {
  const result = {} as P;

  Object.entries(targetObject).forEach(([key, value]) => {
    if (typeof value === 'object' && value) {
      result[key as keyof P] = recursivelyAddProxies(
        value,
        () => {
          const fallbackTheme = getFallbackTheme();

          return fallbackTheme && fallbackTheme[key as keyof P];
        },
        path ? `${path}.${key}` : key
      );
    } else {
      result[key as keyof P] = value;
    }
  });

  return addProxy(result, getFallbackTheme, path);
}

function resolveDesignTokenValues<P extends ThemeLike>(
  themeSubTree: P,
  tokensOverride?: DeepPartial<DesignTokens>
): P {
  const resolvedTheme: {
    [key: string]: string | Record<string, unknown> | undefined;
  } = {};

  Object.entries(themeSubTree).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      return;
    }

    if (typeof value === 'object') {
      resolvedTheme[key] =
        resolveDesignTokenValues<ThemeNode>(value, tokensOverride) || {};

      return;
    }

    resolvedTheme[key] = getDesignTokenValue(value, tokensOverride);
  });

  return resolvedTheme as P;
}

type Direction = 'ltr' | 'rtl';
type WithLanguageAndDirection<T> = T & {
  readonly language?: string;
  readonly direction?: Direction;
};

function addLanguageAndDirectionToTheme(
  theme: DeepPartial<ThemeDefinition>,
  language?: string,
  direction?: Direction
) {
  // avoid empty keys in themes
  let result = theme;
  if (language) {
    result = { ...result, language };
  }
  if (direction) {
    result = { ...result, direction };
  }

  return result;
}

export interface OptionsOverride {
  readonly tokens?: DeepPartial<DesignTokens>;
  readonly themeSet?: DeepPartial<ThemeSet> & {
    main: ThemeDefinition;
  };
}

export function getTheme(
  currentTheme?: WithLanguageAndDirection<ThemeDefinition>,
  newTheme?: ThemeReference,
  language?: string,
  direction?: Direction,
  optionsOverride?: OptionsOverride
): WithLanguageAndDirection<ThemeDefinition> {
  const optionsThemeSet = optionsOverride && optionsOverride.themeSet;
  const themeSet = optionsThemeSet || defaultThemeSet;

  const parentTheme =
    currentTheme && Object.keys(currentTheme).length > 0
      ? currentTheme
      : recursivelyAddProxies<ThemeDefinition>(
          resolveDesignTokenValues(
            themeSet.main,
            optionsOverride && optionsOverride.tokens
          )
        );

  const theme =
    (newTheme && isThemeName(newTheme) ? themeSet[newTheme] : newTheme) || {};
  const themeWithResolvedDesignTokens = resolveDesignTokenValues(
    theme,
    optionsOverride && optionsOverride.tokens
  );

  const themeWithLanguageAndDirection = addLanguageAndDirectionToTheme(
    themeWithResolvedDesignTokens,
    language,
    direction
  );

  return recursivelyAddProxies(
    themeWithLanguageAndDirection,
    () => parentTheme
  ) as ThemeDefinition;
}
