import React, { useMemo } from 'react';
import {
  ChatEvent,
  CustomPayloadMessageContent,
  CustomPayloadType,
  Message,
} from '../../../types';
import LinkRender from './LinkRender';
import ImageResponseCardRender from './ImageResponseCardRender';
import CarouselRender from './CarouselRender';
import MarkdownRender from './MarkdownRender';

interface Props {
  message: Message;
  enabled: boolean;
  sendMessage: (message: string) => void;
  userSelection: (message: string) => void;
  id: string;
  sendEvent: (event: ChatEvent) => void;
}

const CustomPayloadRender = ({
  message,
  enabled,
  sendMessage,
  userSelection,
  id,
  sendEvent,
}: Props): React.ReactElement => {
  const content = useMemo(
    () =>
      message.content
        ? (JSON.parse(message.content) as CustomPayloadMessageContent)
        : undefined,
    [message]
  );
  if (content) {
    switch (content.type) {
      case CustomPayloadType.LINK:
        return <LinkRender content={content} />;
      case CustomPayloadType.BUTTONS:
        return (
          <ImageResponseCardRender
            content={content}
            enabled={enabled}
            sendMessage={sendMessage}
            userSelection={userSelection}
            sendEvent={sendEvent}
          />
        );
      case CustomPayloadType.CAROUSEL:
        return (
          <CarouselRender
            id={id}
            content={content}
            sendMessage={sendMessage}
            enabled={enabled}
            sendEvent={sendEvent}
          />
        );
      case CustomPayloadType.MARKDOWN:
        return <MarkdownRender content={content} />;
    }
  }
  return <></>;
};

export default CustomPayloadRender;
