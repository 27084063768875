import React from 'react';
import { CustomPayloadLink, CustomPayloadLinksOpenType } from '../../../types';
import { CTA } from '../../CallToAction/CallToAction';
import { Text, TextAppearance, TextColor, WhiteSpace } from '../../Text/Text';
import { ExternalLinkIndicator } from '../../ExternalLinkIndicator/ExternalLinkIndicator';
import styled from 'styled-components/macro';
import { injectSmartSignal } from '../../../helpers/smartSignal';

interface Props {
  content: CustomPayloadLink;
}

const Link = styled(CTA)`
  color: black;
  font-size: 16px;
`;

const ExternalLinkIndicatorWrapper = styled.span`
  fill: currentColor;
  display: ${(props) => (props.hidden ? 'none' : '')};
`;

const LinkRender = ({ content }: Props): React.ReactElement => {
  const split = content.text.split(/(\${.*?})/g);
  if (split.length > 1) {
    let urlIndex = -1;
    return (
      <Text color={TextColor.inherit}>
        {split.map((i, index) => {
          if (i.startsWith('$')) {
            const text = i.replace('${', '').replace('}', '');
            urlIndex++;

            const link = content.links[urlIndex];
            const url = link.url;
            const external = link.type === CustomPayloadLinksOpenType.EXTERNAL;
            const tgt = external ? '_blank' : '';

            return (
              <Link
                key={`${index}-link`}
                emphasis="tertiary"
                tag="a"
                // @ts-ignore
                href={
                  link.type === CustomPayloadLinksOpenType.EXTERNAL
                    ? url
                    : injectSmartSignal(url)
                }
                target={tgt}
              >
                {text}
                <ExternalLinkIndicatorWrapper hidden={!external}>
                  <ExternalLinkIndicator />
                </ExternalLinkIndicatorWrapper>
              </Link>
            );
          } else {
            return (
              <Text
                color={TextColor.inherit}
                key={`${index}-text`}
                whiteSpace={WhiteSpace.preWrap}
                appearance={TextAppearance.copy200}
              >
                {i}
              </Text>
            );
          }
        })}
      </Text>
    );
  } else {
    return (
      <Link
        emphasis="tertiary"
        tag="a"
        // @ts-ignore
        href={
          content.links[0].type === CustomPayloadLinksOpenType.EXTERNAL
            ? content.links[0].url
            : injectSmartSignal(content.links[0].url)
        }
        target={
          content.links[0].type === CustomPayloadLinksOpenType.EXTERNAL
            ? '_blank'
            : ''
        }
      >
        {content.text}
        <ExternalLinkIndicatorWrapper>
          <ExternalLinkIndicator />
        </ExternalLinkIndicatorWrapper>
      </Link>
    );
  }
};

export default LinkRender;
