import React from 'react';
import ReactMarkdown from 'react-markdown';
import { CustomPayloadMarkdown } from '../../../types';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components/macro';

interface Props {
  content: CustomPayloadMarkdown;
}

const MDWrapper = styled.div`
  font-size: 16px;
  font-family: var(--font-family-text, vw-text, Helvetica, Arial, sans-serif);
  letter-spacing: var(--unnamed-character-spacing--0-24);
  text-align: left;
  letter-spacing: -0.24px;
`;

const MarkdownRender = ({ content }: Props): React.ReactElement => {
  return (
    <MDWrapper>
      <ReactMarkdown children={content.markdown} remarkPlugins={[remarkGfm]} />
    </MDWrapper>
  );
};

export default MarkdownRender;
