import {
  AddMessagesRequestedAction,
  ClearAllRequestedAction,
  ClearMessagesRequestedAction,
  DialogActionType,
  DialogStateMessage,
  InitializeDialogDoneAction,
  InitializeDialogRequestedAction,
  LegalAcceptedRequestedAction,
  SetSessionIdRequestedAction,
} from './types';

export const initializeDialogRequestedAction =
  (): InitializeDialogRequestedAction => {
    return {
      type: DialogActionType.initializeDialogRequested,
    };
  };

export const initializeDialogDoneAction = (
  messages: DialogStateMessage[],
  legalAccepted: boolean,
  sessionId: string | undefined
): InitializeDialogDoneAction => {
  return {
    type: DialogActionType.initializeDialogDone,
    payload: { messages, legalAccepted, sessionId },
  };
};

export const addMessagesRequestedAction = (
  messages: DialogStateMessage[]
): AddMessagesRequestedAction => {
  return { type: DialogActionType.addMessagesRequested, payload: { messages } };
};

export const clearMessagesRequestedAction =
  (): ClearMessagesRequestedAction => {
    return {
      type: DialogActionType.clearMessagesRequested,
    };
  };

export const legalAcceptedRequestedAction =
  (): LegalAcceptedRequestedAction => {
    return {
      type: DialogActionType.legalAcceptedRequested,
    };
  };

export const setSessionIdRequestedAction = (
  sessionId: string
): SetSessionIdRequestedAction => {
  return {
    type: DialogActionType.setSessionIdRequested,
    payload: { sessionId },
  };
};

export const clearAllRequestedAction = (): ClearAllRequestedAction => {
  return {
    type: DialogActionType.clearAllRequested,
  };
};
