import * as React from 'react';

import styled from 'styled-components/macro';
import { Text, TextAppearance, TextColor, TextTag } from '../Text/Text';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';

export interface CharacterCounterProps extends CommonProps {
  readonly value?: number;
  readonly limit: number;
}

const StyledCharacterCounter = styled.span`
  margin-inline-start: auto;
`;

export const CharacterCounter: React.FunctionComponent<CharacterCounterProps> =
  (props: CharacterCounterProps) => {
    const { limit, value = 0 } = props;
    const commonProps = getCommonProps(props);

    return (
      <StyledCharacterCounter aria-hidden {...commonProps}>
        <Text
          tag={TextTag.span}
          color={TextColor.inherit}
          appearance={TextAppearance.copy100}
        >
          {value} / {limit}
        </Text>
      </StyledCharacterCounter>
    );
  };
