export function createCSSVarName(
  data: { readonly [key: string]: string } | string,
  prefix?: string
): string {
  if (!data || Object.keys(data).length === 0) {
    throw new Error('invalid data');
  }
  const path = [
    ...(prefix ? [prefix] : []),
    ...(typeof data === 'string'
      ? [data]
      : Object.keys(data)
          .sort()
          .reduce((acc: string[], key) => [...acc, key, data[key]], [])),
  ];

  return `--${path
    .join('-')
    .toLowerCase()
    .replace(/[^a-z,0-9-]/g, '-')}`;
}
