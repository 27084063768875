import { warnWithTrace } from '../helpers/logger';
import { get } from '../helpers/get';
import { createCSSVarName } from './create-css-var-name';
import { designTokens } from './example-design-tokens';
import { DesignTokens, DeepPartial, DynamicToken } from './definitions';

export function getDesignTokenValue(
  propertyPath = '',
  tokensOverride?: DeepPartial<DesignTokens>
): string | undefined {
  if (!propertyPath) {
    warnWithTrace(`Design token value could not be found: ${propertyPath}`);

    return undefined;
  }

  const designTokenValue = get<string | DynamicToken[]>(
    tokensOverride || designTokens,
    propertyPath
  );

  // array values, return css var instead
  // the assumption is that every array is a dynamic designToken
  if (Array.isArray(designTokenValue)) {
    return `var(${createCSSVarName(propertyPath)})`;
  }

  return designTokenValue;
}
