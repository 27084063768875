import * as React from 'react';

import { Text, TextAppearance, TextTag } from '../Text/Text';
import { ThemeProvider } from '../../theme';
import styled from 'styled-components/macro';
import { InputAppearance, getNotificationColor } from '../../helpers/input';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';

export interface NotificationMessageProps extends CommonProps {
  readonly appearance?: InputAppearance;
  readonly children?: string;
}

const StyledNotificationMessage = styled.div<{
  readonly appearance?: InputAppearance;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getNotificationColor};
  color: ${({ theme }) => theme.interaction.recipe100.default};
  margin: ${({ theme }) => theme.size.static100} 0 0;
  padding: 0 ${({ theme }) => theme.size.static100};
  border-radius: ${({ theme }) => theme.size.static100};
  min-height: ${({ theme }) => theme.size.static350};
  min-width: ${({ theme }) => theme.size.static350};
`;

export const NotificationMessage: React.FunctionComponent<NotificationMessageProps> =
  (props) => {
    const { appearance = InputAppearance.Default, children } = props;
    const commonProps = getCommonProps(props);

    return (
      <div
        aria-live={
          appearance === InputAppearance.Error ? 'assertive' : 'polite'
        }
        aria-relevant="additions text"
        {...commonProps}
      >
        {React.Children.count(children) ? (
          <ThemeProvider theme="inverted">
            <StyledNotificationMessage appearance={appearance}>
              <Text appearance={TextAppearance.copy0100} tag={TextTag.p}>
                {children}
              </Text>
            </StyledNotificationMessage>
          </ThemeProvider>
        ) : undefined}
      </div>
    );
  };
