import * as React from 'react';

import { css, ThemeDefinition } from '../../theme';
import styled from 'styled-components/macro';
import {
  BreakpointConfigType,
  isBreakpointConfig,
  createStylesForBreakpoints,
} from '../../helpers/breakpoint';
import { warnWithTrace } from '../../helpers/logger';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';

export enum TextAppearance {
  // These textAppearances should be used in combination with the old breakpoints.
  headline600 = 'headline600',
  headline550 = 'headline550',
  headline500 = 'headline500',
  headline450 = 'headline450',
  headline400 = 'headline400',
  headline350 = 'headline350',
  headline300 = 'headline300',
  headline250 = 'headline250',
  headline200 = 'headline200',
  label300 = 'label300',
  label250 = 'label250',
  label200 = 'label200',
  label150 = 'label150',
  label100 = 'label100',
  copy300 = 'copy300',
  copy250 = 'copy250',
  copy200 = 'copy200',
  copy150 = 'copy150',
  copy100 = 'copy100',

  // These textAppearances should be used in combination with the new breakpoints.
  headline0600 = 'headline600',
  headline0550 = 'headline550',
  headline0500 = 'headline500',
  headline0450 = 'headline450',
  headline0400 = 'headline400',
  headline0350 = 'headline350',
  headline0300 = 'headline300',
  headline0250 = 'headline250',
  headline0200 = 'headline200',
  label0300 = 'label300',
  label0250 = 'label250',
  label0200 = 'label200',
  label0150 = 'label150',
  label0100 = 'label100',
  copy0300 = 'copy300',
  copy0250 = 'copy250',
  copy0200 = 'copy200',
  copy0150 = 'copy150',
  copy0100 = 'copy100',
}

export enum TextTag {
  span = 'span',
  div = 'div',
  p = 'p',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  sub = 'sub',
  sup = 'sup',
  strong = 'strong',
  del = 'del',
  legend = 'legend',
  dt = 'dt',
  dd = 'dd',
}

export enum TextAlignment {
  center = 'center',
  left = 'left',
  right = 'right',
  start = 'start',
  end = 'end',
}

export enum TextColor {
  primary = 'primary',
  secondary = 'secondary',
  inherit = 'inherit',
}

export enum WhiteSpace {
  normal = 'normal',
  nowrap = 'nowrap',
  pre = 'pre',
  preLine = 'pre-line',
  preWrap = 'pre-wrap',
  initial = 'initial',
  inherit = 'inherit',
}

export type TextProps = React.PropsWithChildren<{
  readonly appearance?: TextAppearance | BreakpointConfigType<TextAppearance>;
  readonly bold?: boolean;
  readonly sub?: boolean;
  readonly sup?: boolean;
  readonly tag?: TextTag;
  readonly textAlign?: TextAlignment | BreakpointConfigType<TextAlignment>;
  readonly wordBreak?: boolean;
  readonly whiteSpace?: WhiteSpace;
  readonly color?: TextColor;
  readonly staticSize?: boolean;
  readonly strikethrough?: boolean;
  readonly className?: string;
}> &
  CommonProps;

const headAppearanceList: TextAppearance[] = [
  TextAppearance.headline600,
  TextAppearance.headline550,
  TextAppearance.headline500,
  TextAppearance.headline450,
  TextAppearance.headline400,
  TextAppearance.headline350,
  TextAppearance.headline300,
  TextAppearance.headline250,
  TextAppearance.headline200,

  TextAppearance.headline0600,
  TextAppearance.headline0550,
  TextAppearance.headline0500,
  TextAppearance.headline0450,
  TextAppearance.headline0400,
  TextAppearance.headline0350,
  TextAppearance.headline0300,
  TextAppearance.headline0250,
  TextAppearance.headline0200,
];

function createFontStyles(
  props: TextComponentInterface & {
    appearance: TextAppearance;
    theme: ThemeDefinition;
  }
) {
  const { bold, staticSize, sub, sup, appearance, theme } = props;

  const textAppearance = theme.textAppearances[appearance];

  if (!textAppearance) {
    warnWithTrace(`The textAppearance ${appearance} could not be found`);
    return '';
  }

  const { fontFamily } = textAppearance;
  const { fontSize, letterSpacing, lineHeight, reducedFontSize } = staticSize
    ? textAppearance.static
    : textAppearance;

  const isHeadline = headAppearanceList.includes(appearance);

  const fontWeight = bold
    ? theme.text.weight.bold
    : isHeadline
    ? theme.text.weight.light
    : theme.text.weight.regular;

  /**
   * The baselines of the VW Fonts are too low and appear broken. In certain cases,
   * letters like "y", "g" or "q" are cut at the bottom. To fix this issue,
   * we add a margin to the bottom that is relative to the current font-size
   * while removing it at the same time from the top. This is to fix the baseline
   * of the VW Fonts and to make them behave more like Helvetica or Arial.
   * Should the font change in the future, we'll need to adjust the values accordingly
   * or remove them completely by resetting them to the original value "margin: 0".
   *
   * PLEASE DO NOT CHANGE OR REMOVE THESE VALUES UNLESS YOU KNOW EXACTLY WHAT YOU ARE DOING!
   *
   * intended values are "margin: -0.11em 0 0.11em 0;"
   * for static fonts we need to calculate them in px based on the static font size
   * but to avoid occasional rounding errors in browsers we need to round these to 0.5px,
   * i.e. round to full pixels on retina devices
   */
  const marginValue =
    !staticSize || sub || sup
      ? '0.11em'
      : `${fontSize && Math.round(parseInt(fontSize, 10) * 0.11 * 2) / 2}px`;
  const margin = `-${marginValue} 0 ${marginValue} 0`;

  return `
		margin: ${margin};
		font-family: ${fontFamily};
		font-weight: ${fontWeight};
		font-size: ${sub || sup ? reducedFontSize : fontSize};
		line-height: ${sub || sup ? '0' : lineHeight};
		letter-spacing: ${letterSpacing};
	`;
}

function createFontStylesForBreakpoints(
  props: TextComponentInterface & {
    appearance: TextAppearance | BreakpointConfigType<TextAppearance>;
    theme: ThemeDefinition;
  }
) {
  if (isBreakpointConfig(props.appearance)) {
    return createStylesForBreakpoints<TextAppearance>(
      props.appearance,
      (appearance) => {
        return createFontStyles({ ...props, appearance: appearance });
      }
    );
  }

  return createFontStyles({ ...props, appearance: props.appearance });
}

function createTextAlignStylesForBreakpoints(
  props: TextComponentInterface & {
    theme: ThemeDefinition;
  }
) {
  if (!props.textAlign) {
    return '';
  }
  if (isBreakpointConfig(props.textAlign)) {
    return createStylesForBreakpoints<TextAlignment>(
      props.textAlign,
      (textAlign) => `text-align: ${textAlign};`
    );
  }

  return `text-align: ${props.textAlign};`;
}

interface TextComponentInterface {
  readonly appearance?: TextAppearance | BreakpointConfigType<TextAppearance>;
  readonly bold?: boolean;
  readonly staticSize?: boolean;
  readonly strikethrough?: boolean;
  readonly sub?: boolean;
  readonly sup?: boolean;
  readonly textAlign?: TextAlignment | BreakpointConfigType<TextAlignment>;
  readonly whiteSpace?: WhiteSpace;
  readonly wordBreak?: boolean;
  readonly textColor?: string;
}

const StyledTextComponent = styled.span<
  TextComponentInterface & {
    appearance: TextAppearance | BreakpointConfigType<TextAppearance>;
  }
>`
  position: ${(props) => (props.sub || props.sup) && 'static'};
  vertical-align: ${(props) => {
    if (props.sup) {
      return 'super';
    }

    if (props.sub) {
      return 'sub';
    }

    return '';
  }};
  color: ${(props) =>
    props.textColor === TextColor.inherit
      ? 'inherit'
      : props.textColor === TextColor.secondary
      ? props.theme.text.color.secondary
      : props.theme.text.color.primary};

  ${(props) =>
    props.wordBreak &&
    css`
      word-break: normal;
      overflow-wrap: anywhere;
    `}

  &:lang(ko) {
    word-break: keep-all;
  }

  ${createFontStylesForBreakpoints}
  ${createTextAlignStylesForBreakpoints}

	text-decoration: ${(props) =>
    props.strikethrough === true
      ? 'line-through'
      : props.strikethrough === false
      ? 'initial'
      : undefined};

  white-space: ${(props) => props.whiteSpace};
`;

StyledTextComponent.displayName = 'StyledTextComponent';

type TextContextProps = React.PropsWithChildren<{
  readonly textAppearance?:
    | TextAppearance
    | BreakpointConfigType<TextAppearance>;
  readonly textColor?: TextColor;
  readonly bold?: boolean;
  readonly staticSize?: boolean;
}>;

export const TextContext: React.Context<TextContextProps> = React.createContext(
  {}
);

/**
 * Usually React.memo has no big effect on components that take children,
 * because the children are a new object reference on each render - The text
 * component however is often used with just a string as children, which makes
 * this optimization work well in those cases.
 */
export const Text = React.memo((props: TextProps): JSX.Element => {
  const context = React.useContext(TextContext);
  const {
    appearance = context.textAppearance || TextAppearance.copy0200,
    bold = context.bold,
    children,
    className,
    color = context.textColor,
    staticSize = context.staticSize,
    strikethrough,
    sub,
    sup,
    tag = (sub && TextTag.sub) ||
      (sup && TextTag.sup) ||
      (context.textAppearance && TextTag.span) ||
      TextTag.div,
    textAlign,
    wordBreak,
    whiteSpace,
  } = props;
  const commonProps = getCommonProps(props);

  const innerContextValue = React.useMemo(
    () => ({
      textAppearance: appearance,
      bold,
      textColor: color,
      staticSize,
    }),
    [appearance, bold, color, staticSize]
  );

  return (
    <StyledTextComponent
      as={tag}
      appearance={appearance}
      bold={bold}
      textColor={color}
      staticSize={staticSize}
      strikethrough={strikethrough}
      sub={sub}
      sup={sup}
      textAlign={textAlign}
      wordBreak={wordBreak}
      whiteSpace={whiteSpace}
      className={className}
      {...commonProps}
    >
      <TextContext.Provider value={innerContextValue}>
        {children}
      </TextContext.Provider>
    </StyledTextComponent>
  );
});
Text.displayName = 'Text';
