import * as React from 'react';

import { DesignTokenSize } from '../../theme';
import styled from 'styled-components/macro';
import {
  ContainerBreakpointConfig,
  ContainerDirectionalConfig,
  ContainerPaddingPropType,
  createCSSVariablesForSizes,
  createHorizontalAlignmentStyles,
  createSpacingAroundElementsStyles,
  createSpacingBetweenElementsStyles,
  createWrapStyles,
} from './helpers';

import { createElectricTransition } from '../../helpers/animation';
import {
  ContainerHorizontalAlignment,
  ContainerPadding,
  ContainerVerticalAlignment,
  ContainerWrap,
} from './definitions';
import {
  BreakpointConfigType,
  createStylesForBreakpoints,
  isBreakpointConfig,
} from '../../helpers/breakpoint';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';

// this is a legacy type that is being used elsewhere so it needs to be exported.
export type DirectionalContainerPadding = ContainerDirectionalConfig<
  ContainerPadding | ContainerBreakpointConfig<DesignTokenSize>
>;

export interface ContainerProps extends CommonProps {
  readonly gutter?: DesignTokenSize | BreakpointConfigType<DesignTokenSize>;
  readonly wrap?: ContainerWrap | BreakpointConfigType<ContainerWrap>;
  readonly padding?: ContainerPaddingPropType;
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
  readonly shrinkContent?: boolean | BreakpointConfigType<boolean>;
  readonly verticalAlign?: ContainerVerticalAlignment;
  readonly horizontalAlign?:
    | ContainerHorizontalAlignment
    | BreakpointConfigType<ContainerHorizontalAlignment>;
  readonly animateChange?: string[];
  readonly direction?: 'ltr' | 'rtl';
}

const createStretchContentForBreakpoints = (props: {
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
}) => {
  if (!props.stretchContent) {
    return '';
  }
  if (isBreakpointConfig(props.stretchContent)) {
    return createStylesForBreakpoints<boolean>(
      props.stretchContent,
      (stretchContent) =>
        stretchContent ? 'flex-grow: 1;' : 'flex-grow: initial;'
    );
  }

  return props.stretchContent && 'flex-grow: 1;';
};

const createShrinkContentForBreakpoints = (props: {
  readonly shrinkContent?: boolean | BreakpointConfigType<boolean>;
}) => {
  if (!props.shrinkContent) {
    return '';
  }
  if (isBreakpointConfig(props.shrinkContent)) {
    return createStylesForBreakpoints<boolean>(
      props.shrinkContent,
      (shrinkContent) =>
        shrinkContent ? 'flex-shrink: 1;' : 'flex-shrink: initial;'
    );
  }

  return props.shrinkContent && 'flex-shrink: 1;';
};

const StyledChildWrapper = styled.div<{
  readonly direction?: 'ltr' | 'rtl';
  readonly gutter?: DesignTokenSize | BreakpointConfigType<DesignTokenSize>;
  readonly shrinkContent?: boolean | BreakpointConfigType<boolean>;
  readonly stretchContent?: boolean | BreakpointConfigType<boolean>;
  readonly wrapped?: ContainerWrap | BreakpointConfigType<ContainerWrap>;
}>`
  flex-shrink: 0;
  max-width: 100%;

  ${createStretchContentForBreakpoints};
  ${createShrinkContentForBreakpoints};
  ${createSpacingBetweenElementsStyles};

  :empty {
    // if a child renders nothing, the wrapper should also not be rendered
    display: none;
  }
`;

StyledChildWrapper.displayName = 'StyledChildWrapper';

const StyledOverflowWrapper = styled.div<{
  readonly wrapChildren?: ContainerWrap | BreakpointConfigType<ContainerWrap>;
}>`
  overflow: ${(props) => (props.wrapChildren ? '' : 'hidden')};
`;

StyledOverflowWrapper.displayName = 'StyledOverflowWrapper';

// `flex-grow: 1;` for stretchContent is needed for cases where the content does not bring its own width and instead relies on the width
// of the container. Combined with the container being inside a different flex element.
// Example: <Layout><Container stretchContent><AspectRatioContainer /></Container></Layout>
// Also see "components-cms/mood-gallery"
//
// To use transition for some properties:
// animateChange={['padding', 'opacity']}

const StyledContainer = styled.div<{
  readonly direction?: 'ltr' | 'rtl';
  readonly gutter?: DesignTokenSize | BreakpointConfigType<DesignTokenSize>;
  readonly horizontalAlign?:
    | ContainerHorizontalAlignment
    | BreakpointConfigType<ContainerHorizontalAlignment>;
  readonly padding?: ContainerPaddingPropType;
  readonly verticalAlign?: ContainerVerticalAlignment;
  readonly wrapChildren?: ContainerWrap | BreakpointConfigType<ContainerWrap>;
  readonly animateChange?: string[];
}>`
  ${(props) =>
    props.gutter && createCSSVariablesForSizes(props.gutter, props.theme)}
  ${(props) =>
    props.padding && createCSSVariablesForSizes(props.padding, props.theme)}
	${(props) => createWrapStyles(props.wrapChildren, props.theme, props.gutter)}
	align-items: ${(props) => props.verticalAlign};
  ${(props) =>
    props.horizontalAlign &&
    createHorizontalAlignmentStyles(props.horizontalAlign)}
  ${(props) =>
    props.padding &&
    createSpacingAroundElementsStyles(props.padding, props.theme)};
  ${(props) =>
    props.animateChange && createElectricTransition(...props.animateChange)};
`;

StyledContainer.displayName = 'StyledContainer';

class ContainerInner extends React.Component<
  ContainerProps & { innerRef: React.Ref<HTMLDivElement> }
> {
  public constructor(
    props: ContainerProps & { innerRef: React.Ref<HTMLDivElement> }
  ) {
    super(props);
  }

  public render(): JSX.Element | null {
    const {
      children,
      gutter,
      padding,
      wrap,
      stretchContent,
      shrinkContent,
      verticalAlign,
      horizontalAlign,
      animateChange,
      innerRef,
    } = this.props;
    const commonProps = getCommonProps(this.props);

    const childElements: React.ReactElement[] = React.Children.toArray(
      children
    ).filter(React.isValidElement);

    if (!childElements.length) {
      return null;
    }

    return (
      <StyledOverflowWrapper
        ref={innerRef}
        wrapChildren={wrap}
        {...commonProps}
      >
        <StyledContainer
          gutter={gutter}
          padding={padding}
          wrapChildren={wrap}
          verticalAlign={verticalAlign}
          horizontalAlign={horizontalAlign}
          animateChange={animateChange}
        >
          {childElements.map((child) => {
            const key = child.key === null ? undefined : child.key;

            return (
              <StyledChildWrapper
                key={key}
                gutter={gutter}
                wrapped={wrap}
                stretchContent={stretchContent}
                shrinkContent={shrinkContent}
              >
                {child}
              </StyledChildWrapper>
            );
          })}
        </StyledContainer>
      </StyledOverflowWrapper>
    );
  }
}

export const Container = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ContainerProps>
>((props, ref) => <ContainerInner {...props} innerRef={ref} />);
Container.displayName = 'Container';
