import * as React from 'react';

import { ReactComponent as Close } from '../../icons/close/default.svg';

import { css } from '../../theme';
import styled from 'styled-components/macro';
import { CharacterCounter } from '../CharacterCounter/CharacterCounter';
import {
  InputAppearance,
  getAuxillaryColor,
  getAuxillaryTextColor,
  getMainColor,
  getLabelColor,
} from '../../helpers/input';
import { NotificationMessage } from '../NotificationMessage/NotificationMessage';
import { getCommonProps, CommonProps } from '../../helpers/commonProps';

export type HandleTextInputEvent = (
  e: React.SyntheticEvent<HTMLInputElement>
) => void;

export type HandleButtonEvent = (
  e: React.SyntheticEvent<HTMLSpanElement>
) => void;

const iconTransitionDuration = '0.15s';
const clearClickAreaSize = 44;
const clearIconSize = 24;
const clearIconOffset = (clearClickAreaSize - clearIconSize) / 2;

const StyledLabelText = styled.p<{
  readonly hasIcon?: boolean;
  readonly isFloating?: boolean;
  readonly hasValue: boolean;
}>`
  font-size: ${({ theme, isFloating }) =>
    isFloating
      ? theme.textAppearances.copy0200.fontSize
      : theme.textAppearances.copy0100.fontSize};
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  ${({ theme, isFloating, hasIcon, hasValue }) =>
    isFloating
      ? css`
          padding: 0 ${hasIcon && !hasValue ? theme.size.static400 : 0};
          transition: all 0.25s ease-in-out;
          transform: translateY(${theme.size.static250})
            // Move it down by the padding on the 'StyledLabel'
            translateY(${theme.size.static100})
            // Move it down by the padding on the 'StyledInput'
            translateY(${(props) => parseInt(props.theme.size.static300) / 2}px)
            // Move it down by half the size of the 'StyledInput'
            translateY(-50%);
          // Move it up by half of its own size
        `
      : undefined}
`;

const StyledInput = styled.input<{
  readonly appearance?: InputAppearance;
  readonly icon?: React.ReactNode;
  readonly hasValue?: boolean;
  readonly hasClearButton?: boolean;
}>`
  /* global line-height plus top and bottom padding */
  height: calc(0.5em + ${(props) => props.theme.size.static150} * 2);
  width: 100%;
  padding: ${(props) => `${props.theme.size.static150} 0`};
  padding-inline-start: ${(props) =>
    props.icon &&
    !props.hasValue &&
    props.placeholder &&
    props.theme.size.static400};
  padding-inline-end: ${(props) =>
    props.hasClearButton && `${clearClickAreaSize - clearIconOffset}px`};
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: ${(props) =>
    props.appearance === InputAppearance.Disabled ? 'not-allowed' : ''};
  color: ${getMainColor};
  outline: none;
  /* set -webkit-appearance to prevent outline bug on ios */
  -webkit-appearance: none;
  font-size: 20px;
  font-family: inherit;
  transition: ${(props) =>
    props.hasValue
      ? 'none'
      : `padding-inline-start ${iconTransitionDuration} ease-in-out`};

  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.interaction.recipe200.default};
  }

  &:focus {
    ${({ theme, appearance }) =>
      appearance === InputAppearance.Default &&
      `border-bottom-color: ${theme.interaction.recipe150.checked};`}
    color: ${({ theme }) => theme.interaction.recipe450.default};
    padding-inline-start: 0;
    opacity: 1;
    transition: padding-inline-start ${iconTransitionDuration} ease-in-out
      ${iconTransitionDuration};

    ~ ${StyledLabelText} {
      padding: 0;
      transform: translateY(0);
      font-size: ${(props) => props.theme.textAppearances.copy100.fontSize};
      background: ${({ theme }) => theme.focus.background};
      color: ${({ theme }) => theme.focus.foreground};
    }
  }

  ${(props) =>
    props.hasValue &&
    css`
      & ~ ${StyledLabelText} {
        transform: translateY(0);
        font-size: ${props.theme.textAppearances.copy100.fontSize};
      }
    `}

  /* clears the 'X' from Internet Explorer */
	&::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const transform = css`
  transform: translateY(${({ theme }) => theme.size.static250})
    // Move it down by the padding on the 'StyledLabel'
    translateY(${({ theme }) => theme.size.static100})
    // Move it down by the padding on the 'StyledInput'
    translateY(${({ theme }) => parseInt(theme.size.static300) / 2}px)
    // Move it down by half the size of the 'StyledInput'
    translateY(-50%);
  // Move it up by half of its own size
`;

const StyledIcon = styled.div<{
  readonly maxLength?: number;
  readonly hasValue: boolean;
}>`
  display: flex;
  position: absolute;
  top: 0;
  opacity: ${(props) => (props.hasValue ? 0 : 1)};
  transition: ${(props) =>
    props.hasValue
      ? 'none'
      : `all ${iconTransitionDuration} ease-in-out
		${iconTransitionDuration}`};
  ${transform};
`;

const StyledLabel = styled.label<{ readonly appearance?: InputAppearance }>`
  display: block;
  width: 100%;
  position: relative;
  /* 1.15 is the default line-height factor */
  padding-top: 4px;
  color: ${getLabelColor};

  &:focus-within {
    & ${StyledIcon} {
      opacity: 0;
      transition: all ${iconTransitionDuration} ease-in-out;
    }
  }
`;
/* ${({ theme }) => `calc(${theme.textAppearances.copy100.fontSize} * 1.15)`}; */

const StyledAdditionalInfo = styled.div<{
  readonly appearance?: InputAppearance;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${getAuxillaryTextColor};
`;

const StyledClearButton = styled.button`
  position: relative;
  width: ${clearClickAreaSize}px;
  height: ${clearClickAreaSize}px;
  transform: translateX(
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? `-${clearIconOffset}px`
        : `${clearIconOffset}px`}
  );
  background: transparent;
  padding: 0;
  border: none;
  line-height: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-bottom: 1px solid transparent;
  border-radius: 50%;
  outline: none;
  /* set -webkit-appearance to prevent outline bug on ios */
  -webkit-appearance: none;
  pointer-events: initial;

  color: ${({ disabled, theme }) =>
    theme.interaction.recipe450[disabled ? 'disabled' : 'default']};

  ${({ disabled, theme }) =>
    !disabled &&
    css`
      :hover {
        ::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          background-color: ${theme.hoverIndicatorColor.outlinedElement
            .default};
          box-shadow: 0 0 0 ${theme.hoverIndicatorColor.outlinedElement.default};
        }
      }
      :focus {
        background: ${theme.focus.background};
        color: ${theme.focus.foreground};
        border: 2px solid ${theme.interaction.recipe450.default};
      }
    `}
`;

const StyledClearButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row-reverse;
  ${transform}
`;

export interface TextInputProps extends CommonProps {
  readonly additionalFeedback?: React.ReactNode;
  readonly appearance?: InputAppearance;
  readonly label?: string;
  readonly placeholder?: string;
  readonly value?: string;
  readonly defaultValue?: string;
  readonly onChange?: HandleTextInputEvent;
  readonly onBlur?: HandleTextInputEvent;
  readonly onFocus?: HandleTextInputEvent;
  readonly onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  readonly onClear?: HandleButtonEvent;
  readonly required?: boolean;
  readonly autoFocus?: boolean;
  readonly hasClearButton?: boolean;
  readonly isClearButtonVisible?: boolean;
  readonly clearIconLabel?: string;
  readonly name?: string;
  readonly maxLength?: number;
  readonly type?: string;
  readonly icon?: React.ReactNode;
  readonly isFloating?: boolean;
  readonly autoComplete?: string;
  readonly message?: string;
}

interface TextInputState {
  readonly hasValue: boolean;
}

class TextInputInner extends React.Component<
  TextInputProps & { innerRef: React.Ref<HTMLInputElement> },
  TextInputState
> {
  constructor(
    props: TextInputProps & { innerRef: React.Ref<HTMLInputElement> }
  ) {
    super(props);
    this.state = { hasValue: Boolean(props.value || props.defaultValue) };
    this.validateInputField = this.validateInputField.bind(this);
  }

  private validateInputField(
    event: React.SyntheticEvent<HTMLInputElement>
  ): void {
    this.setState({ hasValue: Boolean(event.currentTarget.value) });
  }

  componentDidUpdate(prevProps: TextInputProps, prevState: TextInputState) {
    if (
      this.props.value !== prevProps.value &&
      prevState.hasValue !== Boolean(this.props.value)
    ) {
      this.setState({ hasValue: Boolean(this.props.value) });
    }
  }

  public render() {
    const {
      additionalFeedback,
      appearance = InputAppearance.Default,
      autoFocus,
      clearIconLabel = '',
      defaultValue,
      hasClearButton,
      icon,
      innerRef,
      isClearButtonVisible,
      isFloating,
      label = '',
      maxLength,
      name,
      onBlur,
      onChange,
      onKeyDown,
      onClear,
      onFocus,
      placeholder,
      required = false,
      type,
      value,
      autoComplete,
      message,
    } = this.props;
    const commonProps = getCommonProps(this.props);

    const { hasValue } = this.state;

    return (
      <StyledLabel appearance={appearance} {...commonProps}>
        {icon && (
          <StyledIcon maxLength={maxLength} hasValue={hasValue}>
            {icon}
          </StyledIcon>
        )}

        <StyledInput
          appearance={appearance}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={appearance === InputAppearance.Disabled}
          icon={icon}
          maxLength={maxLength}
          name={name}
          onBlur={(event) => {
            this.validateInputField(event);
            if (onBlur) {
              onBlur(event);
            }
          }}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={appearance === InputAppearance.ReadOnly}
          ref={innerRef}
          required={required}
          type={type}
          value={value}
          autoComplete={autoComplete}
          hasValue={hasValue}
          hasClearButton={hasClearButton}
        />

        {hasClearButton && isClearButtonVisible && (
          <StyledClearButtonWrapper>
            <StyledClearButton
              aria-label={clearIconLabel}
              title={clearIconLabel}
              onClick={onClear}
              type="button"
              disabled={appearance === InputAppearance.Disabled}
            >
              <Close />
            </StyledClearButton>
          </StyledClearButtonWrapper>
        )}

        <StyledLabelText
          hasIcon={Boolean(icon)}
          isFloating={isFloating}
          hasValue={hasValue}
        >
          {label}
          {required && '*'}
        </StyledLabelText>

        <StyledAdditionalInfo appearance={appearance}>
          <NotificationMessage appearance={appearance}>
            {message}
          </NotificationMessage>
          {maxLength && (
            <CharacterCounter
              value={(value && value.length) || 0}
              limit={maxLength}
            />
          )}
        </StyledAdditionalInfo>
        {additionalFeedback}
      </StyledLabel>
    );
  }
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => <TextInputInner {...props} innerRef={ref} />
);
