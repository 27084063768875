import * as React from 'react';
import { withTheme } from 'styled-components/macro';

import {
  CTAButtonProps,
  CTACommonProps,
  CTALinkProps,
  CTA,
} from '../CallToAction/CallToAction';
import { CommonProps } from '../../helpers/commonProps';
import { ReactComponent as ArrowLeftSmall } from '../../icons/arrowLeft/small.svg';
import { ReactComponent as ArrowLefDefault } from '../../icons/arrowLeft/default.svg';
import { ReactComponent as ArrowRightSmall } from '../../icons/arrowRight/small.svg';
import { ReactComponent as ArrowRightDefault } from '../../icons/arrowRight/default.svg';
import styled from 'styled-components/macro';

export type ButtonPreviousCommonProps = Omit<
  CTACommonProps,
  'stretchContent' | 'icon' | 'iconPosition'
>;

export type ButtonPreviousProps = ButtonPreviousCommonProps &
  (CTAButtonProps | CTALinkProps) &
  CommonProps;

const Arrow = withTheme((props) =>
  props.theme.direction === 'rtl' ? (
    props.size === 'small' ? (
      <ArrowRightSmall aria-hidden={true} fill="white" />
    ) : (
      <ArrowRightDefault aria-hidden={true} fill="white" />
    )
  ) : props.size === 'small' ? (
    <ArrowLeftSmall aria-hidden={true} fill="white" />
  ) : (
    <ArrowLefDefault aria-hidden={true} fill="white" />
  )
);

const CTAOverride = styled(CTA)`
  margin-left: -5px;
  :hover {
    background-color: transparent;
    border-color: #dfe4e8;
  }
  :active {
    background-color: transparent;
    border-color: #dfe4e8;
  }
  :hover:not {
    background-color: transparent;
    border-color: #dfe4e8;
  }
  :focus {
    background-color: transparent;
    border-color: #dfe4e8;
  }

  border-color: #dfe4e8;
`;

export class ButtonPrevious extends React.PureComponent<ButtonPreviousProps> {
  public constructor(props: ButtonPreviousProps) {
    super(props);
  }

  public render(): JSX.Element | null {
    const { size } = this.props;
    const { children, ...rest } = this.props;

    return (
      <CTAOverride {...rest} icon={<Arrow size={size} />} size={size}>
        {children}
      </CTAOverride>
    );
  }
}
