import React, { useCallback, useState } from 'react';
import {
  AmplifySignOut,
  AmplifyAuthenticator,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Lex2ChatBot from './components/Lex2Chatbot/Lex2ChatBot';
import { Auth } from '@aws-amplify/auth';
import styled from 'styled-components/macro';
import { ThemeProvider } from './theme';
import { getMediaQueryVars } from './theme/get-media-query-vars';
import { Breakpoints } from './helpers/breakpoint';
import { useAppDispatch } from './hooks/reduxHooks';
import { clearAllRequestedAction } from './state/dialog/actions';

interface ChatBotContainerProps {
  isOpen: boolean;
}

const ChatBotContainer = styled.div`
  position: fixed;
  right: 40px;
  bottom: -3px;
  z-index: 9999;
  ${getMediaQueryVars()};
  @media (max-width: ${Breakpoints.b560}px) {
    right: 0;
    bottom: 0;
    width: ${({ isOpen }: ChatBotContainerProps) => (isOpen ? '100vw' : 0)};
    height: ${({ isOpen }: ChatBotContainerProps) => (isOpen ? '100vh' : 0)};
    z-index: ${({ isOpen }: ChatBotContainerProps) => (isOpen ? '9999' : '0')};
  }
`;

function App() {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object>();
  const [showChatBot, setShowChatBot] = useState(true);

  const reduxDispatch = useAppDispatch();
  const clearAllDialog = useCallback(() => {
    reduxDispatch(clearAllRequestedAction());
    window.location.reload();
  }, []);

  // @ts-ignore
  window.vwChatBot = {
    openChat: () => setShowChatBot(true),
    closeChat: () => setShowChatBot(false),
    getChatState: () => showChatBot,
    clearDialog: () => clearAllDialog(),
  };
  const handleComplete = useCallback(() => {
    console.log('HANDLE COMPLETE');
  }, []);

  React.useEffect(() => {
    if (authState === undefined) {
      Auth.currentAuthenticatedUser()
        .then((authData) => {
          setAuthState(AuthState.SignedIn);
          setUser(authData);
        })
        .catch(() => {});
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, [authState]);

  const requireAuth = process.env.REACT_APP_REQUIRE_AUTH === 'true';
  return !requireAuth || (authState === AuthState.SignedIn && user) ? (
    <ThemeProvider>
      <ChatBotContainer className={'chatBotContainer'} isOpen={showChatBot}>
        <>
          <Lex2ChatBot
            title="Volksbot"
            onComplete={handleComplete}
            clearOnComplete={false}
            conversationModeOn={false}
            voiceEnabled={false}
            initialMessage={'Hola'}
            onClose={() => setShowChatBot(false)}
            onOpen={() => setShowChatBot(true)}
            open={showChatBot}
            showFloatingButton={false}
          />
          {showChatBot && requireAuth}
        </>
      </ChatBotContainer>
    </ThemeProvider>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp />
    </AmplifyAuthenticator>
  );
}

export default App;
