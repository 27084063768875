import { decode, encode } from 'base64-arraybuffer';

export const base64ToUint8Array = (base64: string): Uint8Array => {
  const arrayBuffer = decode(base64);
  return new Uint8Array(arrayBuffer);
};

export const uint8ArrayToBase64 = (uint8Array: Uint8Array): string => {
  return encode(uint8Array);
};
