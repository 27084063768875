import CacheManager, { CacheKey } from '../../cache/cacheManager';
import { DialogAction, DialogState } from './types';
import { DialogActionType } from './types';

const cache = new CacheManager();

const initialState: DialogState = {
  messages: [],
  dialogLegalAccepted: false,
  dialogInitialized: false,
  dialogInitializing: false,
  dialogRestored: false,
  sessionId: undefined,
};

let newState: DialogState | undefined;

const updateCache = (state: DialogState) => {
  cache.writeData(CacheKey.DIALOG_STATE_CACHE_KEY, {
    messages: state.messages,
    dialogLegalAccepted: state.dialogLegalAccepted,
    timestamp: Date.now(),
    version: process.env.REACT_APP_VERSION,
    sessionId: state.sessionId as string,
  });
};

const clearCache = () => {
  cache.clear();
};

const dialogReducer = (
  state: DialogState = initialState,
  action: DialogAction
) => {
  switch (action.type) {
    case DialogActionType.initializeDialogRequested:
      newState = {
        ...state,
        dialogInitializing: true,
        messages: [],
      };
      return newState;
    case DialogActionType.initializeDialogDone:
      newState = {
        ...state,
        dialogInitializing: false,
        dialogInitialized: true,
        messages: action.payload.messages,
        dialogLegalAccepted: action.payload.legalAccepted,
        dialogRestored: action.payload.messages.length > 2,
        sessionId: action.payload.sessionId,
      };
      return newState;
    case DialogActionType.addMessagesRequested:
      newState = {
        ...state,
        messages: [...state.messages, ...action.payload.messages],
      };
      updateCache(newState);
      return newState;
    case DialogActionType.clearMessagesRequested:
      newState = {
        ...state,
        messages: [],
      };
      updateCache(newState);
      return newState;
    case DialogActionType.legalAcceptedRequested:
      newState = {
        ...state,
        dialogLegalAccepted: true,
      };
      updateCache(newState);
      return newState;
    case DialogActionType.setSessionIdRequested:
      newState = {
        ...state,
        sessionId: action.payload.sessionId,
      };
      updateCache(newState);
      return newState;
    case DialogActionType.clearAllRequested:
      clearCache();
      return state;
    default:
      return state;
  }
};

export default dialogReducer;
