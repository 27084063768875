import { DesignTokenSizeEnum } from '../../theme';

export { DesignTokenSizeEnum as ContainerGutter };

export enum ContainerWrap {
  never = 'never',
  always = 'always',
  auto = 'auto',
}

export { DesignTokenSizeEnum as ContainerPadding };

export enum ContainerHorizontalAlignment {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
  spaceBetween = 'space-between',
}

export enum ContainerVerticalAlignment {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
}
