import { Breakpoints } from '../helpers/breakpoint';
import { getColumnsWidthForBreakpoint, getGridColumn } from '../helpers/grid';
import {
  DesignTokenFontSizes,
  DesignTokens,
  DynamicToken,
  TokenTextProperties,
  DesignTokenSizeType,
} from './definitions';

interface FontSizeBreakpointDescription {
  readonly [key: string]: number;
}

const fontSizes: {
  readonly [key in DesignTokenFontSizes]: FontSizeBreakpointDescription;
} = {
  size0100: {
    [Breakpoints.default]: 12,
    [Breakpoints.b1600]: 14,
  },
  size0150: {
    [Breakpoints.default]: 14,
    [Breakpoints.b1600]: 16,
  },
  size0200: {
    [Breakpoints.default]: 16,
    [Breakpoints.b1600]: 20,
  },
  size0250: {
    [Breakpoints.default]: 20,
    [Breakpoints.b1600]: 24,
  },
  size0300: {
    [Breakpoints.default]: 24,
    [Breakpoints.b1600]: 32,
  },
  size0350: {
    [Breakpoints.default]: 28,
    [Breakpoints.b560]: 32,
    [Breakpoints.b1600]: 48,
  },
  size0400: {
    [Breakpoints.default]: 32,
    [Breakpoints.b560]: 48,
    [Breakpoints.b1600]: 60,
  },
  size0450: {
    [Breakpoints.default]: 44,
    [Breakpoints.b560]: 60,
    [Breakpoints.b1600]: 76,
  },
  size0500: {
    [Breakpoints.default]: 56,
    [Breakpoints.b560]: 72,
    [Breakpoints.b1600]: 96,
  },
  size0550: {
    [Breakpoints.default]: 84,
    [Breakpoints.b560]: 96,
    [Breakpoints.b1600]: 120,
  },
  size0600: {
    [Breakpoints.default]: 100,
    [Breakpoints.b560]: 116,
    [Breakpoints.b1600]: 148,
  },
};

enum FontStyles {
  Copy,
  Headline,
  Label,
}

const generateBreakpoints = (
  fontSize: FontSizeBreakpointDescription,
  fn: (e: number) => string
) =>
  Object.values(Breakpoints).reduce((acc, breakpoint) => {
    if (fontSize[breakpoint]) {
      return [
        ...acc,
        {
          breakpoint:
            breakpoint === Breakpoints.default ? breakpoint : `${breakpoint}px`,
          value: fn(fontSize[breakpoint]),
        } as DynamicToken,
      ];
    }

    return acc;
  }, [] as DynamicToken[]);

const generateFontType = (
  size: DesignTokenFontSizes,
  style: FontStyles
): TokenTextProperties => {
  const lineHeightFactor = style === FontStyles.Copy ? 1.5 : 1.1;
  const letterSpacingFactor = -0.35;
  const baseFontSize = 16;

  const lineHeightCalculation = (fontSize: number) =>
    `${Math.ceil((fontSize * lineHeightFactor) / 4) * 4}px`;
  const letterSpacingCalculation = (fontSize: number) =>
    `${((fontSize / baseFontSize - 1) * letterSpacingFactor).toFixed(2)}px`;

  // reduced font size is 1/2 of the regular font size, rounded down to 4px, but at least 12px
  const reducedFontSizeCalculation = (fontSize: number) =>
    `${Math.max(Math.ceil(fontSize / 2 / 4) * 4, 12)}px`;

  /**
   * in css' var(...) anything before the first comma is considered the
   * variable name, everything else is used as a fallback value, regardless
   * of further commas and without quotation marks.
   */
  const fontFamily =
    style === FontStyles.Headline
      ? 'var(--font-family-head, vw-head, Helvetica, Arial, sans-serif);'
      : 'var(--font-family-text, vw-text, Helvetica, Arial, sans-serif);';

  return {
    fontFamily,
    fontSize: generateBreakpoints(fontSizes[size], (e) => `${e}px`),
    reducedFontSize: generateBreakpoints(
      fontSizes[size],
      reducedFontSizeCalculation
    ),
    lineHeight: generateBreakpoints(fontSizes[size], lineHeightCalculation),
    static: {
      fontSize: `${fontSizes[size][Breakpoints.default]}px`,
      lineHeight: lineHeightCalculation(fontSizes[size][Breakpoints.default]),
      letterSpacing: letterSpacingCalculation(
        fontSizes[size][Breakpoints.default]
      ),
      reducedFontSize: reducedFontSizeCalculation(
        fontSizes[size][Breakpoints.default]
      ),
    },
    letterSpacing: generateBreakpoints(
      fontSizes[size],
      letterSpacingCalculation
    ),
  };
};

/**
 * This map contains values to be used with sizes and spacings. The 'spacing'
 * key is deprecated. Use 'size' instead. This map allows the same values to be
 * available for both keys.
 */
const sizes: DesignTokenSizeType = {
  static0: '0',
  static100: '4px',
  static150: '8px',
  static200: '12px',
  static250: '16px',
  static300: '20px',
  static350: '24px',
  static370: '28px',
  static400: '32px',
  static450: '40px',
  static500: '44px',
  static510: '48px',
  static515: '52px',
  static520: '56px',
  static530: '64px',
  static535: '68px',
  static550: '72px',
  static565: '80px',
  static575: '84px',
  static600: '92px',
  static625: '96px',
  static650: '100px',
  static750: '120px',
  static800: '132px',
  static900: '156px',
  static1000: '172px',
  static1100: '184px',
  static1200: '196px',
  grid001: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(1) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(1, Breakpoints.b2560),
    },
  ],
  grid002: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(2) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(2, Breakpoints.b2560),
    },
  ],
  grid003: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(3) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(3, Breakpoints.b2560),
    },
  ],
  grid004: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(4) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(4, Breakpoints.b2560),
    },
  ],
  grid005: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(5) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(5, Breakpoints.b2560),
    },
  ],
  grid006: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(6) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(6, Breakpoints.b2560),
    },
  ],
  grid007: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(7) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(7, Breakpoints.b2560),
    },
  ],
  grid008: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(8) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(8, Breakpoints.b2560),
    },
  ],
  grid009: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(9) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(9, Breakpoints.b2560),
    },
  ],
  grid010: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(10) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(10, Breakpoints.b2560),
    },
  ],
  grid011: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(11) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(11, Breakpoints.b2560),
    },
  ],
  grid012: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(12) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(12, Breakpoints.b2560),
    },
  ],
  grid013: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(13) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(13, Breakpoints.b2560),
    },
  ],
  grid014: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(14) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(14, Breakpoints.b2560),
    },
  ],
  grid015: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(15) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(15, Breakpoints.b2560),
    },
  ],
  grid016: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(16) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(16, Breakpoints.b2560),
    },
  ],
  grid017: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(17) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(17, Breakpoints.b2560),
    },
  ],
  grid018: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(18) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(18, Breakpoints.b2560),
    },
  ],
  grid019: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(19) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(19, Breakpoints.b2560),
    },
  ],
  grid020: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(20) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(20, Breakpoints.b2560),
    },
  ],
  grid021: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(21) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(21, Breakpoints.b2560),
    },
  ],
  grid022: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(22) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(22, Breakpoints.b2560),
    },
  ],
  grid023: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(23) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(23, Breakpoints.b2560),
    },
  ],
  grid024: [
    { breakpoint: `${Breakpoints.default}`, value: getGridColumn(24) },
    {
      breakpoint: `${Breakpoints.b2560}px`,
      value: getColumnsWidthForBreakpoint(24, Breakpoints.b2560),
    },
  ],
  dynamic0020: [
    { breakpoint: `${Breakpoints.default}`, value: '4px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '4px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '8px' },
  ],
  dynamic0040: [
    { breakpoint: `${Breakpoints.default}`, value: '12px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '20px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '32px' },
    { breakpoint: `${Breakpoints.b2560}px`, value: '48px' },
  ],
  dynamic0050: [
    { breakpoint: `${Breakpoints.default}`, value: '12px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '20px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '24px' },
  ],
  dynamic0100: [
    { breakpoint: `${Breakpoints.default}`, value: '20px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '24px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '28px' },
  ],
  dynamic0120: [
    { breakpoint: `${Breakpoints.default}`, value: '24px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '20px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '20px' },
  ],
  dynamic0130: [
    { breakpoint: `${Breakpoints.default}`, value: '24px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '40px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '52px' },
  ],
  dynamic0140: [
    { breakpoint: `${Breakpoints.default}`, value: '28px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '32px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '48px' },
  ],
  dynamic0150: [
    { breakpoint: `${Breakpoints.default}`, value: '28px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '52px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '64px' },
  ],
  dynamic0200: [
    { breakpoint: `${Breakpoints.default}`, value: '32px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '56px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '72px' },
  ],
  dynamic0250: [
    { breakpoint: `${Breakpoints.default}`, value: '44px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '68px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '84px' },
  ],
  dynamic0270: [
    { breakpoint: `${Breakpoints.default}`, value: '56px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '80px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '100px' },
  ],
  dynamic0300: [
    { breakpoint: `${Breakpoints.default}`, value: '72px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '96px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '120px' },
  ],
  dynamic0350: [
    { breakpoint: `${Breakpoints.default}`, value: '100px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '132px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '196px' },
  ],
  dynamic0450: [
    { breakpoint: `${Breakpoints.default}`, value: '156px' },
    { breakpoint: `${Breakpoints.b560}px`, value: '172px' },
    { breakpoint: `${Breakpoints.b1600}px`, value: '184px' },
  ],
  line100: '1px',
  line200: '2px',
};

/**
 * Set of design tokens to be used in the UI themes
 *
 * Only the tokens defined here can be used for component styling
 */
export const designTokens: DesignTokens = {
  animation: {
    duration: {
      duration300: '300ms',
      duration400: '400ms',
      duration500: '500ms',
      duration600: '600ms',
      duration2000: '2000ms',
    },
    timingFunction: {
      timing100: 'ease',
      timing200: 'linear',
      timing300: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      timing400: 'ease-in',
    },
  },
  color: {
    'white-000': '#ffffff',
    'black-000': '#000000',
    'grey-100': '#DFE4E8',
    'grey-200': '#C2CACF',
    'grey-300': '#96A3A8',
    'grey-400': '#6A767D',
    'grey-500': '#3C484D',
    'blue-100': '#4CC7F4',
    'blue-200': '#00B0F0',
    'blue-350': '#0077CC',
    'blue-500': '#00437A',
    'blue-600': '#001E50',
    'blue-900': '#0040C5',
    'mint-100': '#00E6E6',
    'red-100': '#FF335C',
    'red-200': '#E4002C',
    'purple-100': '#5B08A4',
    'green-100': '#ADE5A1',
    'green-200': '#008437',
    'yellow-000': '#FFD100',
    transparent: 'transparent',
    'black-000-transparent-10': 'rgba(0, 0, 0, 0.1)',
    'black-000-transparent-50': 'rgba(0, 0, 0, 0.5)',
    'black-000-transparent-80': 'rgba(0, 0, 0, 0.8)',
    'grey-100-transparent-40': 'rgba(223, 228, 232, 0.4)',
    'grey-200-transparent-20': 'rgba(194, 202, 207, 0.2)',
    'white-000-transparent-20': 'rgba(255, 255, 255, 0.2)',
    'white-000-transparent-40': 'rgba(255, 255, 255, 0.4)',
    'white-000-transparent-98': 'rgba(255, 255, 255, 0.98)',
  },
  textAppearances: {
    // These textAppearances should be used in combination with the new breakpoints.
    copy100: generateFontType(DesignTokenFontSizes.size0100, FontStyles.Copy),
    copy150: generateFontType(DesignTokenFontSizes.size0150, FontStyles.Copy),
    copy200: generateFontType(DesignTokenFontSizes.size0200, FontStyles.Copy),
    copy250: generateFontType(DesignTokenFontSizes.size0250, FontStyles.Copy),
    copy300: generateFontType(DesignTokenFontSizes.size0300, FontStyles.Copy),
    headline200: generateFontType(
      DesignTokenFontSizes.size0200,
      FontStyles.Headline
    ),
    headline250: generateFontType(
      DesignTokenFontSizes.size0250,
      FontStyles.Headline
    ),
    headline300: generateFontType(
      DesignTokenFontSizes.size0300,
      FontStyles.Headline
    ),
    headline350: generateFontType(
      DesignTokenFontSizes.size0350,
      FontStyles.Headline
    ),
    headline400: generateFontType(
      DesignTokenFontSizes.size0400,
      FontStyles.Headline
    ),
    headline450: generateFontType(
      DesignTokenFontSizes.size0450,
      FontStyles.Headline
    ),
    headline500: generateFontType(
      DesignTokenFontSizes.size0500,
      FontStyles.Headline
    ),
    headline550: generateFontType(
      DesignTokenFontSizes.size0550,
      FontStyles.Headline
    ),
    headline600: generateFontType(
      DesignTokenFontSizes.size0600,
      FontStyles.Headline
    ),
    label100: generateFontType(DesignTokenFontSizes.size0100, FontStyles.Label),
    label150: generateFontType(DesignTokenFontSizes.size0150, FontStyles.Label),
    label200: generateFontType(DesignTokenFontSizes.size0200, FontStyles.Label),
    label250: generateFontType(DesignTokenFontSizes.size0250, FontStyles.Label),
    label300: generateFontType(DesignTokenFontSizes.size0300, FontStyles.Label),
  },
  font: {
    weight: {
      regular: 'normal',
      light: '200',
      bold: 'bold',
    },
  },
  size: sizes,
};
