import { DialogStateMessage } from './state/dialog/types';

export enum SentimentType {
  MIXED = 'MIXED',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
}

/**
 * <p>The individual sentiment responses for the utterance.</p>
 */
export interface SentimentScore {
  /**
   * <p>The level of confidence that Amazon Comprehend has in the accuracy
   *          of its detection of the <code>POSITIVE</code> sentiment.</p>
   */
  positive?: number;
  /**
   * <p>The level of confidence that Amazon Comprehend has in the accuracy
   *          of its detection of the <code>NEGATIVE</code> sentiment.</p>
   */
  negative?: number;
  /**
   * <p>The level of confidence that Amazon Comprehend has in the accuracy
   *          of its detection of the <code>NEUTRAL</code> sentiment.</p>
   */
  neutral?: number;
  /**
   * <p>The level of confidence that Amazon Comprehend has in the accuracy
   *          of its detection of the <code>MIXED</code> sentiment.</p>
   */
  mixed?: number;
}

export interface SentimentResponse {
  /**
   * <p>The overall sentiment expressed in the user's response. This is the
   *          sentiment most likely expressed by the user based on the analysis by
   *          Amazon Comprehend.</p>
   */
  sentiment?: SentimentType | string;
  /**
   * <p>The individual sentiment responses for the utterance.</p>
   */
  sentimentScore?: SentimentScore;
}

export enum IntentState {
  FAILED = 'Failed',
  FULFILLED = 'Fulfilled',
  FULFILLMENT_IN_PROGRESS = 'FulfillmentInProgress',
  IN_PROGRESS = 'InProgress',
  READY_FOR_FULFILLMENT = 'ReadyForFulfillment',
  WAITING = 'Waiting',
}

export enum ConfirmationState {
  CONFIRMED = 'Confirmed',
  DENIED = 'Denied',
  NONE = 'None',
}
export enum Shape {
  LIST = 'List',
  SCALAR = 'Scalar',
}
/**
 * <p>The value of a slot.</p>
 */
export interface Value {
  /**
   * <p>The text of the utterance from the user that was entered for the
   *          slot.</p>
   */
  originalValue?: string;
  /**
   * <p>The value that Amazon Lex V2 determines for the slot. The actual value
   *          depends on the setting of the value selection strategy for the bot. You
   *          can choose to use the value entered by the user, or you can have Amazon Lex V2
   *          choose the first value in the <code>resolvedValues</code> list.</p>
   */
  interpretedValue: string | undefined;
  /**
   * <p>A list of additional values that have been recognized for the
   *          slot.</p>
   */
  resolvedValues?: string[];
}

/**
 * <p>A value that Amazon Lex V2 uses to fulfill an intent. </p>
 */
export interface Slot {
  /**
   * <p>The current value of the slot.</p>
   */
  value?: Value;
  /**
   * <p>When the <code>shape</code> value is <code>List</code>, it indicates
   *          that the <code>values</code> field contains a list of slot values. When
   *          the value is <code>Scalar</code>, it indicates that the
   *             <code>value</code> field contains a single value.</p>
   */
  shape?: Shape | string;
  /**
   * <p>A list of one or more values that the user provided for the slot.
   *          For example, if a for a slot that elicits pizza toppings, the values
   *          might be "pepperoni" and "pineapple." </p>
   */
  values?: Slot[];
}

/**
 * <p>The current intent that Amazon Lex V2 is attempting to fulfill.</p>
 */
export interface Intent {
  /**
   * <p>The name of the intent.</p>
   */
  name: string | undefined;
  /**
   * <p>A map of all of the slots for the intent. The name of the slot maps
   *          to the value of the slot. If a slot has not been filled, the value is
   *          null.</p>
   */
  slots?: {
    [key: string]: Slot;
  };
  /**
   * <p>Contains fulfillment information for the intent. </p>
   */
  state?: IntentState | string;
  /**
   * <p>Contains information about whether fulfillment of the intent has
   *          been confirmed.</p>
   */
  confirmationState?: ConfirmationState | string;
}
/**
 * <p>The current intent that Amazon Lex V2 is attempting to fulfill.</p>
 */
export interface Intent {
  /**
   * <p>The name of the intent.</p>
   */
  name: string | undefined;
  /**
   * <p>A map of all of the slots for the intent. The name of the slot maps
   *          to the value of the slot. If a slot has not been filled, the value is
   *          null.</p>
   */
  slots?: {
    [key: string]: Slot;
  };
  /**
   * <p>Contains fulfillment information for the intent. </p>
   */
  state?: IntentState | string;
  /**
   * <p>Contains information about whether fulfillment of the intent has
   *          been confirmed.</p>
   */
  confirmationState?: ConfirmationState | string;
}

export enum DialogActionType {
  CLOSE = 'Close',
  CONFIRM_INTENT = 'ConfirmIntent',
  DELEGATE = 'Delegate',
  ELICIT_INTENT = 'ElicitIntent',
  ELICIT_SLOT = 'ElicitSlot',
  NONE = 'None',
}
/**
 * <p>The next action that Amazon Lex V2 should take.</p>
 */
export interface DialogAction {
  /**
   * <p>The next action that the bot should take in its interaction with the
   *          user. The possible values are:</p>
   *          <ul>
   *             <li>
   *                <p>
   *                   <code>Close</code> - Indicates that there will not be a
   *                response from the user. For example, the statement "Your order
   *                has been placed" does not require a response.</p>
   *             </li>
   *             <li>
   *                <p>
   *                   <code>ConfirmIntent</code> - The next action is asking the
   *                user if the intent is complete and ready to be fulfilled. This is
   *                a yes/no question such as "Place the order?"</p>
   *             </li>
   *             <li>
   *                <p>
   *                   <code>Delegate</code> - The next action is determined by
   *                Amazon Lex V2.</p>
   *             </li>
   *             <li>
   *                <p>
   *                   <code>ElicitSlot</code> - The next action is to elicit a slot
   *                value from the user.</p>
   *             </li>
   *          </ul>
   */
  type: DialogActionType | string | undefined;
  /**
   * <p>The name of the slot that should be elicited from the user.</p>
   */
  slotToElicit?: string;
}

/**
 * <p>Provides a score that indicates the confidence that Amazon Lex V2 has that
 *          an intent is the one that satisfies the user's intent.</p>
 */
export interface ConfidenceScore {
  /**
   * <p>A score that indicates how confident Amazon Lex V2 is that an intent
   *          satisfies the user's intent. Ranges between 0.00 and 1.00. Higher
   *          scores indicate higher confidence.</p>
   */
  score?: number;
}

/**
 * <p>The time that a context is active. You can specify the time to live
 *          in seconds or in conversation turns.</p>
 */
export interface ActiveContextTimeToLive {
  /**
   * <p>The number of seconds that the context is active. You can specify
   *          between 5 and 86400 seconds (24 hours).</p>
   */
  timeToLiveInSeconds: number | undefined;
  /**
   * <p>The number of turns that the context is active. You can specify up
   *          to 20 turns. Each request and response from the bot is a turn.</p>
   */
  turnsToLive: number | undefined;
}

/**
 * <p>Contains information about the contexts that a user is using in a
 *          session. You can configure Amazon Lex V2 to set a context when an intent is
 *          fulfilled, or you can set a context using the , , or  operations.</p>
 *          <p>Use a context to indicate to Amazon Lex V2 intents that should be used as
 *          follow-up intents. For example, if the active context is
 *             <code>order-fulfilled</code>, only intents that have
 *             <code>order-fulfilled</code> configured as a trigger are considered
 *          for follow up.</p>
 */
export interface ActiveContext {
  /**
   * <p>The name of the context.</p>
   */
  name: string | undefined;
  /**
   * <p>Indicates the number of turns or seconds that the context is active.
   *          Once the time to live expires, the context is no longer returned in a
   *          response.</p>
   */
  timeToLive: ActiveContextTimeToLive | undefined;
  /**
   * <p>A list of contexts active for the request. A context can be
   *          activated when a previous intent is fulfilled, or by including the
   *          context in the request.</p>
   *          <p>If you don't specify a list of contexts, Amazon Lex V2 will use the current
   *          list of contexts for the session. If you specify an empty list, all
   *          contexts for the session are cleared. </p>
   */
  contextAttributes:
    | {
        [key: string]: string;
      }
    | undefined;
}

/**
 * <p>An intent that Amazon Lex V2 determined might satisfy the user's utterance.
 *          The intents are ordered by the confidence score. </p>
 */
export interface Interpretation {
  /**
   * <p>Determines the threshold where Amazon Lex V2 will insert the
   *             <code>AMAZON.FallbackIntent</code>,
   *             <code>AMAZON.KendraSearchIntent</code>, or both when returning
   *          alternative intents in a response. <code>AMAZON.FallbackIntent</code>
   *          and <code>AMAZON.KendraSearchIntent</code> are only inserted if they
   *          are configured for the bot.</p>
   */
  nluConfidence?: ConfidenceScore;
  /**
   * <p>The sentiment expressed in an utterance. </p>
   *          <p>When the bot is configured to send utterances to Amazon Comprehend
   *          for sentiment analysis, this field contains the result of the
   *          analysis.</p>
   */
  sentimentResponse?: SentimentResponse;
  /**
   * <p>A list of intents that might satisfy the user's utterance. The
   *          intents are ordered by the confidence score.</p>
   */
  intent?: Intent;
}

/**
 * <p>The state of the user's session with Amazon Lex V2.</p>
 */
export interface SessionState {
  /**
   * <p>The next step that Amazon Lex V2 should take in the conversation with a
   *          user.</p>
   */
  dialogAction?: DialogAction;
  /**
   * <p>The active intent that Amazon Lex V2 is processing.</p>
   */
  intent?: Intent;
  /**
   * <p>One or more contexts that indicate to Amazon Lex V2 the context of a
   *          request. When a context is active, Amazon Lex V2 considers intents with the
   *          matching context as a trigger as the next intent in a session.</p>
   */
  activeContexts?: ActiveContext[];
  /**
   * <p>Map of key/value pairs representing session-specific context
   *          information. It contains application information passed between Amazon Lex V2
   *          and a client application.</p>
   */
  sessionAttributes?: {
    [key: string]: string;
  };
  /**
   * <p></p>
   */
  originatingRequestId?: string;
}

export interface Button {
  /**
   * <p>The text that is displayed on the button.</p>
   */
  text: string | undefined;
  /**
   * <p>The value returned to Amazon Lex V2 when a user chooses the button.</p>
   */
  value: string | undefined;
}

export interface ImageResponseCard {
  /**
   * <p>The title to display on the response card. The format of the title
   *          is determined by the platform displaying the response card.</p>
   */
  title: string | undefined;
  /**
   * <p>The subtitle to display on the response card. The format of the
   *          subtitle is determined by the platform displaying the response
   *          card.</p>
   */
  subtitle?: string;
  /**
   * <p>The URL of an image to display on the response card. The image URL
   *          must be publicly available so that the platform displaying the response
   *          card has access to the image.</p>
   */
  imageUrl?: string;
  /**
   * <p>A list of buttons that should be displayed on the response card. The
   *          arrangement of the buttons is determined by the platform that displays
   *          the button.</p>
   */
  buttons?: Button[];
}

export enum MessageContentType {
  CUSTOM_PAYLOAD = 'CustomPayload',
  IMAGE_RESPONSE_CARD = 'ImageResponseCard',
  PLAIN_TEXT = 'PlainText',
  SSML = 'SSML',
  SYSTEM_MESSAGE = 'SystemMessage',
}

export interface Message {
  /**
   * <p>The text of the message.</p>
   */
  content?: string;
  /**
   * <p>Indicates the type of response.</p>
   */
  contentType: MessageContentType | string | undefined;
  /**
   * <p>A card that is shown to the user by a messaging platform. You define
   *          the contents of the card, the card is displayed by the platform. </p>
   *          <p>When you use a response card, the response from the user is
   *          constrained to the text associated with a button on the card.</p>
   */
  imageResponseCard?: ImageResponseCard;
}

export interface ResponseMetadata {
  /**
   * The status code of the last HTTP response received for this operation.
   */
  HTTPStatusCode?: number;

  /**
   * A unique identifier for the last request sent for this operation. Often
   * requested by AWS service teams to aid in debugging.
   */
  RequestId?: string;

  /**
   * A secondary identifier for the last request sent. Used for debugging.
   */
  ExtendedRequestId?: string;

  /**
   * A tertiary identifier for the last request sent. Used for debugging.
   */
  CfId?: string;

  /**
   * The number of times this operation was attempted.
   */
  RetryAttempts?: number;

  /**
   * The total amount of time (in milliseconds) that was spent waiting between
   * retry attempts.
   */
  TotalRetryDelay?: number;
}

export interface MetadataBearer {
  /**
   * Metadata pertaining to this request.
   */
  $metadata: ResponseMetadata;
}

export interface RecognizeTextResponse {
  /**
   * <p>A list of messages last sent to the user. The messages are ordered
   *          based on the order that you returned the messages from your Lambda
   *          function or the order that the messages are defined in the bot.</p>
   */
  messages?: Message[];
  /**
   * <p>Represents the current state of the dialog between the user and the
   *          bot. </p>
   *          <p>Use this to determine the progress of the conversation and what the
   *          next action may be.</p>
   */
  sessionState?: SessionState;
  /**
   * <p>A list of intents that Amazon Lex V2 determined might satisfy the user's
   *          utterance. </p>
   *          <p>Each interpretation includes the intent, a score that indicates now
   *          confident Amazon Lex V2 is that the interpretation is the correct one, and an
   *          optional sentiment response that indicates the sentiment expressed in
   *          the utterance.</p>
   */
  interpretations?: Interpretation[];
  /**
   * <p>The attributes sent in the request.</p>
   */
  requestAttributes?: {
    [key: string]: string;
  };
  /**
   * <p>The identifier of the session in use.</p>
   */
  sessionId?: string;
}

export interface RecognizeTextCommandOutput
  extends RecognizeTextResponse,
    MetadataBearer {}

export interface RecognizeUtteranceResponse {
  /**
   * <p>Indicates whether the input mode to the operation was text or
   *          speech.
   *       </p>
   */
  inputMode?: string;
  /**
   * <p>Content type as specified in the <code>responseContentType</code> in
   *          the request.</p>
   */
  contentType?: string;
  /**
   * <p>A list of messages that were last sent to the user. The messages are
   *          ordered based on the order that you returned the messages from your
   *          Lambda function or the order that the messages are defined in the
   *          bot.</p>
   *          <p>The <code>messages</code> field is compressed with gzip and then
   *          base64 encoded. Before you can use the contents of the field, you must
   *          decode and decompress the contents. See the example for a simple
   *          function to decode and decompress the contents.</p>
   */
  messages?: string;
  /**
   * <p>A list of intents that Amazon Lex V2 determined might satisfy the user's
   *          utterance.</p>
   *          <p>Each interpretation includes the intent, a score that indicates how
   *          confident Amazon Lex V2 is that the interpretation is the correct one, and an
   *          optional sentiment response that indicates the sentiment expressed in
   *          the utterance.</p>
   *          <p>The <code>interpretations</code> field is compressed with gzip and
   *          then base64 encoded. Before you can use the contents of the field, you
   *          must decode and decompress the contents. See the example for a simple
   *          function to decode and decompress the contents.</p>
   */
  interpretations?: string;
  /**
   * <p>Represents the current state of the dialog between the user and the
   *          bot.</p>
   *          <p>Use this to determine the progress of the conversation and what the
   *          next action might be.</p>
   *          <p>The <code>sessionState</code> field is compressed with gzip and then
   *          base64 encoded. Before you can use the contents of the field, you must
   *          decode and decompress the contents. See the example for a simple
   *          function to decode and decompress the contents.</p>
   */
  sessionState?: string;
  /**
   * <p>The attributes sent in the request.</p>
   *          <p>The <code>requestAttributes</code> field is compressed with gzip and
   *          then base64 encoded. Before you can use the contents of the field, you
   *          must decode and decompress the contents.</p>
   */
  requestAttributes?: string;
  /**
   * <p>The identifier of the session in use.</p>
   */
  sessionId?: string;
  /**
   * <p>The text used to process the request.</p>
   *          <p>If the input was an audio stream, the <code>inputTranscript</code>
   *          field contains the text extracted from the audio stream. This is the
   *          text that is actually processed to recognize intents and slot values.
   *          You can use this information to determine if Amazon Lex V2 is correctly
   *          processing the audio that you send.</p>
   *          <p>The <code>inputTranscript</code> field is compressed with gzip and
   *          then base64 encoded. Before you can use the contents of the field, you
   *          must decode and decompress the contents. See the example for a simple
   *          function to decode and decompress the contents.</p>
   */
  inputTranscript?: string;
  /**
   * <p>The prompt or statement to send to the user. This is based on the
   *          bot configuration and context. For example, if Amazon Lex V2 did not understand
   *          the user intent, it sends the <code>clarificationPrompt</code>
   *          configured for the bot. If the intent requires confirmation before
   *          taking the fulfillment action, it sends the
   *             <code>confirmationPrompt</code>. Another example: Suppose that the
   *          Lambda function successfully fulfilled the intent, and sent a message
   *          to convey to the user. Then Amazon Lex V2 sends that message in the
   *          response.</p>
   */
  // audioStream?: Readable | ReadableStream | Blob;
  audioStream?: string; // base64
}

export interface RecognizeUtteranceCommandOutput
  extends RecognizeUtteranceResponse,
    MetadataBearer {}

export enum CustomPayloadType {
  LINK = 'link',
  MULTI = 'multi',
  BUTTONS = 'buttons',
  CAROUSEL = 'carousel',
  MARKDOWN = 'markdown',
}

export enum CustomPayloadLinksOpenType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface CustomPayloadLinks {
  url: string;
  type: CustomPayloadLinksOpenType;
}

export interface CustomPayloadLink {
  type: CustomPayloadType.LINK;
  text: string;
  links: CustomPayloadLinks[];
}

export interface CustomPayloadMulti {
  type: CustomPayloadType.MULTI;
  size: number;
  contentLength: number;
}

export enum CustomPayloadButtonsType {
  LINK = 'link',
  INTENT = 'intent',
  EVENT = 'event',
}

export enum ChatEvent {
  TOS_ACCEPTED = 'TOS_ACCEPTED',
  TOS_REJECTED = 'TOS_REJECTED',
  TOS_RESET = 'TOS_RESET',
}

export interface CustomPayloadButtonLink {
  type: CustomPayloadButtonsType.LINK;
  text: string;
  value: string;
  typeLink: CustomPayloadLinksOpenType;
}

export interface CustomPayloadButtonIntent {
  type: CustomPayloadButtonsType.INTENT;
  text: string;
  value: string;
}

export interface CustomPayloadButtonEvent {
  type: CustomPayloadButtonsType.EVENT;
  text: string;
  value: ChatEvent;
}

export type CustomPayloadButton =
  | CustomPayloadButtonLink
  | CustomPayloadButtonIntent
  | CustomPayloadButtonEvent;

export interface CustomPayloadButtons extends ImageResponseCard {
  type: CustomPayloadType.BUTTONS;
  buttons: CustomPayloadButton[];
  dir: ButtonsDirection;
  size: ButtonsSize;
}

export interface CustomPayloadCarouselElement {
  title: string;
  subtitle: string;
  imageUrl: string;
  buttons: CustomPayloadButton[];
}

export interface CustomPayloadCarousel {
  type: CustomPayloadType.CAROUSEL;
  title: string;
  subtitle?: string;
  elements: CustomPayloadCarouselElement[];
}

export interface CustomPayloadMarkdown {
  type: CustomPayloadType.MARKDOWN;
  markdown: string;
}

export type CustomPayloadMessageContent =
  | CustomPayloadLink
  | CustomPayloadMulti
  | CustomPayloadButtons
  | CustomPayloadCarousel
  | CustomPayloadMarkdown;

export enum SystemMessageType {
  TOS_REQUEST = 'TOS_REQUEST',
  TOS_REJECTED = 'TOS_REJECTED',
}

export interface SystemMessageContent {
  type: SystemMessageType;
}

export interface DialogCache {
  messages: DialogStateMessage[];
  dialogLegalAccepted: boolean;
  timestamp: number;
  version: string;
  sessionId: string;
}

export enum MessageOrigin {
  USER = 'USER',
  BOT = 'BOT',
  SYSTEM = 'SYSTEM',
}

export type ButtonsDirection =
  | 'vertical'
  | 'horizontal'
  | 'horizontal-lightweight';

export type ButtonsSize = 'large' | 'small';
