export enum Breakpoints {
  default = 0,
  b560 = 560,
  b960 = 960,
  b1280 = 1280,
  b1600 = 1600,
  b1920 = 1920,
  b2560 = 2560,
  never = Infinity,
}

export interface BreakpointConfigType<X = string> {
  readonly [breakpoint: string]: X;
}

const breakpoints = Object.values(Breakpoints);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBreakpointConfig<X = any>(
  prop?: X | BreakpointConfigType<X>
): prop is BreakpointConfigType<X> {
  return (
    typeof prop === 'object' &&
    Object.keys(prop).every(
      (key) =>
        breakpoints.includes(key) || breakpoints.includes(parseInt(key, 10))
    )
  );
}

/**
 * Compare two breakpoints to each other
 *
 * Pass to sort the breakpoints in ascending order
 *
 * All non-default breakpoints can also be passed as strings to enable mapping over Objects with breakpoints as keys:
 * ```
 * const breakpointConfig = {
 *     [Breakpoints.default]: theme.size.static250,
 *     [Breakpoints.b560]: theme.size.static350
 * };
 *
 * Object.keys(breakpointConfig).sort(compareBreakpoints).map(breakpoint => `
 *     @media (min-with: ${breakpoint}) {
 *         padding: ${breakpointConfig[breakpoint]}
 *     }
 * `)
 * ```
 */
export function compareBreakpoints(
  a: string | number,
  b: string | number
): number {
  return (
    (typeof a === 'number' ? a : parseInt(a, 10)) -
    (typeof b === 'number' ? b : parseInt(b, 10))
  );
}

function mediaQueryTemplate(breakpoint: string, styles: string): string {
  if (breakpoint === '0') {
    return styles;
  }

  return `@media (min-width: ${breakpoint}px) {${styles}}`;
}

export function createStylesForBreakpoints<T = string>(
  config: BreakpointConfigType<T>,
  fn = (x: T): string => x as unknown as string
): string {
  return Object.keys(config)
    .sort(compareBreakpoints)
    .map((breakpoint) => mediaQueryTemplate(breakpoint, fn(config[breakpoint])))
    .join('');
}
