export interface PromiseResult<TData> {
  result?: TData;
  hasError?: boolean;
  error?: any;
}

export const promiseResultWrapper = async <TData>(
  executor: () => Promise<TData>
): Promise<PromiseResult<TData>> => {
  return executor()
    .then((data) => ({ result: data }))
    .catch((e) => ({ hasError: true, error: e }));
};
