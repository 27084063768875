import * as React from 'react';

import { ReactComponent as ArrowLefDefault } from '../../icons/arrowLeft/default.svg';
import { ReactComponent as ArrowLeftSmall } from '../../icons/arrowLeft/small.svg';
import { ReactComponent as ArrowRightDefault } from '../../icons/arrowRight/default.svg';
import { ReactComponent as ArrowRightSmall } from '../../icons/arrowRight/small.svg';

import {
  CTAButtonProps,
  CTACommonProps,
  CTALinkProps,
  CTA,
} from '../CallToAction/CallToAction';
import { CommonProps } from '../../helpers/commonProps';
import styled, { withTheme } from 'styled-components/macro';

export type ButtonNextCommonProps = Omit<
  CTACommonProps,
  'stretchContent' | 'icon' | 'iconPosition'
>;

export type ButtonNextProps = ButtonNextCommonProps &
  (CTAButtonProps | CTALinkProps) &
  CommonProps;

const Arrow = withTheme((props) =>
  props.theme.direction === 'rtl' ? (
    props.size === 'small' ? (
      <ArrowLeftSmall aria-hidden={true} fill="white" />
    ) : (
      <ArrowLefDefault aria-hidden={true} fill="white" />
    )
  ) : props.size === 'small' ? (
    <ArrowRightSmall aria-hidden={true} fill="white" />
  ) : (
    <ArrowRightDefault aria-hidden={true} fill="white" />
  )
);

const CTAOverride = styled(CTA)`
  margin-right: -13px;
  :hover {
    background-color: transparent;
    border-color: #dfe4e8;
  }
  :active {
    background-color: transparent;
    border-color: #dfe4e8;
  }
  :hover:not {
    background-color: transparent;
    border-color: #dfe4e8;
  }
  :focus {
    background-color: transparent;
    border-color: #dfe4e8;
  }

  border-color: #dfe4e8;
`;

export class ButtonNext extends React.PureComponent<ButtonNextProps> {
  public constructor(props: ButtonNextProps) {
    super(props);
  }

  public render(): JSX.Element | null {
    const { size } = this.props;

    const { children, ...rest } = this.props;

    return (
      <CTAOverride
        {...rest}
        icon={<Arrow size={size} />}
        iconPosition="end"
        size={size}
      >
        {children}
      </CTAOverride>
    );
  }
}
