import React from 'react';
import {
  ChatEvent,
  CustomPayloadButtonEvent,
  CustomPayloadButtonsType,
} from '../../../types';
import styled from 'styled-components/macro';
import { CTA } from '../../CallToAction/CallToAction';
import { ExternalLinkIndicator } from '../../ExternalLinkIndicator/ExternalLinkIndicator';
import {
  Text,
  TextAlignment,
  TextAppearance,
  TextColor,
  WhiteSpace,
} from '../../Text/Text';
import ButtonsRender from './ButtonsRender';

interface Props {
  sendMessage: (message: string) => void;
  sendEvent: (event: ChatEvent) => void;
  currentMessage: boolean;
}

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

const LegalText = styled.div`
  flex-basis: 100%;
  align-self: start;
  padding-top: 1px;
  margin: 0 0 15px 0;
`;

const StyledText = styled(Text)`
  width: 240px;
  display: box;
  white-space: nowrap;
`;

const Link = styled(CTA)`
  color: #001e50;
  font-size: 16px;
`;

const ExternalLinkIndicatorWrapper = styled.span`
  fill: currentColor;
`;

const PRIVACY_POLICY_LINK =
  'https://www.volkswagen.com.ar/es/informaciones-legales/politica-de-privacidad.html';
const TERMS_AND_CONDITIONS_LINK =
  'https://www.volkswagen.com.ar/es/informaciones-legales/terminos-y-condiciones.html';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 310px;
`;

const CTAOverride = styled(CTA)`
  padding: 10px;
  margin: 3px 5px 0 0;
  color: #001e50;
  background-color: white;
  border: 1px solid;
  border-radius: 22px;
  max-width: 119px;
  min-width: 119px;
`;

const TosRequestRender = ({
  sendEvent,
  sendMessage,
  currentMessage,
}: Props) => {
  const buttonClickHandler = (event: ChatEvent) => {
    sendEvent(event);
  };
  return (
    <MainContainer>
      <LegalText>
        <Text
          appearance={TextAppearance.copy200}
          color={TextColor.inherit}
          textAlign={TextAlignment.start}
          whiteSpace={WhiteSpace.preWrap}
        >
          <>
            <StyledText bold={false}>{'Hola soy '}</StyledText>
            <StyledText bold={true}>{'Volksbot, '}</StyledText>
            <StyledText bold={false}>{'tu asistente virtual.'}</StyledText>
            {'\n'}
            {
              'Para poder continuar la conversación, necesitamos que aceptes nuestros \ntérminos y condiciones y políticas de \nprivacidad para que todas las funciones \nse activen correctamente.\n'
            }
            <Link
              emphasis="tertiary"
              tag="a"
              // @ts-ignore
              href={PRIVACY_POLICY_LINK}
              target="_blank"
            >
              {'Políticas de privacidad '}
              <ExternalLinkIndicatorWrapper>
                <ExternalLinkIndicator />
              </ExternalLinkIndicatorWrapper>
            </Link>
            <br />
            <Link
              emphasis="tertiary"
              tag="a"
              // @ts-ignore
              href={TERMS_AND_CONDITIONS_LINK}
              target="_blank"
            >
              {'Términos y condiciones '}
              <ExternalLinkIndicatorWrapper>
                <ExternalLinkIndicator />
              </ExternalLinkIndicatorWrapper>
            </Link>
          </>
        </Text>
      </LegalText>
      <ButtonsContainer>
        <CTAOverride
          disabled={!currentMessage}
          emphasis={'primary'}
          key={'aceptar-custom'}
          tag="button"
          onClick={
            currentMessage
              ? () => buttonClickHandler(ChatEvent.TOS_ACCEPTED)
              : undefined
          }
        >
          {'Acepto'}
        </CTAOverride>
        <CTAOverride
          disabled={!currentMessage}
          emphasis={'primary'}
          key={'no-aceptar-custom'}
          tag="button"
          onClick={
            currentMessage
              ? () => buttonClickHandler(ChatEvent.TOS_REJECTED)
              : undefined
          }
        >
          {'No Acepto'}
        </CTAOverride>
      </ButtonsContainer>
    </MainContainer>
  );
};

export default TosRequestRender;
